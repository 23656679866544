import Page from "../../../../shared/components/Page";
import AdministrativePaymentInvoicing from "./AdministrativePaymentInvoicing";

export default function AdministrativePaymentInvoicingPage() {
  return (
    <Page>
      <Page.Header>
        <Page.Title>Invoicing & Adjustments</Page.Title>
      </Page.Header>

      <Page.Content p={0}>
        <AdministrativePaymentInvoicing />
      </Page.Content>
    </Page>
  );
}
