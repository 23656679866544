import { Box, Button, Flex, IconButton, Progress, Tooltip } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import PlayArrowIcon from "../../../../../shared/icons/PlayArrowIcon";
import PlayArrowOutlineIcon from "../../../../../shared/icons/PlayArrowOutlineIcon";
import PlaylistAddIcon from "../../../../../shared/icons/PlaylistAddIcon";
import PlaylistRemoveIcon from "../../../../../shared/icons/PlaylistRemoveIcon";
import useParallelCalls from "../useParallelCalls";
import RefreshRoundedIcon from "../../../../../shared/icons/RefreshRoundedIcon";

export default function PendingParallelCallsListActions() {
  const calls = useParallelCalls();

  return (
    <>
      <Flex gap={2} justify="end" px={5} py={3}>
        <Button
          isDisabled={calls.getCheckedCallsCount() === 0}
          overflow="hidden"
          px={0}
          rounded="full"
          variant="outline"
          onClick={calls.playQueue}
        >
          {calls.getCheckedCallsCount() > 0 ? (
            <PlayArrowIcon fontSize="xl" mx={2} />
          ) : (
            <PlayArrowOutlineIcon fontSize="xl" mx={2} />
          )}
          <AnimatePresence>
            {calls.getCheckedCallsCount() > 0 && (
              <motion.span
                animate={{ width: "auto", opacity: 1 }}
                exit={{ width: 0, opacity: 0 }}
                initial={{ width: 0, opacity: 0 }}
              >
                <Box pe={4}>Add to queue ({calls.getCheckedCallsCount()})</Box>
              </motion.span>
            )}
          </AnimatePresence>
        </Button>

        <Tooltip
          label={
            calls.getDispatchedCalls().length > 0
              ? "Refreshing is disabled while calls are in progress"
              : "Refresh queue"
          }
        >
          <IconButton
            aria-label="Refresh"
            icon={
              <RefreshRoundedIcon
                fontSize="xl"
                mx={2}
                transform={calls.query.isFetching ? "rotate(360deg)" : undefined}
                transition={calls.query.isFetching ? "transform 0.5s" : undefined}
              />
            }
            isDisabled={calls.getDispatchedCalls().length > 0 || calls.query.isFetching}
            rounded="full"
            variant="ghost"
            onClick={() => calls.query.refetch()}
          />
        </Tooltip>

        {calls.getHasUnchecked() ? (
          <Tooltip label="Check all">
            <IconButton
              aria-label="Check all"
              icon={<PlaylistAddIcon fontSize="xl" />}
              isDisabled={(calls.getListedCalls()?.length ?? 0) === 0}
              rounded="full"
              variant="ghost"
              onClick={calls.checkAll}
            />
          </Tooltip>
        ) : (
          <Tooltip label="Uncheck all">
            <IconButton
              aria-label="Uncheck all"
              icon={<PlaylistRemoveIcon fontSize="xl" />}
              isDisabled={(calls.getListedCalls()?.length ?? 0) === 0}
              rounded="full"
              variant="ghost"
              onClick={calls.uncheckAll}
            />
          </Tooltip>
        )}
      </Flex>
      <Progress bg="gray.200" h="1px" isIndeterminate={calls.query.isFetching} />
    </>
  );
}
