
//! @ngInject
export function billingCheckDetailsCtrl(
  $scope,
  $rootScope,
  $stateParams,
  NgTableParams,
  itemSearchPageManager,
  BillingChecksService,
  ChecksCodesService,
  generalUtils,
  toaster,
  $uibModal,
  DatabaseApi,
  wildcard,
  SweetAlert,
  $filter,
  Currency,
  $timeout,
) {
  $scope.selectedTab = "Applied"
  $scope.checkBatch = null;
  $scope.checkBatchId = null;
  $scope.canSubmit = false;
  $scope.isLoading = false;
  $scope.openVisitInstanceModal = $rootScope.openVisitInstanceModal;

  /**
   * Initialize controller
   */
  // TODO: Refactor, so initialize and consolidateAttachedVisitLines share code.
  function initialize() {
    if ($scope.isActiveGetCheckRequest) {
      return;
    }
    $scope.isActiveGetCheckRequest = true;
    $scope.isLoading = true;
    if (!$scope.checkBatch) {
      $scope.checkBatch = $stateParams.check;
    }
    $scope.offices = DatabaseApi.offices() || {};

    initEditCentsFields();

    $scope.describeRarc = ChecksCodesService.describeRarc;
    $scope.describeCarc = ChecksCodesService.describeCarc;
    $scope.describeCagc = ChecksCodesService.describeCagc;

    $scope.checkBatchId =
      ($scope.checkBatch && $scope.checkBatch.id) ||
      $stateParams.id ||
      generalUtils.getLastPartInUrlPath();

    if ($scope.checkBatchId) {
      BillingChecksService.getCheckById($scope.checkBatchId, {
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId
      })
        .then(onSuccessBatchRequest, onErrorBatchRequest)
        .finally(() => {
          initializeDelta();
          $scope.isLoading = false;
          $scope.isActiveGetCheckRequest = false;
        });
    }
  }

  /**
  * The $scope.delta object holds the differences between the server-side totals and the actual totals, which take
  * modified check lines into account: actualTOTAL = $scope.checkBatch.TOTAL + $scope.delta.TOTAL
  *
  * The deltas are updated with updateDelta on check-line initialization, and on every check-line change.
  */

  const initialDelta = {
    appliedAmount: 0,
    appliedPositiveAmount: 0,
    appliedNegativeAmount: 0,
    writeOffAmount: 0,
    dirtyCheckLineCount: 0,
  };

  const initializeDelta = () => {
    $scope.delta = initialDelta;
  };

  /**
   * Recompute $scope.delta from the dirty check lines.
   *
   * NOTE: For simplicity and correctness, we recompute the deltas from scratch every time a check line changes. This
   * is fast enough for at least 100,000 modfied check lines, as long as we don't call for each line on bulk changes
   * (which would make it O(N^2) rather than O(N)).
   */
  function updateDelta() {
    const dirtyCheckLines = $scope.checkBatch.checkLines.filter(({ isDirty }) => isDirty);

    // Add the differences caused by the changes to this checkLine to delta.
    const addCheckLineDelta = (delta, checkLine) => {
      const server = {
        appliedAmount: checkLine.serverCheckLine.payment,
        appliedPositiveAmount: checkLine.serverCheckLine.payment > 0 ? checkLine.serverCheckLine.payment : 0,
        appliedNegativeAmount: checkLine.serverCheckLine.payment < 0 ? checkLine.serverCheckLine.payment : 0,
        writeOffAmount: checkLine.serverCheckLine.writeOffAmount,
      }
      const { payment, writeOffAmount } = checkLine;
      return {
        appliedAmount: delta.appliedAmount - server.appliedAmount + payment,
        appliedPositiveAmount: delta.appliedPositiveAmount - server.appliedPositiveAmount + (payment > 0 ? payment : 0),
        appliedNegativeAmount: delta.appliedNegativeAmount - server.appliedNegativeAmount + (payment < 0 ? payment : 0),
        writeOffAmount: delta.writeOffAmount - server.writeOffAmount + writeOffAmount,
        dirtyCheckLineCount: delta.dirtyCheckLineCount + 1,
      }
    }

    $scope.delta = dirtyCheckLines.reduce(addCheckLineDelta, initialDelta);
  }

  $scope.getBalance = () => !$scope.checkBatch
    ? 0 // Return 0 until the check has loaded.
    : (
        $scope.checkBatch.amount
        - $scope.checkBatch.forwardingBalance
        - $scope.checkBatch.interest
        - $scope.checkBatch.overpaymentRecovery
        - $scope.checkBatch.nonreimbursable
        - $scope.checkBatch.levyAmount
        - $scope.checkBatch.lumpSum
        - $scope.checkBatch.adjustment
        - $scope.checkBatch.providerRefund
     ) - ($scope.checkBatch.appliedAmount + $scope.delta.appliedAmount);

  // A checkLine is dirty if its editable properties differ from the ones on the server (checkLine.serverCheckLine).
  const isCheckLineDirty = (checkLine) =>
    checkLine.claimNumber !== checkLine.serverCheckLine.claimNumber ||
    checkLine.payment !== checkLine.serverCheckLine.payment ||
    checkLine.writeOffAmount !== checkLine.serverCheckLine.writeOffAmount ||
    checkLine.isMarkedForDeletion;

  const setCheckLineIsDirty = (checkLine) => {
    checkLine.isDirty = isCheckLineDirty(checkLine);
  };

  // Undo restores the editable properties to the server values and updates the ..InDollars properties.
  $scope.undoCheckLineChanges = (checkLine) => {
    checkLine.isMarkedForDeletion = false;
    checkLine.claimNumber = checkLine.serverCheckLine.claimNumber

    // Update dollars because updateCheckLine uses it as the data model.
    checkLine.paymentInDollars = checkLine.serverCheckLine.payment / 100;
    checkLine.writeOffInDollars = checkLine.serverCheckLine.writeOffAmount / 100;

    $scope.updateCheckLine(checkLine);
  };

  /**
   * Update the CheckLine DOM
   */
  $scope.updateCheckLine = (checkLine) => {
    // 0 for invalid inputs.
    checkLine.payment = new Currency().fromMajor(checkLine.paymentInDollars, 0).toMinor();
    checkLine.writeOffAmount = new Currency().fromMajor(checkLine.writeOffInDollars, 0).toMinor();
    hydrateCheckLineMissing(checkLine);
    $scope.canSubmit = getCanSubmit();

    setCheckLineIsDirty(checkLine);
    updateDelta();
  };

  /**
   * Save the CheckLine changes (api request)
   *
   * NOTE: Saving a selection of check lines will clear unsaved changes because of an initalize() down the line.
   */
  $scope.save = (checkLines = $scope.checkBatch.checkLines) => {

    // TODO: Once we're confident the dirty tracking is accurate, we can save just the dirty checkLines.
    // Only dirty lines.
    const checkLinesToSave = checkLines.filter(({ isDirty }) => isDirty).map((checkLine) => ({
      checkLineId: checkLine.id,
      claimNumber: checkLine.claimNumber ?? null,
      payment: checkLine.payment,
      writeOffAmount: checkLine.writeOffAmount ?? null,
      delete: checkLine.isMarkedForDeletion ?? null,
    }));

    const deleteLinesCount = checkLinesToSave.filter((checkLine) => checkLine.delete).length;

    if (deleteLinesCount > 0) {
      SweetAlert.swal({
        title: 'Remove check visit',
        text: `Are you sure you want to remove ${deleteLinesCount === 1 ? "a service" : `${deleteLinesCount} services`} from this check?`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3077EB',
        confirmButtonText: 'Yes, Remove',
        closeOnConfirm: true,
        closeOnCancel: true
      }, function (isConfirm) {
        if (isConfirm) {
          sendUpdate(checkLinesToSave);
        }
      });
    } else {
      sendUpdate(checkLinesToSave);
    }
  };
  
  function sendUpdate(checkLinesToSave) {
    $scope.isLoading = true;
    BillingChecksService.updateCheckLinesAmounts(checkLinesToSave, {
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    })
      .then(handleSuccessUpdate)
      .catch(handleErrorUpdate)
      .finally(() => ($scope.isLoading = false));
  }

  $scope.deleteAll = (checkLines = $scope.tableParams.data) => {
    for (const checkLine of checkLines) {
      $scope.deleteCheckLine(checkLine);
    }
  };

  /**
   * Open attach-visits modal dialog.
   */
  $scope.openAttachVisitsDialog = (unappliedCheckLine) => {
    updateDelta(); // The deltas should be up to date, but we update them here just to be sure (incrementality is hard).

    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/billing-check-attach-visits-modal.html",
      size: "lg",
      controller: "billingAttachCheckVisitsModalCtrl",
      windowClass: "billing-attach-check-visits-modal",
      backdrop: "static",
      resolve: {
        check: () => $scope.checkBatch,
        checkBalance: $scope.getBalance,
        defaultFilters: () => {
          if (!unappliedCheckLine) return;

          const sortedDates = $scope.checkBatch.unappliedLines
            .filter(line => line.claimIndentifier === unappliedCheckLine.claimIndentifier)
            .map(line => new Date($filter("mfShortDate")(line.date)))
            .sort((a, b) => a.valueOf() - b.valueOf());

          return {
            visitFrom: sortedDates[0],
            visitTo: sortedDates[sortedDates.length - 1],
            patientName: unappliedCheckLine.patientName
          };
        },
      },
    });
    modalInstance.result.then(
      function (response) {
        if (response) {
          if (response.isError) {
            toaster.pop("error", response.message);
          } else {
            consolidateAttachedVisitLines(response.data.checkLineIds)
          }
        }
      },
      function (rejection) {
        if (rejection !== "backdrop click") {
          toaster.pop("error", "Failed to open modal to attach visits");
        }
      }
    );
  };

  // TODO: We duplicate a lot of code from initialize & onSuccessBatchRequest here. A global refactoring is needed.
  async function consolidateAttachedVisitLines(addedCheckLineIds) {
    $scope.isLoading = true;
    $scope.isActiveGetCheckRequest = true;

    try {
      // Request the check to get additional details like patient_id. This could be optimized with a new endpoint.
      const { data: newVisitsCheckBatch } = await BillingChecksService.getCheckById($scope.checkBatchId, {
        agencyId: $rootScope.agencyId,
        agencyMemberId: $rootScope.agencyMemberId
      });

      const addedCheckLineIdSet = new Set(addedCheckLineIds);
      const addedCheckLines = newVisitsCheckBatch.checkLines.filter(
        (checkLine) => addedCheckLineIdSet.has(checkLine.id)
      );

      addedCheckLines.forEach(hydrateCheckLine); // TODO: Refactor to get rid of these error-prone mutations.

      // Insert the new lines in front of the existing lines (so they show at the top if the table is not sorted):
      $scope.checkBatch.checkLines = [...addedCheckLines, ...$scope.checkBatch.checkLines];

      $scope.canSubmit = getCanSubmit();
      updateDelta();

      try {
        const { data: updatedCheckBatch } = await BillingChecksService.getCheckById($scope.checkBatchId, {
          agencyId: $rootScope.agencyId,
          agencyMemberId: $rootScope.agencyMemberId
        });

        // Take all check details except the contractTypeName and the (possibly dirty) check lines from the server
        // response. The totals from the server don'only take saved changes into account, buotals
        $scope.checkBatch = {
          ...updatedCheckBatch,
          contractTypeName: $scope.checkBatch.contractTypeName,
          checkLines: $scope.checkBatch.checkLines
        };

        initEditCentsFields();

        initTable($scope.checkBatch.checkLines);
        initUnappliedTable($scope.checkBatch.unappliedLines);

        // TODO: Why don't these toasters pop without a $timeout?
        $timeout(() => toaster.pop(
          "success",
          `Successfully attached ${addedCheckLines.length} visit${addedCheckLines.length !== 1 ? 's' : ''}`
        ), 0);
      } catch (error) {
        $timeout(() => toaster.pop("error", "Failed to load the check details"), 0);
        console.error('consolidateAttachedVisitLines.newTotals', error);
      }
    } catch (error) {
      $timeout(() => toaster.pop("error", "Failed to load the check with the newly-attached services"), 0);
      console.error('consolidateAttachedVisitLines.newVisits', error);
    } finally {
      $scope.isActiveGetCheckRequest = false;
      $scope.isLoading = false;
    }
  }

  /**
   * In case the $event target has an empty value, change it to zero
   */
  $scope.setToZeroIfEmpty = ($event) => {
    if ($event.currentTarget.value === "") {
      $event.currentTarget.value = 0;
    }
  };

  /**
   * Handle a successful change operation
   * @param {Response} response
   */
  function handleSuccessUpdate(response) {
    toaster.pop(
      "success",
      "Success",
      "You have successfully updated the checkLines"
    );
    initialize();
  }

  /**
   * Handle an error response of change operation
   * @param {Error} response
   */
  function handleErrorUpdate(response) {
    console.log(response.data.error);

    const message =
      response.data.error || "Could not update the checkLines";

    toaster.pop("error", "Error", message);
  }

  /**
   * Handle successful batch request
   * @param {Response} response
   */
  function onSuccessBatchRequest({ data }) {
    $scope.checkBatch = data;

    initEditCentsFields();

    $scope.checkBatch.checkLines.forEach(hydrateCheckLine);  // TODO: Refactor to get rid of error-prone mutations.
    $scope.checkBatch.unappliedLines.forEach(hydrateUnappliedCheckLine); // TODO: Refactor

    $scope.canSubmit = getCanSubmit();

    if (!$scope.checkBatch.contractTypeName && $scope.checkBatch.contractTypeId) {
      $scope.pageManager = itemSearchPageManager.createSearchPageManager("/check_batches");
      $scope.contractTypesDataManager = $scope.pageManager.getContractTypesDataManager();

      const contractType = $scope.contractTypesDataManager.getContractTypeById($scope.checkBatch.contractTypeId);
      $scope.checkBatch.contractTypeName = (contractType && contractType.name) || $scope.checkBatch.contractTypeId;
    }

    $scope.checkBatch.officesNames = $scope.offices.filter(officeItem => $scope.checkBatch.officesIds.includes(officeItem.id)).map(officeItem => officeItem.name).join(", ");

    initTable($scope.checkBatch.checkLines);
    initUnappliedTable($scope.checkBatch.unappliedLines);
  }

  /**
   * Handle failure batch requested
   * @param {Response} error
   */
  function onErrorBatchRequest(error) {
    toaster.pop("error", "Failed to load the check batch details");
  }

  /**
   * TODO: A previous comment about $scope.canSubmit mentioned:
   * > Determines if the client can submit the form. We need to make sure that:
   * >   1. the check amount hasn't been changed
   * >   2. all payments equal or bigger than zero
   * >   3. all write-off(s) equal or bigger than zero
   * >   4. the maximum write-off should not be bigger than (checkamount - total requested)
   *
   * Only (3) was (and is) being checked though. Do we need those other checks?
   */

  // MarkedForDeletion - This previous comment seems to not be relevant anymore. As of writing this comment, it just checks that there is no negative write-off.
  function getCanSubmit() {
    return !$scope.checkBatch.checkLines.some(({ writeOffAmount }) => writeOffAmount < 0);
  }

  const getPatientName = (patientId) => {
    const patient = DatabaseApi.getPatientById(patientId)
    return patient ? patient.firstName + ' ' + patient.lastName : '<loading>';
  }

  $rootScope.$on("got_patients_data", () => {
    // In case the check was already loaded (and hydrated) before the patient data finished loading, the patient names
    // will still say '<loading>', so we set them here.
    if ($scope.checkBatch) {
      $scope.checkBatch.checkLines.forEach(hydrateCheckLinePatientName);
    }
  });

  /**
   * Set 'paymentInDollars', 'writeOffInDollars', 'patientName', and 'missing' properties on the checkLine object,
   * and add a 'serverCheckLine' property with a copy of the editable properties the checkLine object. Rather than
   * mutating, it would have been better to create a checkLineRow object and use that in the table, but that might
   * require a lot of refactoring.
   *
   * @param {object} checkLine
   */
  function hydrateCheckLine(checkLine) {
    // Having invisible nulls that show up as '' or 0 but don't become a string or number until edited, is a nuisance,
    // so we replace them with '' and 0.
    checkLine.claimNumber = checkLine.claimNumber ?? '',
      checkLine.writeOffAmount = checkLine.writeOffAmount ?? 0,

      checkLine.serverCheckLine = {
        claimNumber: checkLine.claimNumber ?? '',
        payment: checkLine.payment,
        writeOffAmount: checkLine.writeOffAmount ?? 0,
      };

    checkLine.isDirty = false;

    checkLine.patientName = getPatientName(checkLine.patientId);
    checkLine.paymentInDollars = checkLine.payment / 100;
    checkLine.writeOffInDollars = checkLine.writeOffAmount / 100;
    hydrateCheckLineMissing(checkLine);
    hydrateCheckLinePatientName(checkLine);
  }

  /**
   * Set 'missing' property on checkLine object.
   *
   * @param {object} checkLine
   */
  function hydrateCheckLineMissing(checkLine) {
    checkLine.missing = checkLine.expectedTotalAmount - (checkLine.payment + checkLine.writeOffAmount);
  }

  /**
   * Set 'patientName' property on checkLine object.
   *
   * @param {object} checkLine
   */
  function hydrateCheckLinePatientName(checkLine) {
    checkLine.patientName = getPatientName(checkLine.patientId)
  }

  /**
   * Set 'patientName' property on unappliedCheckLine object.
   *
   * @param {object} unappliedCheckLine
   */
  function hydrateUnappliedCheckLine(unappliedCheckLine) {
    unappliedCheckLine.patientName = `${unappliedCheckLine.patientFirstName} ${unappliedCheckLine.patientLastName}`;
  }

  /**
   * Initialize tables
   * @param {object} items
   */
  const initTable = function (items) {
    const oldTotal = $scope.tableParams?.total?.() || 0;
    let count = 25;
    let sorting = {};
    let page = false;
    if ($scope.tableParams) {
      count = $scope.tableParams.count();
      sorting = $scope.tableParams.sorting();
      page = $scope.tableParams.page();
    }
    const options = {
      sorting: sorting,
      count: count
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items
    });
    if ($scope.globalFilter && $scope.globalFilter.val && $scope.globalFilter.val.length > 0) {
      $scope.applyGlobalSearch($scope.globalFilter.val)
    }
    if (page && oldTotal === $scope.tableParams.total()) $scope.tableParams.page(page);
  };

  const initUnappliedTable = (items) => {
    const oldTotal = $scope.unappliedTableParams?.total?.() || 0;
    let count = 25;
    let sorting = {};
    let page = false;
    if ($scope.unappliedTableParams) {
      count = $scope.unappliedTableParams.count();
      sorting = $scope.unappliedTableParams.sorting();
      page = $scope.unappliedTableParams.page();
    }
    const options = {
      sorting: sorting,
      count: count
    };
    $scope.unappliedTableParams = new NgTableParams(options, {
      dataset: items
    });
    if ($scope.globalFilter && $scope.globalFilter.val && $scope.globalFilter.val.length > 0) {
      $scope.applyGlobalSearch($scope.globalFilter.val)
    }
    if ($scope.filters && $scope.filters.isApplied !== undefined) {
      $scope.filterIsApplied($scope.filters.isApplied);
    }
    if (page && oldTotal === $scope.unappliedTableParams.total()) $scope.unappliedTableParams.page(page);
  };

  $scope.fillPaymentFromRequested = (checkLine) => {
    // NOTE: Unlike the old strange loop, we set update the payment even if the current balance is negative.
    // Previously, the payment would be left unchanged in case of a negative balance, which is less consistent.

    // Add the difference between the expected payment and the current payment, while respecting the balance.
    // This may lead to a negative payment if balance < -checkLine.payment, but in case this is undesired, the undo
    // button can be used to revert the payment fill.
    const payment = checkLine.payment +
      Math.min(checkLine.expectedTotalAmount - checkLine.payment, $scope.getBalance());

    checkLine.paymentInDollars = payment / 100;
    $scope.updateCheckLine(checkLine); // Sets checkLine.payment using checkLine.paymentInDollars.
  }

  $scope.goToPatient = function (id) {
    $rootScope.openPatientModal(id);
  };

  $scope.globalFilter = { val: "" };

  $scope.filters = {
    isApplied: undefined
  };

  $scope.filterIsApplied = (flaggedAsApplied) => {
    $scope.filters.isApplied = flaggedAsApplied;
    const filter = { flaggedAsApplied: flaggedAsApplied };
    if ($scope.unappliedTableParams) {
      angular.extend($scope.unappliedTableParams.filter(), filter);
    }
  };

  $scope.applyGlobalSearch = function (term) {
    const filter = { $: term };
    angular.extend($scope.tableParams.filter(), filter);
    angular.extend($scope.unappliedTableParams.filter(), filter);
  };

  $scope.deleteCheckLine = (checkLine) => {
    checkLine.isMarkedForDeletion = true;
    checkLine.paymentInDollars = 0;
    checkLine.writeOffInDollars = 0;
    $scope.updateCheckLine(checkLine);
  }

  $scope.updateCheckCentsParam = (paramName) => {
    const am = new Currency().fromMajor($scope.checkBatch["edit_" + paramName], 0).toMinor();

    // No validation, just update.. Okay.. I guess?
    // No consistency in our inputs at all.
    $scope.checkBatch["edit_" + paramName] = am / 100;
    BillingChecksService.updateCheckById({
      checkId: $scope.checkBatchId,
      [paramName]: am,
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    }).then(() => {
      toaster.pop("success", `Updated ${paramName} successfully`);
      $scope.checkBatch[paramName] = am;
    }).catch((err) => {
      toaster.pop("warning", `something went wrong, ${paramName} wasn't updated`);
    });
  };

  $scope.updateCheckParam = (paramName) => {
    BillingChecksService.updateCheckById({
      checkId: $scope.checkBatchId,
      [paramName]: $scope.checkBatch[paramName],
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    }).then(() => {
      toaster.pop("success", `Updated ${paramName} successfully`);
    }).catch((err) => {
      toaster.pop("warning", `something went wrong, ${paramName} wasn't updated`);
    });
  };

  $scope.updateCheckDate = () => {
    BillingChecksService.updateCheckById({
      checkId: $scope.checkBatchId,
      checkDate: $scope.checkBatch.checkDate,
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    }).then(() => {
      toaster.pop("success", "Updated check date successfully");
    }).catch((err) => {
      toaster.pop("warning", "something went wrong", "Check date wasn't updated");
    });
  };

  $scope.updateDepositDate = () => {
    BillingChecksService.updateCheckById({
      checkId: $scope.checkBatchId,
      depositDate: $scope.checkBatch.depositDate,
      agencyId: $rootScope.agencyId,
      agencyMemberId: $rootScope.agencyMemberId
    }).then(() => {
      toaster.pop("success", "Deposit date updated successfully");
    }).catch((err) => {
      toaster.pop("warning", "something went wrong", "Deposit date wasn't updated");
    });
  };

  $scope.handleTabChange = (newTab) => {
    $scope.selectedTab = newTab;
  };

  $scope.handleChangeFlaggedAsApplied = (unappliedCheckLine) => {
    const url = wildcard(
      "agencies/:agencyId/unapplied_check_lines/:unappliedCheckLineId",
      $rootScope.agencyId,
      unappliedCheckLine.id
    );
    const body = {
      flaggedAsApplied: unappliedCheckLine.flaggedAsApplied
    };
    DatabaseApi.patch(url, body).then((res) => {
      toaster.pop("success", "Success");
      initialize();
    }, (err) => {
      unappliedCheckLine.flaggedAsApplied = !unappliedCheckLine.flaggedAsApplied;
      toaster.pop("error", "Error", "Failed updating unapplied check line");
    });
  };

  $scope.$on("refresh_visits", function () {
    // TODO: Not sure when or if this gets called, but it would reset all unsaved changes.
    initialize();
  });


  // Just a hack so it doesn't look ridiculous.
  function initEditCentsFields () {
    if ($scope.checkBatch) {
      $scope.checkBatch.edit_interest = $scope.checkBatch.interest / 100;
      $scope.checkBatch.edit_forwardingBalance = $scope.checkBatch.forwardingBalance / 100;
      $scope.checkBatch.edit_overpaymentRecovery = $scope.checkBatch.overpaymentRecovery / 100;
      $scope.checkBatch.edit_nonreimbursable = $scope.checkBatch.nonreimbursable / 100;
      $scope.checkBatch.edit_levyAmount = $scope.checkBatch.levyAmount / 100;
      $scope.checkBatch.edit_lumpSum = $scope.checkBatch.lumpSum / 100;
      $scope.checkBatch.edit_adjustment = $scope.checkBatch.adjustment / 100;
      $scope.checkBatch.edit_providerRefund = $scope.checkBatch.providerRefund / 100;
      $scope.checkBatch.edit_amount = $scope.checkBatch.amount / 100;
    }
  }

  $scope.exportExcel = () => {
    const url = wildcard(
      "agencies/:agencyId/agency_members/:agencyMemberId/check_batches/:checkId/export",
      $rootScope.agencyId,
      $rootScope.agencyMemberId,
      $scope.checkBatch.id
    );
    DatabaseApi.get(url)
      .then((rsp) => {
        const fileUrl = rsp?.data?.url;
        if (!fileUrl) return;

        const downloadLink = document.createElement("a");
        downloadLink.setAttribute("href", fileUrl);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
      .catch(() => {
        toaster.pop("error", "Something went wrong", "Couldn't get file")
      })
  }

  initialize();
};
