import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  IconButton,
  IconProps,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { LayoutGroup, motion } from "framer-motion";
import { Messages } from "../../../../core/api";
import Dialpad from "../../../../shared/components/DialPad";
import DurationTimer from "../../../../shared/components/DurationTimer";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import useSingleDigitDialPad from "../../../../shared/hooks/useSingleDigitDialPad";
import BaselinePersonAddAlt1Icon from "../../../../shared/icons/BaselinePersonAddAlt1Icon";
import DialpadIcon from "../../../../shared/icons/DialpadIcon";
import HeadphonesIcon from "../../../../shared/icons/HeadphonesIcon";
import MicMuteFillIcon from "../../../../shared/icons/MicMuteFillIcon";
import PhoneHangupIcon from "../../../../shared/icons/PhoneHangupIcon";
import {
  AgencyMemberId,
  CommCenterTicketId,
  TelephonyCallId,
  TelephonyCallParticipantId,
} from "../../../../shared/schema/schema";
import { fmap } from "../../../../shared/utils";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";
import { phoneFormatter } from "../../../../shared/utils/phone-formatter";
import { isParticipantRemoveable } from "../../../call-center/call-center.utils";
import useCallCenter from "../../../call-center/hooks/useCallCenter";
import useInviteToCallModal from "../../../call-center/hooks/useInviteToCallModal";
import usePhoneProvider from "../../../call-center/hooks/usePhoneProvider";
import { hasActiveAgencyMemberInCall } from "../../utils/communication-utils";
import CallTicketParticipantCard from "../CallTicketParticipantCard";
import LiveBadge from "../LiveBadge";
import RingingBadge from "../RingingBadge";
import CallNoteCard from "./CallNoteCard";
import CallNotesSection from "./CallNotesSection";
import DialerPatientNYIAActions from "./DialerPatientNYIAActions";
import CallTicketColumn from "./helpers/CallTicketColumn";
import CallTicketEventCard from "./helpers/CallTicketEventCard/CallTicketEventCard";
import CallTicketRow from "./helpers/CallTicketRow";
import { CommCenterCallTicket, isPatientTicket } from "./helpers/call-ticket-utils";
import useCallTicketNotes from "./hooks/useCallTicketNotes";

const ICON_PROPS: IconProps = {
  cursor: "var(--icon-cursor)",
  boxSize: "var(--icon-box-size)",
  color: "var(--icon-color)",
};

interface Props {
  activeTicket: CommCenterCallTicket;
  callInfo: Messages["TelephonyCallInfo"];
}

export default function LiveCallTicket(props: Props) {
  const { agencyMember } = useAuthData();
  const callCenter = useCallCenter();
  const singleDigitDialer = useDisclosure();
  const inviteToCallModal = useInviteToCallModal();
  const phoneProvider = usePhoneProvider();
  const { state: singleDigitDialPadState } = useSingleDigitDialPad({
    onClickDigit: (digit) => {
      phoneProvider.sendDtmfTone(digit);
    },
  });
  const notesSectionState = useCallTicketNotes({
    ticket: props.activeTicket,
    callId: props.callInfo.id,
  });

  const handleClickHangup = () => {
    if (callCenter.state.currentCall !== null) {
      callCenter.hangup();
    }
  };

  const checkIsInCall = () => {
    if (
      callCenter.state === null ||
      callCenter.state.currentCall === null ||
      callCenter.state.currentCall.id !== props.callInfo.id
    ) {
      return false;
    }

    switch (callCenter.state.status) {
      case "Active":
      case "Calling":
      case "Ringing":
        return true;
    }
  };

  const handleClickAddToCall = () => {
    inviteToCallModal.open({ callId: props.callInfo.id });
  };

  const handleClickMuteUnmute = () => {
    if (callCenter.state.currentCall !== null) {
      callCenter.toggleMute();
    }
  };

  const isInCall = checkIsInCall();

  return (
    <Flex
      backgroundSize="60%"
      borderRadius="0 12px 12px 0"
      direction="row"
      gap={2}
      height="100%"
      justifyContent="space-between"
      padding={2}
    >
      <CallTicketColumn>
        <CallTicketRow>
          <Flex flexDirection="column" gap="50px" py="10px">
            <Flex align="center" flexDirection="column" gap={4}>
              <Text fontSize="5xl">
                {props.callInfo.connectedAt !== null ? (
                  <DurationTimer instant={props.callInfo.connectedAt} />
                ) : (
                  "--:--"
                )}
              </Text>
              <Text>
                {(() => {
                  switch (props.callInfo.direction) {
                    case "Inbound":
                      return phoneFormatter.formatNationalIfValid(props.callInfo.caller.source);
                    case "Outbound":
                    case "Parallel":
                      return fmap(
                        props.callInfo.callee?.source,
                        phoneFormatter.formatNationalIfValid
                      );
                  }
                })()}
              </Text>
              {props.callInfo.status === "InProgress" && <LiveBadge />}
              {props.callInfo.status === "Ringing" && <RingingBadge />}
            </Flex>
            <Box
              sx={{ "--icon-box-size": "40px", "--icon-color": "gray", "--icon-cursor": "pointer" }}
            >
              {isInCall ? (
                <JoinedCallActions
                  isMuted={callCenter.state.isMuted}
                  onClickAddToCall={handleClickAddToCall}
                  onClickDialer={singleDigitDialer.onOpen}
                  onClickHangup={handleClickHangup}
                  onClickMute={handleClickMuteUnmute}
                />
              ) : (
                <NotJoinedCallActions
                  hasCallBeenAnswered={hasActiveAgencyMemberInCall(props.callInfo)}
                  ticketId={props.callInfo.ticket.id}
                />
              )}
            </Box>
          </Flex>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Notes</CallTicketRow.Header>
          <CallTicketRow.Content>
            <CallNotesSection {...notesSectionState} ticketId={props.callInfo.ticket.id} />
            {props.callInfo.notes?.map((note) => (
              <CallNoteCard key={note.id} note={note} />
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>
      <CallTicketColumn>
        <CallTicketRow flexShrink={0}>
          <CallTicketRow.Header>Call Parties</CallTicketRow.Header>
          <CallTicketRow.Content>
            <LayoutGroup>
              {sortActiveOnCallFirst(props.callInfo.participants).map((participant, i) => (
                <motion.div key={participant.id} layout layoutScroll={true}>
                  <CallTicketRow.ContentRow>
                    <CallTicketParticipantCard
                      actions={
                        <ParticipantActions
                          agencyMemberId={agencyMember.id}
                          callId={props.callInfo.id}
                          participant={participant}
                        />
                      }
                      index={i}
                      participant={participant}
                    />
                  </CallTicketRow.ContentRow>
                </motion.div>
              ))}
            </LayoutGroup>
          </CallTicketRow.Content>
        </CallTicketRow>
        <CallTicketRow>
          <CallTicketRow.Header>Call Events</CallTicketRow.Header>
          <CallTicketRow.Content>
            {props.callInfo.participantEvents.map((event) => (
              <CallTicketRow.ContentRow key={JSON.stringify(event)}>
                <CallTicketEventCard event={event} />
              </CallTicketRow.ContentRow>
            ))}
          </CallTicketRow.Content>
        </CallTicketRow>
      </CallTicketColumn>

      <Modal isCentered isOpen={singleDigitDialer.isOpen} onClose={singleDigitDialer.onClose}>
        <ModalOverlay zIndex={10001} />
        <ModalContent containerProps={{ zIndex: 10001 }} display="flex" paddingY={6}>
          <ModalCloseButton />
          <Center as={Flex} flexDirection="column" gap={2} h="full">
            <ModalHeader>
              <Heading>Enter Input</Heading>
            </ModalHeader>
            <Dialpad state={singleDigitDialPadState} />
            {isPatientTicket(props.activeTicket) && (
              <DialerPatientNYIAActions
                patientId={props.activeTicket.relatedPatient.id}
                onClickMultipleDigits={singleDigitDialPadState.onClickMultipleDigits}
              />
            )}
          </Center>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

const JoinedCallActions = (props: {
  isMuted: boolean;
  onClickHangup: () => void;
  onClickDialer: () => void;
  onClickAddToCall: () => void;
  onClickMute: () => void;
}) => {
  return (
    <Grid gap={6} maxW="fit-content" mx="auto" templateColumns="repeat(2, 1fr)">
      <Center>
        <MicMuteFillIcon
          {...ICON_PROPS}
          color={props.isMuted ? "blue.500" : ICON_PROPS.color}
          onClick={props.onClickMute}
        />
      </Center>
      <Center>
        <DialpadIcon {...ICON_PROPS} onClick={props.onClickDialer} />
      </Center>
      <Center>
        <BaselinePersonAddAlt1Icon {...ICON_PROPS} onClick={props.onClickAddToCall} />
      </Center>
      <Center>
        <PhoneHangupIcon {...ICON_PROPS} onClick={props.onClickHangup} />
      </Center>
    </Grid>
  );
};

const NotJoinedCallActions = (props: {
  ticketId: CommCenterTicketId;
  hasCallBeenAnswered: boolean;
}) => {
  const callCenter = useCallCenter();

  return (
    <Flex alignItems="center" flexDirection="row" gap={2} justifyContent="center" w="full">
      {props.hasCallBeenAnswered ? (
        <>
          <BigIconButton
            aria-label="answer"
            icon={<HeadphonesIcon />}
            isDisabled={true}
            variant="ghost"
          />
          <Tooltip label="Join the call">
            <BigIconButton
              aria-label="answer"
              icon={<BaselinePersonAddAlt1Icon />}
              isLoading={callCenter.answerByTicket.isPending}
              variant="ghost"
              onClick={() =>
                callCenter.answerByTicket.mutate({ ticketId: props.ticketId, intent: "BargeIn" })
              }
            />
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip label="Pick up the call">
            <BigIconButton
              aria-label="answer"
              icon={<PhoneIcon />}
              isDisabled={callCenter.answerByTicket.isPending}
              variant="ghost"
              onClick={() =>
                callCenter.answerByTicket.mutate({ ticketId: props.ticketId, intent: "Auto" })
              }
            />
          </Tooltip>
        </>
      )}
    </Flex>
  );
};

const BigIconButton = chakra(IconButton, {
  baseStyle: {
    fontSize: "4xl",
    width: 16,
    height: 16,
  },
});

function ParticipantActions(props: {
  agencyMemberId: AgencyMemberId;
  participant: Messages["TelephonyCallParticipantInfo"];
  callId: TelephonyCallId;
}) {
  const isSelfParticipant =
    props.participant.entity.type === "AgencyMember" &&
    props.participant.entity.id === props.agencyMemberId;

  if (!isParticipantRemoveable(props.participant) || isSelfParticipant) {
    return <></>;
  }

  return <RemoveFromCallButton callId={props.callId} participantId={props.participant.id} />;
}

function RemoveFromCallButton(props: {
  participantId: TelephonyCallParticipantId;
  callId: TelephonyCallId;
}) {
  const { api } = useApi();
  const toast = useToast();
  const remove = useMutation({
    mutationFn: () => {
      return api.post("./telephony/calls/:callId/participants/:participantId/remove", {
        path: {
          callId: props.callId,
          participantId: props.participantId,
        },
      });
    },
    onError: (error) => {
      toast({
        title: "Could not remove participant",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      toast({
        title: "Participant removed",
        status: "success",
        position: "top-right",
      });
    },
  });

  return (
    <Tooltip label="Remove from call">
      <IconButton
        aria-label="remove"
        colorScheme="red"
        icon={<DeleteIcon />}
        isLoading={remove.isPending}
        rounded="full"
        variant="ghost"
        onClick={() => remove.mutate()}
      />
    </Tooltip>
  );
}

function sortActiveOnCallFirst(participants: Messages["TelephonyCallParticipantInfo"][]) {
  return participants.slice().sort((a, b) => {
    if (a.isActiveOnCall && !b.isActiveOnCall) {
      return -1;
    }

    if (!a.isActiveOnCall && b.isActiveOnCall) {
      return 1;
    }

    return 0;
  });
}
