import { keepPreviousData, queryOptions } from "@tanstack/react-query";
import { BodyOf, QueryParamsOf } from "../../core/api";
import { createQueryGroup } from "../../core/api/queries";

export default createQueryGroup(({ api }) => ({
  invoicing: (query: QueryParamsOf<"get", "./invoice_administrative_payment/invoicing">) => {
    return queryOptions({
      queryKey: ["administrative_payment", "invoicing", query],
      queryFn: () => api.get("./invoice_administrative_payment/invoicing", { query }),
      placeholderData: keepPreviousData,
    });
  },
  invoices: (query: QueryParamsOf<"get", "./invoice_administrative_payment">) => {
    return queryOptions({
      queryKey: ["administrative_payment", "invoice", query],
      queryFn: () => api.get("./invoice_administrative_payment", { query }),
      placeholderData: keepPreviousData,
    });
  },
  bill: (body: BodyOf<"post", "./invoice_administrative_payment">) => {
    return queryOptions({
      queryKey: ["administrative_payment", "bill"],
      queryFn: () => api.post("./invoice_administrative_payment", { body }),
    });
  },
  export: (body: BodyOf<"post", "./invoice_administrative_payment/export">) => {
    return queryOptions({
      queryKey: ["administrative_payment", "export"],
      queryFn: () => api.post("./invoice_administrative_payment/export", { body }),
    });
  },
}));
