import { useDisclosure, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { BodyOf } from "../../../core/api";
import useApi from "../../../shared/hooks/useApi";
import { IntakeChecklistItemId, NoteId, PatientId } from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { ChecklistItemWithBlocking } from "../shared/utils/intakeChecklist.utils";

type UpdateChecklistItemParams = BodyOf<
  "post",
  "./patient_intake/:patientId/checklist/:checklistItemId"
> & {
  checklistItemId: IntakeChecklistItemId;
};
export type UpdateChecklistItemParamsWithoutNoteId = Omit<UpdateChecklistItemParams, "noteId">;

function useIntakeChecklist(props: { patientId: PatientId }) {
  const queryClient = useQueryClient();
  const { api, queries } = useApi();
  const toast = useToast();
  const confirmBulkUncheckDisclosure = useDisclosure();
  const newNoteDisclosure = useDisclosure();

  const [clickedChecklistItem, setClickedChecklistItem] = React.useState<
    ChecklistItemWithBlocking | undefined
  >(undefined);
  const [changeChecklistItemParams, setChangeChecklistItemParams] = React.useState<
    UpdateChecklistItemParamsWithoutNoteId | undefined
  >(undefined);
  const [allItemsThatWillBeUnchecked, setAllItemsThatWillBeUnchecked] = React.useState<
    IntakeChecklistItemId[]
  >([]);

  const checklistItemMutation = useMutation({
    mutationFn: (params: UpdateChecklistItemParams) =>
      api.post("./patient_intake/:patientId/checklist/:checklistItemId", {
        path: { patientId: props.patientId, checklistItemId: params.checklistItemId },
        body: params,
      }),
    onSuccess: () => {
      toast({
        title: "Checklist item update successfully",
        status: "success",
        position: "top-right",
      });
      queryClient.invalidateQueries(queries.intake.checklist(props.patientId));
      queryClient.invalidateQueries(queries.intake.history(props.patientId));
    },
    onError: (error) => {
      toast({
        title: "Error while updating checklist item",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const builkUncheckMutation = useMutation({
    mutationFn: (params: BodyOf<"put", "./patient_intake/:patientId/checklist/uncheck_bulk">) =>
      api.put("./patient_intake/:patientId/checklist/uncheck_bulk", {
        path: { patientId: props.patientId },
        body: params,
      }),
    onSuccess: () => {
      toast({
        title: "Items were unchecked successfully",
        status: "success",
        position: "top-right",
      });
      queryClient.invalidateQueries(queries.intake.checklist(props.patientId));
    },
    onError: (error) => {
      toast({
        title: "Error while updating checklist item",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const handleClickChecklistItem = (
    item: ChecklistItemWithBlocking,
    params: UpdateChecklistItemParamsWithoutNoteId
  ) => {
    setClickedChecklistItem(item);
    setChangeChecklistItemParams(params);

    if (!params.isCompleted && item.blocking.length > 0) {
      confirmBulkUncheckDisclosure.onOpen();
    } else {
      newNoteDisclosure.onOpen();
    }
  };

  const handleConfirmBulkUncheck = async (ids: IntakeChecklistItemId[]) => {
    setAllItemsThatWillBeUnchecked(ids);
    confirmBulkUncheckDisclosure.onClose();
    newNoteDisclosure.onOpen();
  };

  const handleNoteSaved = (noteId: NoteId) => {
    if (clickedChecklistItem !== undefined && changeChecklistItemParams !== undefined) {
      if (allItemsThatWillBeUnchecked.length > 0) {
        builkUncheckMutation.mutate({
          items: allItemsThatWillBeUnchecked,
          params: { ...changeChecklistItemParams, noteId },
        });
      } else {
        checklistItemMutation.mutate({ ...changeChecklistItemParams, noteId });
      }
      queryClient.invalidateQueries(queries.intake.checklist(props.patientId));
      setClickedChecklistItem(undefined);
      setAllItemsThatWillBeUnchecked([]);
    }
  };

  return {
    clickedChecklistItem,
    changeChecklistItemParams,
    confirmBulkUncheckDisclosure,
    newNoteDisclosure,
    clickChecklistItem: handleClickChecklistItem,
    confirmBulkUncheck: handleConfirmBulkUncheck,
    onSaveNote: handleNoteSaved,
  };
}

export default useIntakeChecklist;
