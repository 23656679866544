import { Box, Flex } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import {
  EndedParallelCallItem,
  EnqueuedParallelCallItem,
  OngoingParallelCallItem,
  PendingParallelCallItem,
} from "../parallel-calls.types";
import ParallelCallMachineDetectionStatus from "./ParallelCallMachineDetectionStatus";
import ParallelCallTranscriptionText from "./ParallelCallTranscriptionText";
import DispatchedParallelCallStatus from "./DispatchedParallelCallStatus";
import ParallelCallContactName from "./ParallelCallContactName";
import ParallelCallIntakeStatus from "./ParallelCallIntakeStatus";

type Props = {
  item:
    | PendingParallelCallItem
    | EnqueuedParallelCallItem
    | OngoingParallelCallItem
    | EndedParallelCallItem;
  onClassify: (item: OngoingParallelCallItem, as: "VoiceMail" | "Human") => void;
};

export default function ParallelCallBox(props: Props) {
  return (
    <motion.div
      exit={{ opacity: 0 }}
      layout="position"
      layoutId={`parallel-call-box-${props.item.requestCallId}`}
      transition={{ type: "keyframes" }}
    >
      <Flex borderColor="gray.100" borderWidth={1} direction="column" gap={4} p={4} rounded="xl">
        <Flex alignItems="center" gap={8}>
          <Flex h={10} w={24}>
            <AnimatePresence mode="wait">
              <motion.div
                key={`parallel-call-status-${props.item.status}`}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{ opacity: 0, translateX: 24 }}
                initial={{ opacity: 0, translateX: -24 }}
                layout={false}
                transition={{ ease: "easeInOut", duration: 0.2 }}
              >
                <DispatchedParallelCallStatus item={props.item} onClassify={props.onClassify} />
              </motion.div>
            </AnimatePresence>
          </Flex>
          <Flex alignItems="center" gap={2} w={48}>
            <ParallelCallContactName contact={props.item.contact} />
          </Flex>
          <Box color="gray.600" flex={1}>
            <ParallelCallIntakeStatus contact={props.item.contact} />
          </Box>
          <motion.div
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: 48 }}
          >
            <ParallelCallMachineDetectionStatus contact={props.item.contact} />
          </motion.div>
        </Flex>
        {(() => {
          switch (props.item.status) {
            case "InQueue":
            case "Pending":
            case "Dialing":
            case "Queued":
              return;
            case "Classifying":
            case "VoiceMail":
            case "ManualClassificationRequired":
            case "Human":
            case "Canceled":
            case "Failed":
            case "Completed":
            case "NoAnswer":
              return <ParallelCallTranscriptionText item={props.item} />;
          }
        })()}
      </Flex>
    </motion.div>
  );
}
