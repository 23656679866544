import { Center, Flex, IconButton, SystemStyleObject, Tooltip } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { Messages } from "../../../../../core/api";
import ClockIcon from "../../../../../shared/icons/ClockIcon";
import PlaylistAddCheckIcon from "../../../../../shared/icons/PlaylistAddCheckIcon";
import PlaylistAddIcon from "../../../../../shared/icons/PlaylistAddIcon";
import RocketLaunchOutlineIcon from "../../../../../shared/icons/RocketLaunchOutlineIcon";
import WarningOutlineIcon from "../../../../../shared/icons/WarningOutlineIcon";
import { fmap } from "../../../../../shared/utils";
import { dateFormatter } from "../../../../../shared/utils/date-formatter";
import { NY_ZONE_ID } from "../../../../../shared/utils/date-utils";
import ParallelCallContactName from "./ParallelCallContactName";
import ParallelCallIntakeStatus from "./ParallelCallIntakeStatus";
import { useRouter } from "@uirouter/react";

type Props = {
  contact: Messages["TelephonyParallelCallContactInfo"];
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
};

export default function ListedParallelCallItem(props: Props) {
  const { contact } = props;
  const isOverdue = fmap(contact.nextCallDate, (x) => LocalDateTime.now(NY_ZONE_ID).isAfter(x));
  const { stateService } = useRouter();

  return (
    <ListedParallelCallItemLayout
      boost={fmap(contact.boostType, (boostType) => (
        <Flex alignItems="center" color="gray.500" fontSize="sm" gap={1}>
          <RocketLaunchOutlineIcon />
          {boostType}
        </Flex>
      ))}
      checkButton={
        <IconButton
          isRound
          aria-label="Add to queue"
          borderColor={props.isChecked ? "blue.300" : "gray.300"}
          colorScheme={props.isChecked ? "blue" : "gray"}
          variant={props.isChecked ? "outline" : "ghost"}
          onClick={() => props.onChange(!props.isChecked)}
        >
          {props.isChecked ? (
            <PlaylistAddCheckIcon fontSize="xl" />
          ) : (
            <PlaylistAddIcon fontSize="xl" />
          )}
        </IconButton>
      }
      contactName={<ParallelCallContactName contact={contact} />}
      intakeStatus={<ParallelCallIntakeStatus contact={contact} />}
      nextCallDate={fmap(contact.nextCallDate, (nextCallDate) => (
        <Flex alignItems="center" color={isOverdue ? "red" : "gray.500"} fontSize="sm" gap={1}>
          {isOverdue ? (
            <Tooltip bg="red" color="white" label="Overdue">
              <WarningOutlineIcon />
            </Tooltip>
          ) : (
            <ClockIcon />
          )}
          Scheduled for {dateFormatter.toDateOrDateTime(nextCallDate)}
        </Flex>
      ))}
      sx={{ _hover: { bg: "gray.50", cursor: "pointer" } }}
      onClick={(e) => {
        const target = e.target as HTMLElement;
        const hasClickedInsideButton = target.closest("button");

        if (!hasClickedInsideButton) {
          stateService.go(".", { intakePatientId: contact.id });
        }
      }}
    />
  );
}

function ListedParallelCallItemLayout(props: {
  contactName: React.ReactNode;
  intakeStatus: React.ReactNode;
  nextCallDate: React.ReactNode;
  boost: React.ReactNode;
  checkButton: React.ReactNode;
  sx?: SystemStyleObject;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <Flex
      alignItems="center"
      bg="white"
      borderBottomWidth={1}
      gap={5}
      px={5}
      py={3}
      sx={props.sx}
      onClick={props.onClick}
    >
      <Flex alignItems="center" flex={1} justifyContent="space-between" textAlign="start">
        <Flex direction="column" flex={1} gap={1}>
          {props.contactName}
          {props.intakeStatus}
          {props.nextCallDate}
          {props.boost}
        </Flex>
        <Center flexShrink={0}>{props.checkButton}</Center>
      </Flex>
      {}
    </Flex>
  );
}

ListedParallelCallItem.Layout = ListedParallelCallItemLayout;
