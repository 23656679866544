import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { TelephonyCallId } from "../../shared/schema/schema";
import { ParallelCallItem } from "../patientIntake/pages/parallel-calls/parallel-calls.types";

export default createQueryGroup(({ api }) => ({
  call: (callId: TelephonyCallId) =>
    queryOptions({
      queryKey: ["telephnony", "call", "get", callId],
      queryFn: () => api.get("./telephony/calls/:callId", { path: { callId } }),
    }),

  endpoints: () =>
    queryOptions({
      queryKey: ["telephnony", "call", "endpoints"],
      queryFn: () => api.get("./telephony/endpoints", {}),
      staleTime: Infinity,
    }),

  ringingCalls: () =>
    queryOptions({
      queryKey: ["telephony", "calls", "ringing"],
      queryFn: () => api.get("./telephony/calls/ringing", {}),
    }),

  missedCalls: ({ inLastMinutes }: { inLastMinutes: number }) =>
    queryOptions({
      queryKey: ["telephony", "calls", "missed"],
      queryFn: () =>
        api.get("./telephony/calls/missed", {
          query: { inLastMinutes },
        }),
    }),
  parallel: {
    queue: queryOptions({
      staleTime: Infinity,
      queryKey: ["telephony", "parallel-calls", "queue"],
      queryFn: async () => {
        const { contacts } = await api.get("./telephony/parallel_calls/queue", {});
        return contacts.map((contact): ParallelCallItem => ({ contact, status: "Checked" }));
      },
    }),
  },
}));
