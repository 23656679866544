import { Text } from "@chakra-ui/react";
import { IntakeHistoryChangeLogRowType } from "../../../../shared/utils/intake-change-history";
import { Instant } from "@js-joda/core";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { NEW_YORK_TZ } from "../../../../shared/utils/date-utils";

export const AppointmentHistoryChangeDetails = (props: {
  changeType: IntakeHistoryChangeLogRowType;
  field: string;
  appointment: {
    date: Instant | null;
    clinician: string | null;
    location: string | null;
  };
  from: string | null;
  to: string | null;
}) => {
  return (
    <>
      {props.changeType === "Added" && (
        <Text>
          <u>Date and Time</u>:{" "}
          {props.appointment.date === null
            ? "-"
            : dateFormatter.toDateTime(props.appointment.date, { timezone: NEW_YORK_TZ })}
          <br />
          <u>Clinician Name</u>: {props.appointment.clinician ?? "-"}
          <br />
          <u>Location</u>: {props.appointment.location ?? "-"}
          <br />
        </Text>
      )}
      {props.changeType === "Updated" && (
        <>
          <Text>
            <u>From:</u> {props.from}
          </Text>
          <Text>
            <u>To:</u> {props.to}
          </Text>
        </>
      )}
    </>
  );
};
