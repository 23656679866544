import { Flex, Tag, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Messages } from "../../../../../core/api";
import { getFullName } from "../../../../../shared/utils/get-full-name";
import ParallelCallOrderBadge from "./ParallelCallOrderBadge";
import Link from "../../../../../shared/components/Link";
import { useCurrentStateAndParams } from "@uirouter/react";

type Props = { contact: Messages["TelephonyParallelCallContactInfo"] };

export default function ParallelCallContactName(props: Props) {
  const { state } = useCurrentStateAndParams();

  return (
    <motion.div layoutId={`contact-name-${props.contact.id}`}>
      <Link params={{ intakePatientId: props.contact.id }} to={state.name ?? ""}>
        <Flex alignItems="center" as="span" gap={2}>
          <ParallelCallOrderBadge contact={props.contact} />
          <Text as="span" fontWeight="semibold">
            {getFullName(props.contact)}
          </Text>
          {props.contact.isSelfServe && (
            <Tag colorScheme="blue" size="sm">
              Self Serve
            </Tag>
          )}
        </Flex>
      </Link>
    </motion.div>
  );
}
