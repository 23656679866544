import { ButtonGroup, IconButton, Spinner, Tooltip, chakra } from "@chakra-ui/react";
import PersonOutlineIcon from "../../../../../shared/icons/PersonOutlineIcon";
import VoicemailIcon from "../../../../../shared/icons/VoicemailIcon";
import {
  EndedParallelCallItem,
  EnqueuedParallelCallItem,
  OngoingParallelCallItem,
  PendingParallelCallItem,
} from "../parallel-calls.types";

type Props = {
  item:
    | PendingParallelCallItem
    | EnqueuedParallelCallItem
    | OngoingParallelCallItem
    | EndedParallelCallItem;
  onClassify: (item: OngoingParallelCallItem, as: "VoiceMail" | "Human") => void;
};

export default function DispatchedParallelCallStatus(props: Props) {
  switch (props.item.status) {
    case "Pending":
      return (
        <Root bg="gray.50" color="gray.500">
          Pending
        </Root>
      );
    case "InQueue":
      return (
        <Root bg="gray.50" color="gray.900">
          In Queue
        </Root>
      );
    case "Queued":
      return (
        <Root bg="gray.50" color="gray.900">
          Queued
        </Root>
      );
    case "Dialing":
      return (
        <Root bg="purple.50" color="purple.600">
          Dialing
        </Root>
      );
    case "ManualClassificationRequired": {
      const item = props.item;

      return (
        <ButtonGroup isAttached variant="outline">
          <Tooltip label="Connect Call?">
            <IconButton
              _hover={{ borderColor: "green", bg: "green.50", zIndex: 1 }}
              aria-label="Voicemail"
              icon={<PersonOutlineIcon />}
              px={4}
              rounded="full"
              onClick={() => props.onClassify(item, "Human")}
            />
          </Tooltip>
          <Tooltip label="Mark as VoiceMail">
            <IconButton
              _hover={{ borderColor: "red", bg: "red.50" }}
              aria-label="Voicemail"
              icon={<VoicemailIcon />}
              px={4}
              rounded="full"
              onClick={() => props.onClassify(item, "VoiceMail")}
            />
          </Tooltip>
        </ButtonGroup>
      );
    }
    case "Classifying":
      return (
        <Root bg="pink.50" color="purple.600">
          Classifying
        </Root>
      );
    case "VoiceMail":
      return (
        <Root bg="gray.800" color="gray.50">
          VoiceMail
        </Root>
      );
    case "Canceled":
      return (
        <Root bg="gray.800" color="gray.50">
          Canceled
        </Root>
      );
    case "Failed":
      return (
        <Root bg="gray.800" color="gray.50">
          Failed
        </Root>
      );
    case "Completed":
      return (
        <Root bg="gray.800" color="gray.50">
          Completed
        </Root>
      );
    case "NoAnswer":
      return (
        <Root bg="gray.800" color="gray.50">
          No Answer
        </Root>
      );
    case "Human":
      return (
        <Root bg="green.500" color="green.50">
          Human {props.item.call.status === "Initiated" && <Spinner size="xs" thickness="1.5px" />}
        </Root>
      );
  }
}

const Root = chakra("div", {
  baseStyle: {
    display: "flex",
    gap: 2,
    alignItems: "center",
    justifyContent: "center",
    fontSize: "sm",
    fontWeight: "semibold",
    height: 10,
    lineHeight: 10,
    px: 4,
    rounded: "full",
    w: "fit-content",
    whiteSpace: "nowrap",
  },
});
