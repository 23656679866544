import { Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { Messages } from "../../../../../core/api";

type Props = { contact: Messages["TelephonyParallelCallContactInfo"] };

export default function ParallelCallIntakeStatus(props: Props) {
  return (
    <motion.div layoutId={`contact-intake-status-${props.contact.id}`}>
      <Text fontSize="sm">{props.contact.intakeStatusName}</Text>
    </motion.div>
  );
}
