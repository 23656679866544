import { Button, Center, Flex, Skeleton, Text } from "@chakra-ui/react";
import { LayoutGroup, motion } from "framer-motion";
import useParallelCalls from "../useParallelCalls";
import ListedParallelCallItem from "./ListedParallelCallItem";

export default function ListedParallelCalls() {
  const calls = useParallelCalls();

  return (
    <LayoutGroup>
      <Flex direction="column" minH="full">
        {(() => {
          switch (calls.query.status) {
            case "error":
              return (
                <Center flex={1} flexDirection="column" gap={4}>
                  <Text colorScheme="red" fontSize="lg" p={4} textAlign="center">
                    {String(calls.query.error)}
                  </Text>
                  <Button isLoading={calls.query.isLoading} onClick={() => calls.query.refetch()}>
                    Refetch
                  </Button>
                </Center>
              );
            case "pending":
              return [0, 1, 2, 3, 4, 5].map((i) => (
                <ListedParallelCallItem.Layout
                  key={i}
                  boost={null}
                  checkButton={<Skeleton height={10} rounded="full" width={10} />}
                  contactName={<Skeleton height={6} rounded="full" w={40} />}
                  intakeStatus={<Skeleton height={5} rounded="full" w={60} />}
                  nextCallDate={<Skeleton height={5} rounded="full" w={48} />}
                />
              ));
            case "success":
              return (
                <>
                  {calls.getListedCalls()?.length === 0 && (
                    <Center flex={1} flexDirection="column" gap={4}>
                      <Text fontSize="lg" p={4} textAlign="center">
                        {calls.query.data?.length === 0
                          ? "Congratulations! You've completed all your calls."
                          : "Your queue is empty"}
                      </Text>
                      {calls.getDispatchedCalls()?.length === 0 && (
                        <Button
                          isLoading={calls.query.isLoading}
                          onClick={() => calls.query.refetch()}
                        >
                          Refetch
                        </Button>
                      )}
                    </Center>
                  )}

                  {calls.getListedCalls()?.map(({ contact, status }) => (
                    <motion.div key={contact.source} layout>
                      <ListedParallelCallItem
                        contact={contact}
                        isChecked={status === "Checked"}
                        onChange={(isChecked) => calls.setChecked(contact.id, isChecked)}
                      />
                    </motion.div>
                  ))}
                </>
              );
          }
        })()}
      </Flex>
    </LayoutGroup>
  );
}
