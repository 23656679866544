import { Instant } from "@js-joda/core";
import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import useApi from "../../../../shared/hooks/useApi";
import useAuthData from "../../../../shared/hooks/useAuthInfo";
import useMultipleSocketEvent from "../../../../shared/hooks/useMultipleSocketEvent";
import useSocketEvent from "../../../../shared/hooks/useSocketEvent";
import useCallCenter from "../../../call-center/hooks/useCallCenter";
import { EndedParallelCallItem, EnqueuedParallelCallItem } from "./parallel-calls.types";
import { hasParallelCallStatus, MAX_CONCURRENT_PARALLEL_CALLS } from "./parallel-calls.utils";
import useParallelCalls from "./useParallelCalls";
import useParallelCallsDial from "./useParallelCallsDial";

export default function useParallelCallsListeners() {
  const { queries } = useApi();
  const queryClient = useQueryClient();
  const authData = useAuthData();
  const callCenter = useCallCenter();
  const { stateService } = useRouter();
  const dial = useParallelCallsDial();
  const parallelCalls = useParallelCalls();

  useMultipleSocketEvent({
    keys: [
      "TelephonyParallelCallStarted",
      "TelephonyParallelCallRinging",
      "TelephonyParallelCallAnswered",
      "TelephonyParallelCallCalleeClassified",
    ],
    validate: true,
    onEvent(event) {
      queryClient.setQueryData(queries.telephony.call(event.call.id).queryKey, (prev) => {
        const prevVersion = prev?.call.version ?? 1;
        const nextVersion = event.call.version;

        return nextVersion > prevVersion ? { call: event.call } : prev;
      });
    },
  });

  useSocketEvent({
    key: "TelephonyParallelCallStarted",
    validate: true,
    onEvent: (event) => {
      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
        return prev?.map((item) => {
          if (item.requestCallId !== event.call.id) return item;
          if (!("queuedAt" in item)) return item;

          const prevVersion = item.call?.version ?? 1;
          const nextVersion = event.call.version;

          return nextVersion > prevVersion
            ? { ...item, status: "Queued" as const, call: event.call }
            : item;
        });
      });
    },
  });

  useSocketEvent({
    key: "TelephonyParallelCallRinging",
    validate: true,
    onEvent: (event) => {
      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
        return prev?.map((item) => {
          if (item.requestCallId !== event.call.id) return item;
          if (!("queuedAt" in item)) return item;

          const prevVersion = item.call?.version ?? 1;
          const nextVersion = event.call.version;

          return nextVersion > prevVersion
            ? { ...item, status: "Dialing" as const, call: event.call }
            : item;
        });
      });
    },
  });

  useSocketEvent({
    key: "TelephonyParallelCallAnswered",
    validate: true,
    onEvent: (event) => {
      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
        return prev?.map((item) => {
          if (item.requestCallId !== event.call.id) return item;
          if (!("queuedAt" in item)) return item;

          const prevVersion = item.call?.version ?? 1;
          const nextVersion = event.call.version;

          if (nextVersion <= prevVersion) return item;

          switch (item.status) {
            case "InQueue":
            case "Queued":
            case "Dialing":
              return { ...item, status: "Classifying" as const, call: event.call };
            case "NoAnswer":
            case "Failed":
            case "Canceled":
            case "Completed":
            case "VoiceMail":
            case "ManualClassificationRequired":
            case "Human":
            case "Classifying":
              return item;
          }
        });
      });
    },
  });

  useSocketEvent({
    key: "TelephonyParallelCallCalleeClassified",
    validate: true,
    onEvent: (event) => {
      if (event.call.status === "Canceled") {
        return;
      }

      switch (event.classification.type) {
        case "VoiceMail":
          queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
            return prev?.map((item) => {
              if (item.requestCallId !== event.call.id) return item;
              if (!("queuedAt" in item)) return item;

              const prevVersion = item.call?.version ?? 1;
              const nextVersion = event.call.version;

              return nextVersion > prevVersion
                ? {
                    ...item,
                    status: "VoiceMail" as const,
                    call: event.call,
                    endedAt: Instant.now(),
                  }
                : item;
            });
          });

          break;
        case "Unknown":
          queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
            return prev?.map((item) => {
              if (item.requestCallId !== event.call.id) return item;
              if (!("queuedAt" in item)) return item;

              const prevVersion = item.call?.version ?? 1;
              const nextVersion = event.call.version;

              return nextVersion > prevVersion
                ? { ...item, status: "ManualClassificationRequired" as const, call: event.call }
                : item;
            });
          });

          if (event.call.caller?.entity.id === authData.agencyMember.id) {
            callCenter.connectParallel(event.call);
            callCenter.openCallTicketPopup(event.call);
          }
          break;
        case "Human":
          queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
            return prev?.map((item) => {
              if (item.requestCallId !== event.call.id) return item;
              if (!("queuedAt" in item)) return item;

              const prevVersion = item.call?.version ?? 1;
              const nextVersion = event.call.version;

              return nextVersion > prevVersion
                ? { ...item, status: "Human" as const, call: event.call, endedAt: Instant.now() }
                : item;
            });
          });

          callCenter.connectParallel(event.call);
          callCenter.openCallTicketPopup(event.call);
      }
    },
  });

  useSocketEvent({
    key: "TelephonyParallelCallTranscribed",
    onEvent(event) {
      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
        return prev?.map((item) => {
          return item.requestCallId === event.callId
            ? { ...item, transcription: event.transcription }
            : item;
        });
      });
    },
  });

  useSocketEvent({
    key: "TelephonyCallConnected",
    validate: true,
    onEvent: (event) => {
      if (event.call.direction !== "Parallel") {
        return;
      }

      const call = event.call;

      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
        return prev.map((item) => {
          return item.requestCallId === event.call.id ? { ...item, call } : item;
        });
      });

      const item = queryClient
        .getQueryData(queries.telephony.parallel.queue.queryKey)
        ?.find((item) => item.requestCallId === call.id);

      if (
        item?.status === "Human" &&
        event.call.caller?.entity.id === authData.agencyMember.id &&
        call.callee.entity.type === "Patient"
      ) {
        stateService.go("app.patients.intake-flow", {
          patientId: call.callee.entity.id,
        });
      }
    },
  });

  useSocketEvent({
    key: "TelephonyCallEnded",
    validate: true,
    onEvent: (event) => {
      if (event.call.direction !== "Parallel") {
        return;
      }

      const call = event.call;

      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
        return prev.map((item) => {
          return item.requestCallId === call.id && "queuedAt" in item
            ? ({
                status: ((): EndedParallelCallItem["status"] => {
                  switch (true) {
                    case item.status === "VoiceMail":
                    case call.hasDetectedVoiceMail:
                      return "VoiceMail";
                    case call.status === "NoAnswer":
                    case call.status === "Completed":
                    case call.status === "Canceled":
                    case call.status === "Failed":
                      return call.status;
                    default:
                      return "Failed";
                  }
                })(),
                contact: item.contact,
                requestCallId: item.requestCallId,
                transcription: "transcription" in item ? item.transcription : undefined,
                call: call,
                queuedAt: item.queuedAt,
                endedAt: Instant.now(),
              } satisfies EndedParallelCallItem)
            : item;
        });
      });

      const pending = queryClient
        .getQueryData(queries.telephony.parallel.queue.queryKey)
        ?.filter((item) => hasParallelCallStatus(item, "Pending"));

      const queue: EnqueuedParallelCallItem[] = [];

      const dispatchedCallsCount = parallelCalls
        .getDispatchedCalls()
        .filter((x) => x.requestCallId !== call.id).length;

      for (const item of pending ?? []) {
        if (queue.length + dispatchedCallsCount >= MAX_CONCURRENT_PARALLEL_CALLS) {
          continue;
        }

        if (queue.find((x) => !x.contact.hasVoiceMailTemplate)) {
          continue;
        }

        if (!item.contact.hasVoiceMailTemplate && queue.length > 0) {
          continue;
        }

        queue.push({
          contact: item.contact,
          requestCallId: item.requestCallId,
          queuedAt: Instant.now(),
          status: "InQueue",
        });
      }

      queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
        return prev.map(
          (item) => queue.find((x) => x.requestCallId === item.requestCallId) ?? item
        );
      });

      if (queue.length > 0) {
        dial.mutate({
          calls: queue.map((item) => ({
            source: item.contact.source,
            requestCallId: item.requestCallId,
          })),
        });
      }
    },
  });
}
