import { cacheTemplates } from "@ng-template-cache";
import angular from "angular";
import ngAnimate from "angular-animate";
import "angular-bootstrap-colorpicker";
import "angular-bootstrap-lightbox";
import daterangepicker from "angular-daterangepicker";
import "angular-drag-and-drop-lists/angular-drag-and-drop-lists";
import "angular-google-maps";
import "angular-google-places-autocomplete/src/autocomplete";
import "angular-input-masks/us";
import angularLoad from "angular-load";
import "angular-payments/lib/angular-payments";
import ngSanitize from "angular-sanitize";
import "angular-simple-logger";
import "angular-summernote/dist/angular-summernote";
import "sweetalert/dist/sweetalert.min";
import "angular-sweetalert/SweetAlert";
import ngTouch from "angular-touch";
import ngUiBootstrap from "angular-ui-bootstrap";
import "angular-ui-calendar";
import "angular-ui-grid";
import uiMask from "angular-ui-mask";
import { UI_ROUTER_REACT_HYBRID } from "@uirouter/react-hybrid";
import "angular-ui-sortable";
import "angular-web-notification";
import xeditable from "angular-xeditable";
import "angularjs-count-to/src/count-to";
import "angularjs-dropdown-multiselect/dist/angularjs-dropdown-multiselect.min";
import rzModule from "angularjs-slider";
import toaster from "angularjs-toaster";
import checklistModel from "checklist-model";
import "@js-joda/timezone";
import "leaflet";
import "leaflet.markercluster";
import "moment-timezone";
import ngFileUpload from "ng-file-upload";
import "ng-table/bundles/ng-table";
import ngMap from "ngmap";
import "./vendors/angular-auto-complete/angular-auto-complete";
import "./vendors/angular-ckeditor/angular-ckeditor";
import "./vendors/bootstrap-daterangepicker/bootstrap-daterangepicker";
import "bootstrap";
import { calendarWeekAggregationColumn } from "./admin/scripts/components/calendarWeekAggregationColumn";
import { CaregiverAssignedTasks } from "./admin/scripts/components/caregiverAssignedTasks";
import { caregiverCalendar } from "./admin/scripts/components/caregiverCalendar";
import {
  caregiverTrainingCenter,
  caregiverTrainingCenterBundle,
  caregiverTrainingCenterDueDate,
  caregiverTrainingCenterInfoBundleDueDateHeader,
  masterWeek,
  nursingQuestion,
} from "./admin/scripts/components/components";
import { datePickerWithSideButtonsComponent } from "./admin/scripts/components/datePickerWithSideButtonsComponent";
import { DateTimeDuration } from "./admin/scripts/components/dateTimeDuration";
import { EligibilityChecksPage } from "./admin/scripts/components/eligibilityChecksPage";
import { entityName } from "./admin/scripts/components/entity-name";
import { entityNote } from "./admin/scripts/components/entityNote";
import { entityNotes } from "./admin/scripts/components/entityNotes";
import { entityNoteFiles } from "./admin/scripts/components/entityNoteFiles";
import { entityNoteFilesTable } from "./admin/scripts/components/entityNoteFilesTable";
import { entityNoteSettingPredefinedAnswers } from "./admin/scripts/components/entityNoteSettingPredefinedAnswers";
import { historyTimelineChange } from "./admin/scripts/components/historyTimelineChange";
import { mfCalendar } from "./admin/scripts/components/mfCalendar";
import { MissedVisitSideBar } from "./admin/scripts/components/missedVisitSiderBar";
import { newVersion } from "./admin/scripts/components/newVersion";
import { patientCalendar } from "./admin/scripts/components/patientCalendar";
import { patientDiagnosisCodes } from "./admin/scripts/components/patientDiagnosisCodes";
import { PatientDocumentDropdownButton } from "./admin/scripts/components/patientDocumentDropdownButton";
import { patientMedicationProfile } from "./admin/scripts/components/patientMedicationProfile";
import { patientPhysicians } from "./admin/scripts/components/patientPhysicians";
import { patientPlansOfCareComponent } from "./admin/scripts/components/patientPlansOfCare.component";
import { patientWeeklyTemplate } from "./admin/scripts/components/patientWeeklyTemplate";
import { patientWeeklyTemplateTable } from "./admin/scripts/components/patientWeeklyTemplateTable";
import { personList } from "./admin/scripts/components/personList";
import { physicians } from "./admin/scripts/components/physicians";
import { plyrVideo } from "./admin/scripts/components/plyrVideo";
import { segmentsProgress } from "./admin/scripts/components/segmentsProgress";
import { stateOfHomeCareSurvey } from "./admin/scripts/components/stateOfHomeCareSurvey";
import { patientTaskScheduleDateTime } from "./admin/scripts/components/patientTaskScheduleDateTime";
import { CertificationPeriodSelector } from "./admin/scripts/components/certificationPeriodSelector";
import { PatientTaskModalAssignedType } from "./admin/scripts/components/patientTaskModalAssignedType";
import {
  compileProviderConfig,
  config,
  initialRun,
  uibModalProviderConfig,
} from "./admin/scripts/config";
import { Consts } from "./admin/scripts/config.consts";
import { adjustmentApprovalsConsts } from "./admin/scripts/consts/adjustmentApprovalsConsts";
import { apiErrors } from "./admin/scripts/consts/apiErrors.const";
import { assertNever } from "./admin/scripts/consts/assertNever.const";
import { base64ImageConsts } from "./admin/scripts/consts/base64ImageConsts";
import { CalendarItemType } from "./admin/scripts/consts/calendarItemType";
import {
  apiErrorsConsts,
  caregiverStatus,
  patientStatusUtils,
} from "./admin/scripts/consts/commonConsts";
import { dateRangeDefaultOptions } from "./admin/scripts/consts/dateRangeDefaultOptions";
import { dateUtils } from "./admin/scripts/consts/dateUtils";
import { DropdownSettingsConsts } from "./admin/scripts/consts/dropdown-settings.const";
import { dropdownSettingsConst } from "./admin/scripts/consts/dropdownSettings.const";
import { noteConsts } from "./admin/scripts/consts/noteConsts";
import { visitConsts } from "./admin/scripts/consts/visitConsts";
import { filterValidDropdownFields } from "./admin/scripts/controllers/addHrDocumentModalCtrl";
import { agencyTeamsTable } from "./admin/scripts/controllers/agencyTeamsTableCtrl";
import { agencyUserPermissionsRoles } from "./admin/scripts/controllers/agencyUserPermissionsRolesCtrl";
import {
  humanizedDuration,
  uniquePotentialLink,
} from "./admin/scripts/controllers/callsMaintenanceCtrl";
import { caregiverAttendance } from "./admin/scripts/controllers/caregiverAttendanceComponent";
import { caregiverPayroll } from "./admin/scripts/controllers/caregiverPayroll";
import { caregiversTrainingCenterMaterialStatsTable } from "./admin/scripts/controllers/caregiversTrainingCenterMaterialStatsTable";
import { caregiversTrainingCenterOverviewBundles } from "./admin/scripts/controllers/caregiversTrainingCenterOverviewBundles";
import { caregiversTrainingCenterOverviewTable } from "./admin/scripts/controllers/caregiversTrainingCenterOverviewTable";
import { caregiversTrainingCenterOverviewTotalStatistics } from "./admin/scripts/controllers/caregiversTrainingCenterOverviewTotalStatistics";
import { caregiverVisits } from "./admin/scripts/controllers/caregiverVisits";
import { diagnosisType } from "./admin/scripts/controllers/contractSetupModalCtrl";
import { countyMap } from "./admin/scripts/controllers/countyMapDirective";
import { agencyProduct } from "./admin/scripts/controllers/customerEngagementCtrl";
import {
  bodyDrawingData,
  icdChartFormat,
  icdFormat,
} from "./admin/scripts/controllers/editPatientDocModalCtrl";
import { entityChatsListComponent } from "./admin/scripts/controllers/entityChatsListComponent";
import { entityCustomFieldComponent } from "./admin/scripts/controllers/entityCustomFieldComponent";
import { entityCustomFieldValueComponent } from "./admin/scripts/controllers/entityCustomFieldValueComponent";
import { entityDeleteVisitComponent } from "./admin/scripts/controllers/entityDeleteVisitComponent";
import { entityNewVisitComponent } from "./admin/scripts/controllers/entityNewVisitComponent";
import { entityNotesComponent } from "./admin/scripts/controllers/entityNotesComponent";
import { entitySideBarComponent } from "./admin/scripts/controllers/entitySideBarComponent";
import { filterByNameAndSize } from "./admin/scripts/controllers/filter-by-name-and-size.directive";
import { startFrom } from "./admin/scripts/controllers/globalSearchCtrl";
import { newVisitCaregiverSearch } from "./admin/scripts/controllers/newVisitCaregiverSearch";
import { noteCommentsComponent } from "./admin/scripts/controllers/noteCommentsComponent";
import { patientDutySheetComponent } from "./admin/scripts/controllers/patient-duty-sheet.component";
import { patientMedicalSummary } from "./admin/scripts/controllers/patientMedicalSummaryComponent";
import { patientPlanOfCareComponent } from "./admin/scripts/controllers/patientPlanOfCare";
import { patientTasks } from "./admin/scripts/controllers/patientTasksComponent";
import { patientVisits } from "./admin/scripts/controllers/patientVisits";
import { ProcessorModalProcessGenerator } from "./admin/scripts/controllers/processorModalCtrl";
import { ptoApprovalNotesComponent } from "./admin/scripts/controllers/ptoApprovalNotesComponent";
import { reportToolContent } from "./admin/scripts/controllers/reportToolContent";
import { reportToolTablesAndColumns } from "./admin/scripts/controllers/reportToolTablesAndColumns";
import { settingsAgencyLogoUpload } from "./admin/scripts/controllers/settingsAgencyLogoUpload";
import { teamMemberTable } from "./admin/scripts/controllers/teamMemberTableCtrl";
import { animateOnChange } from "./admin/scripts/controllers/visitsCtrl";
import { visitsListView } from "./admin/scripts/controllers/visitsListView";
import { autocompleteMultiple } from "./admin/scripts/directives/autocomplete-multiple-directive";
import { caregiverPatientDisplayIdAndStatus } from "./admin/scripts/directives/caregiver-patient-display-id-and-status.filter";
import { caregiverPatientSearch } from "./admin/scripts/directives/caregiver-patient-search-directive";
import { caregiverPatientSearchFilter } from "./admin/scripts/directives/caregiver-patient-search.filter";
import { caregiverPayRates } from "./admin/scripts/directives/caregiver-pay-rates";
import { caregiverPayrollTax } from "./admin/scripts/directives/caregiver-payroll-tax";
import { clickAnywhereButHere } from "./admin/scripts/directives/click-anywhere-but-here";
import { contractAndPayment } from "./admin/scripts/directives/contract-and-payment-directive";
import { customProHeader } from "./admin/scripts/directives/custom-pro-header";
import {
  DateDirectivesModule,
  dateFromToPickers,
  dateRangePicker,
} from "./admin/scripts/directives/date-directives";
import { onboardingComponent } from "./admin/modules/onboarding/components/onboarding/onboarding.component";
import {
  americanphoneFilter,
  callsMissedTable,
  capitalize,
  centsToDollarsFilter,
  checkedButton,
  clockLocationBound,
  csvValueWithCommaFilter,
  dateRangeFilter,
  dateStringToLocaleDateFilter,
  displayFullNameFilter,
  fileModel,
  fileUrlField,
  filterByNameAndCodeFilter,
  focusMe,
  formatPatientContractFilter,
  glue,
  hiddenphoneFilter,
  hourMinutesRangeFilter,
  hourMinutesToDateTimeFilter,
  hoursMinutesFilter,
  iboxTools,
  iboxToolsFullScreen,
  includeReplace,
  instantStringToLocaleDateTime,
  introVideo,
  joinFilter,
  matchItemsFilter,
  mfActiveOnScroll,
  mfPreventNewLine,
  mfTextareaAutoHeight,
  mfShortDateFilter,
  mfShortDayOfWeekFilter,
  minimalizaSidebar,
  objectSizeFilter,
  objToArrayFilter,
  onErrorSrc,
  percentage,
  phonenumberFilter,
  pipeItemsFilter,
  replacebrFilter,
  roundedCheckbox,
  secondsToDateTimeFilter,
  secondsToLabelFilter,
  secondsToTimeFilter,
  sideNavigation,
  ssn,
  ssnFilter,
  styledBadge,
  styledLabel,
  timeFilter,
  titlecase,
  toPatient,
  toPatients,
  uppercase,
  viewPermission,
  viewPermissionSome,
} from "./admin/scripts/directives/directives";
import { filterFieldValue } from "./admin/scripts/directives/filter-field-value-directive";
import { globalSearch } from "./admin/scripts/directives/global-search-directive";
import { historyTimeline } from "./admin/scripts/directives/history-timeline";
import { labelWithOptions } from "./admin/scripts/directives/label-with-optinos-directive";
import { mfNumberRange } from "./admin/scripts/directives/mf-number-range";
import { mfTimeInput } from "./admin/scripts/directives/mf-time-input-directive";
import { newVisitChooseDates } from "./admin/scripts/directives/new-visit-choose-dates-directive";
import { flexibleDaysChooseDates } from "./admin/scripts/directives/flexible-days-choose-dates";
import { flexibleHoursChooseDates } from "./admin/scripts/directives/flexible-hours-choose-dates";
import { flexibleDaysAndHoursChooseDates } from "./admin/scripts/directives/flexible-days-and-hours-choose-dates";
import { newVisitFooter } from "./admin/scripts/directives/new-visit-footer-directive";
import { newVisitTimeSpan } from "./admin/scripts/directives/new-visit-time-span-directive";
import { ngEnter } from "./admin/scripts/directives/on-enter";
import { pageTabs } from "./admin/scripts/directives/page-directives";
import { pageTitle } from "./admin/scripts/directives/page-title.directive";
import { paginationPanel } from "./admin/scripts/directives/pagination-directives";
import { patientPayRates } from "./admin/scripts/directives/patient-pay-rates";
import { permissionsManagement } from "./admin/scripts/directives/permissions-management-directive";
import { pngTimeInput } from "./admin/scripts/directives/png-time-input-directive";
import { profileCollapsibleSection } from "./admin/scripts/directives/profile-collapsible-section-directive";
import { selectAutocomplete } from "./admin/scripts/directives/select-autocomplete-directive";
import { sideNavigationItem } from "./admin/scripts/directives/side-navigation-item.directive";
import { spinner } from "./admin/scripts/directives/spinner-directives";
import {
  billingInvoicesToExportTable,
  billingAdministrativePaymentsTable,
  billingVisitActionDataGenerator,
  billingVisitsTable,
  billingVisitsToExportTable,
  importedVisitsTable,
  visitsTableScopeService,
} from "./admin/scripts/directives/table-directives";
import { validateDateInput } from "./admin/scripts/directives/validate-date-input";
import { visitAdjustmentApprovalTable } from "./admin/scripts/directives/visit-instance-approvals-directive";
import { visitTimeSpan } from "./admin/scripts/directives/visit-time-span-directive";
import { visitSectionToggleEdit } from "./admin/scripts/directives/visitSectionToggleEditComponent";
import { activeAgencySettings } from "./admin/scripts/filters/activeAgencySettings";
import { bundleThumbnail } from "./admin/scripts/filters/bundleThumbnail";
import {
  camelCase,
  diffInDays,
  duration,
  emptyString,
  fullName,
  hour,
  jsJodaMonth,
  minutesToClock,
  nameWeekDays,
  numberRange,
  reverse,
  secondsToClock,
} from "./admin/scripts/filters/dataDisplayFilters";
import displayEnvUrl from "./admin/scripts/filters/displayEnvUrl.filter";
import { isEmpty } from "./admin/scripts/filters/isEmpty";
import { mfShortTime } from "./admin/scripts/filters/mfShortTime";
import { onlyActivePatientContracts } from "./admin/scripts/filters/onlyActivePatientContracts";
import { titlecasefromsnake } from "./admin/scripts/filters/title-case-from-snake.filter";
import { mockCallsData } from "./admin/scripts/mockData/mockCallsData";
import { agencyBranchService } from "./admin/scripts/services/agencyBranchService";
import { agencyCustomFieldsService } from "./admin/scripts/services/agencyCustomFieldsService";
import { AgencyLogoUploadService } from "./admin/scripts/services/agencyLogoUploadService";
import { agencyMemberMentionsService } from "./admin/scripts/services/agencyMemberMentionsService";
import { agencyPatientStatusService } from "./admin/scripts/services/agencyPatientStatusService";
import { agencyPermissionsRolesService } from "./admin/scripts/services/agencyPermissionsRolesService";
import { AgencySettingsService } from "./admin/scripts/services/agencySettingsService";
import { allergyService } from "./admin/scripts/services/allergyService";
import { Analytics } from "./admin/scripts/services/analytics";
import { Api } from "./admin/scripts/services/Api";
import { BillingChecksService } from "./admin/scripts/services/billingChecksService";
import { billingInvoicesProcessor } from "./admin/scripts/services/billingInvoicesProcessor";
import { BoostTrainingCenterModalService } from "./admin/scripts/services/boostTrainingCenterModalService";
import { CaregiverModalService } from "./admin/scripts/services/caregiverModalService";
import { caregiverTrainingCenterAddBundleModalService } from "./admin/scripts/services/caregiverTrainingCenterAddBundleModalService";
import { CaregiverTrainingCenterService } from "./admin/scripts/services/caregiverTrainingCenterService";
import { chartGenerator } from "./admin/scripts/services/chartGenerator";
import { ChecksCodesService } from "./admin/scripts/services/checksCodesService";
import { cityTaxCodeService } from "./admin/scripts/services/cityTaxCodeService";
import { ClientSurvey } from "./admin/scripts/services/clientSurvey";
import { ClockinClockoutService } from "./admin/scripts/services/clockinClockoutService";
import { combinePdf } from "./admin/scripts/services/combinePdf";
import { ContractTypeService } from "./admin/scripts/services/contractTypeService";
import { CertificationPeriodService } from "./admin/scripts/services/certificationPeriodService";
import { CreateTaskModalService } from "./admin/scripts/services/createTaskModalService";
import { CopyCertificationSettingsService } from "./admin/scripts/services/copyCertificationSettingsService";
import { CsvExportUtil } from "./admin/scripts/services/csvExportUtil";
import { Currency } from "./admin/scripts/services/currencyService";
import { dataManagerGenerator } from "./admin/scripts/services/dataManagers/dataManagerGenerator";
import { DatabaseApi } from "./admin/scripts/services/db";
import { createDropdownFilter } from "./admin/scripts/services/dropdownFilter";
import { DutySheetService } from "./admin/scripts/services/dutySheetService";
import { EligibilityChecksService } from "./admin/scripts/services/eligibilityChecksService";
import { endpoint } from "./admin/scripts/services/endpoint.service";
import { entityNewVisitModalService } from "./admin/scripts/services/entityNewVisitModalService";
import { entityNoteService } from "./admin/scripts/services/entityNoteService";
import { entityNotesModalService } from "./admin/scripts/services/entityNotesModalService";
import { exportProfileService } from "./admin/scripts/services/exportProfileService";
import { FilterUtils } from "./admin/scripts/services/filterUtils";
import { FormService } from "./admin/scripts/services/formService";
import { generalUtils } from "./admin/scripts/services/generalUtils";
import { GoogleAddressService } from "./admin/scripts/services/googleAddressService";
import { hhaxIntegrationService } from "./admin/scripts/services/hhaxIntegrationService";
import { authInterceptor } from "./admin/scripts/services/interceptor-service";
import { $localstorage } from "./admin/scripts/services/localStorageServices";
import { MasterWeekService } from "./admin/scripts/services/masterWeekService";
import { MfModalFactory } from "./admin/scripts/services/mfModalFactory";
import { MissedVisitService } from "./admin/scripts/services/missedVisitService";
import { noteService } from "./admin/scripts/services/noteService";
import { Notifications } from "./admin/scripts/services/notifications";
import { nursingMedicalStatisticsService } from "./admin/scripts/services/nursingMedicalStatisticsService";
import { OfficesService } from "./admin/scripts/services/officesService";
import { onlyUnique } from "./admin/scripts/services/onlyUnique";
import { OrientationCenterService } from "./admin/scripts/services/orientationCenterService";
import { itemSearchPageManager } from "./admin/scripts/services/pageManagers/itemSearchPageManager";
import { paginationLogic } from "./admin/scripts/services/paginationLogic";
import { PatientDocumentService } from "./admin/scripts/services/patientDocumentService";
import { PatientModalService } from "./admin/scripts/services/patientModalService";
import { PatientService } from "./admin/scripts/services/patientService";
import { PayerService } from "./admin/scripts/services/payerService";
import { physiciansService } from "./admin/scripts/services/physiciansService";
import { planOfCareHHAXService } from "./admin/scripts/services/planOfCareHHAXService";
import { PlanOfCareService } from "./admin/scripts/services/planOfCareService";
import { PlanOfCareTypeService } from "./admin/scripts/services/planOfCareTypeService";
import { ptoNotesModalService } from "./admin/scripts/services/ptoNotesModalService";
import { rangesCalculateService } from "./admin/scripts/services/rangesCalculateService";
import { reportService } from "./admin/scripts/services/reportService";
import { selectionLogic } from "./admin/scripts/services/selectionLogic";
import { Socket } from "./admin/scripts/services/sockets-io-service";
import { Storage } from "./admin/scripts/services/storage";
import { TasksService } from "./admin/scripts/services/tasksService";
import { TimesheetsService } from "./admin/scripts/services/timesheetsService";
import { TimeZoneService } from "./admin/scripts/services/timezoneService";
import { TrainingCenterBundleService } from "./admin/scripts/services/trainingCenterBundleService";
import { TrainingCenterClassroomService } from "./admin/scripts/services/trainingCenterClassroomService";
import { TrainingCenterExemptsService } from "./admin/scripts/services/trainingCenterExemptsService";
import { TrainingCenterOverviewService } from "./admin/scripts/services/trainingCenterOverviewService";
import { vbpService } from "./admin/scripts/services/vbpService";
import { VisitFileUploadService } from "./admin/scripts/services/visitFileUploadService";
import { VisitInstanceModalService } from "./admin/scripts/services/visitInstanceModalService";
import { visitInstanceService } from "./admin/scripts/services/visitInstanceService";
import { VisitStatus } from "./admin/scripts/services/visitStatus";
import { walkthroughsService } from "./admin/scripts/services/walkthroughsService";
import { wildcard } from "./admin/scripts/services/wildcard";
import { registerAppModuleControllers } from "./app.controllers.module";
import "./global-vendors/switchery/switchery";
import "./vendors/angular-moment/angular-moment";
import "./vendors/angular-signature/angular-signature";
// import dateDirectives from "./directives/dateDirectives";
import "./vendors/angular-ui-select/select";
import "./vendors/ng-switchery/ng-switchery";
import "./vendors/signature_pad/signature_pad";
import { ReleaseNotesAddOn } from "./admin/scripts/components/releaseNotesAddOn";
import { registerComplianceModule } from "./admin/modules/compliance/compliance.module";
import { registerSharedModule } from "./admin/modules/shared/shared.module";
import { registerPlanOfCareModule } from "./admin/modules/plan-of-care/plan-of-care.module";
import { LoginDataStorageService } from "./admin/scripts/services/loginDataStorageService";
import { loginComponent } from "./admin/scripts/components/loginComponent";
import { registerVisitBroadcastModule } from "./admin/modules/visit-broadcast/visit-broadcast.module";

import { registerReactAdapterModule } from "./admin/modules/react-adapter/react-adapter.module";
import { registerCaregiverModule } from "./admin/modules/caregiver/caregiver.module";
import { patientBilling } from "./admin/scripts/controllers/patientBilling";
import { registerFaxesModule } from "./admin/modules/faxes/faxes.module";
import { FaxesService } from "./admin/modules/faxes/faxes.service";
import { profileFaxesTable } from "./admin/modules/faxes/components/profile-faxes-table/profile-faxes-table.component";
import { NotificationsService } from "./admin/scripts/services/NotificationsService";
import { registerPatientBpModule } from "./admin/modules/patient-bp/patientbp.module";

import {patientSurplusList} from "./admin/scripts/components/patientSurpluses";
import {patientSurplusInvoiceList} from "./admin/scripts/components/patientSurplusInvoices";
import {patientSurplusChecksList} from "./admin/scripts/components/patientSurplusChecks";
import { angularToReact } from "./admin/scripts/angular-to-react";
import { patientCaregiverRelationships } from "./admin/scripts/components/patientCaregiverRelationships";
import { entityRemoveVisitComponent } from "./admin/scripts/controllers/entityRemoveVisitComponent";
import { registerHoursOverrideModule } from "./admin/modules/cargiver-hours-override/caregiver-hours-override.module";
import { CaregiverHoursOverrideService } from "./admin/modules/cargiver-hours-override/caregiver-hours-override.service";

const dependencies = [
  "ui.router",
  UI_ROUTER_REACT_HYBRID,
  ngAnimate,
  ngTouch,
  ngUiBootstrap,
  "countTo",
  "ngTable",
  ngFileUpload,
  daterangepicker,
  "google.places",
  "uiGmapgoogle-maps",
  "bootstrapLightbox",
  xeditable,
  "oitozero.ngSweetAlert",
  ngSanitize,
  toaster,
  "ui.utils.masks",
  "angular-web-notification",
  "NgSwitchery",
  ngMap,
  "angularPayments",
  "angularMoment",
  // "mightyDatepicker", - failed
  rzModule,
  checklistModel,
  angularLoad,
  "ui.calendar",
  "signature",
  //   dateDirectives,
  "ui.select",
  "summernote",
  "angularjs-dropdown-multiselect",
  // "smoothScroll", - failed
  "dndLists",
  //   "wysiwyg.module",
  "autoCompleteModule",
  "colorpicker.module",
  "ui.sortable",
  "ui.grid",
  "ui.grid.saveState",
  "ui.grid.selection",
  "ui.grid.cellNav",
  "ui.grid.resizeColumns",
  "ui.grid.moveColumns",
  "ui.grid.pinning",
  "ui.grid.grouping",
  "ui.grid.exporter",
  "ui.grid.pagination",
  "ui.grid.autoResize",
  "ckeditor",
  uiMask,
  DateDirectivesModule,
];

export const AppModule = angular
  .module("dashboard", dependencies)

  // Cache template
  .run(cacheTemplates)

  // Configs
  .config(config)
  .config(uibModalProviderConfig)
  .config(compileProviderConfig)

  // Constants
  .constant("apiErrors", apiErrors)
  .constant("dropdownSettings", dropdownSettingsConst)
  .constant("assertNever", assertNever)
  .constant("createDropdownFilter", createDropdownFilter)
  .constant("dateRangeDefaultOptions", dateRangeDefaultOptions)
  .constant("Consts", Consts)
  .constant("noteConsts", noteConsts)
  .constant("dropdownSettingsConsts", DropdownSettingsConsts)
  .constant("visitConsts", visitConsts)
  .constant("bodyDrawingData", bodyDrawingData)
  .constant("adjustmentApprovalsConsts", adjustmentApprovalsConsts)
  .constant("base64ImageConsts", base64ImageConsts)
  .constant("CalendarItemType", CalendarItemType)
  .constant("CaregiverStatus", caregiverStatus)
  .constant("apiErrors", apiErrorsConsts)
  .constant("dateUtils", dateUtils)
  .constant("patientStatusUtils", patientStatusUtils)

  // Services
  .service("planOfCareService", PlanOfCareService)
  .service("planOfCareTypeService", PlanOfCareTypeService)
  .service("patientService", PatientService)
  .service("dutySheetService", DutySheetService)
  .service("ProcessorModalProcessGenerator", ProcessorModalProcessGenerator)
  .service("patientDocumentService", PatientDocumentService)
  .service("tasksService", TasksService)
  .service("missedVisitService", MissedVisitService)
  .service("timesheetsService", TimesheetsService)
  .service("Analytics", Analytics)
  .service("api", Api)
  .service("billingVisitActionDataGenerator", billingVisitActionDataGenerator)
  .service("visitsTableScopeService", visitsTableScopeService)
  .service("billingInvoicesProcessor", billingInvoicesProcessor)
  .service("physiciansService", physiciansService)
  .service("paginationLogic", paginationLogic)
  .service("FilterUtils", FilterUtils)
  .service("FormService", FormService)
  .service("combinePdf", combinePdf)
  .service("chartGenerator", chartGenerator)
  .service("ClientSurvey", ClientSurvey)
  .service("DatabaseApi", DatabaseApi)
  .service("dataManagerGenerator", dataManagerGenerator)
  .service("endpoint", endpoint)
  .service("generalUtils", generalUtils)
  .service("GoogleAddressService", GoogleAddressService)
  .service("itemSearchPageManager", itemSearchPageManager)
  .service("mockCallsData", mockCallsData)
  .service("Notifications", Notifications)
  .service("officesService", OfficesService)
  .service("selectionLogic", selectionLogic)
  .service("Storage", Storage)
  .service("TimeZoneService", TimeZoneService)
  .service("visitInstanceService", visitInstanceService)
  .service("VisitStatus", VisitStatus)
  .service("walkthroughsService", walkthroughsService)
  .service("wildcard", wildcard)
  .service("contractTypeService", ContractTypeService)
  .service("csvExportUtil", CsvExportUtil)
  .service("eligibilityChecksService", EligibilityChecksService)
  .service("payerService", PayerService)
  .service("certificationPeriodService", CertificationPeriodService)
  .service("createTaskModalService", CreateTaskModalService)
  .service("loginDataStorageService", LoginDataStorageService)
  .service("faxesService", FaxesService)
  .service("notificationsService", NotificationsService)
  .service("caregiverHoursOverrideService", CaregiverHoursOverrideService)

  // Factories
  .factory("planOfCareHHAXService", planOfCareHHAXService)
  .factory("hhaxIntegrationService", hhaxIntegrationService)
  .factory("Socket", Socket)
  .factory("authInterceptor", authInterceptor)
  .factory(MfModalFactory.$name, MfModalFactory)
  .factory("noteService", noteService)
  .factory("entityNoteService", entityNoteService)
  .factory("entityNotesModalService", entityNotesModalService)
  .factory("agencyBranchService", agencyBranchService)
  .factory("agencyCustomFieldsService", agencyCustomFieldsService)
  .factory("AgencyLogoUploadService", AgencyLogoUploadService)
  .factory("agencyMemberMentionsService", agencyMemberMentionsService)
  .factory("agencyPatientStatusService", agencyPatientStatusService)
  .factory("agencyPermissionsRolesService", agencyPermissionsRolesService)
  .factory("AgencySettingsService", AgencySettingsService)
  .factory("allergyService", allergyService)
  .factory("BillingChecksService", BillingChecksService)
  .factory("ChecksCodesService", ChecksCodesService)
  .factory("TrainingCenterBundleService", TrainingCenterBundleService)
  .factory("TrainingCenterClassroomService", TrainingCenterClassroomService)
  .factory("TrainingCenterExemptsService", TrainingCenterExemptsService)
  .factory("TrainingCenterOverviewService", TrainingCenterOverviewService)
  .factory("vbpService", vbpService)
  .factory("VisitFileUploadService", VisitFileUploadService)
  .factory("VisitInstanceModalService", VisitInstanceModalService)
  .factory("BoostTrainingCenterModalService", BoostTrainingCenterModalService)
  .factory("CaregiverModalService", CaregiverModalService)
  .factory(
    "caregiverTrainingCenterAddBundleModalService",
    caregiverTrainingCenterAddBundleModalService
  )
  .factory("CaregiverTrainingCenterService", CaregiverTrainingCenterService)
  .factory("cityTaxCodeService", cityTaxCodeService)
  .factory("ClockinClockoutService", ClockinClockoutService)
  .factory("CopyCertificationSettingsService", CopyCertificationSettingsService)
  .factory("Currency", Currency)
  .factory("entityNewVisitModalService", entityNewVisitModalService)
  .factory("exportProfileService", exportProfileService)
  .factory("$localstorage", $localstorage)
  .factory("MasterWeekService", MasterWeekService)
  .factory("nursingMedicalStatisticsService", nursingMedicalStatisticsService)
  .factory("onlyUnique", onlyUnique)
  .factory("OrientationCenterService", OrientationCenterService)
  .factory("PatientModalService", PatientModalService)
  .factory("ptoNotesModalService", ptoNotesModalService)
  .factory("rangesCalculateService", rangesCalculateService)
  .factory("reportService", reportService)

  // Filters
  .filter("activeAgencySettings", activeAgencySettings)
  .filter("instantStringToLocaleDateTime", instantStringToLocaleDateTime)
  .filter("isEmpty", isEmpty)
  .filter("bundleThumbnail", bundleThumbnail)
  .filter("caregiverPatientSearchFilter", caregiverPatientSearchFilter)
  .filter("caregiverPatientDisplayIdAndStatus", caregiverPatientDisplayIdAndStatus)
  .filter("diagnosisType", diagnosisType)
  .filter("displayEnvUrl", displayEnvUrl)
  .filter("filterByNameAndSize", filterByNameAndSize)
  .filter("filterValidDropdownFields", filterValidDropdownFields)
  .filter("humanizedDuration", humanizedDuration)
  .filter("mfShortTime", mfShortTime)
  .filter("onlyActivePatientContracts", onlyActivePatientContracts)
  .filter("startFrom", startFrom)
  .filter("titlecasefromsnake", titlecasefromsnake)
  .filter("uniquePotentialLink", uniquePotentialLink)
  .filter("icdFormat", icdFormat)
  .filter("icdChartFormat", icdChartFormat)
  .filter("toPatient", toPatient)
  .filter("toPatients", toPatients)
  .filter("secondsToTime", secondsToTimeFilter)
  .filter("secondsToLabel", secondsToLabelFilter)
  .filter("replacebr", replacebrFilter)
  .filter("phonenumber", phonenumberFilter)
  .filter("objToArray", objToArrayFilter)
  .filter("americanphone", americanphoneFilter)
  .filter("hiddenphone", hiddenphoneFilter)
  .filter("centsToDollars", centsToDollarsFilter)
  .filter("ssn", ssnFilter)
  .filter("hoursMinutes", hoursMinutesFilter)
  .filter("time", timeFilter)
  .filter("dateRange", dateRangeFilter)
  .filter("titlecase", titlecase)
  .filter("matchItems", matchItemsFilter)
  .filter("dateStringToLocaleDate", dateStringToLocaleDateFilter)
  .filter("hourMinutesRange", hourMinutesRangeFilter)
  .filter("pipeItems", pipeItemsFilter)
  .filter("displayFullName", displayFullNameFilter)
  .filter("secondsToDateTime", secondsToDateTimeFilter)
  .filter("hourMinutesToDateTime", hourMinutesToDateTimeFilter)
  .filter("csvValueWithComma", csvValueWithCommaFilter)
  .filter("filterByNameAndCode", filterByNameAndCodeFilter)
  .filter("join", joinFilter)
  .filter("mfShortDate", mfShortDateFilter)
  .filter("mfShortDayOfWeek", mfShortDayOfWeekFilter)
  .filter("formatPatientContract", formatPatientContractFilter)
  .filter("objectSize", objectSizeFilter)
  .filter("capitalize", capitalize)
  .filter("jsJodaMonth", jsJodaMonth)
  .filter("diffInDays", diffInDays)
  .filter("duration", duration)
  .filter("reverse", reverse)
  .filter("numberRange", numberRange)
  .filter("camelCase", camelCase)
  .filter("fullName", fullName)
  .filter("emptyString", emptyString)
  .filter("nameWeekDays", nameWeekDays)
  .filter("hour", hour)
  .filter("secondsToClock", secondsToClock)
  .filter("minutesToClock", minutesToClock)

  // Directives
  .directive("animateOnChange", animateOnChange)
  .directive("agencyProduct", agencyProduct)
  .directive("countyMap", countyMap)
  .directive("globalSearch", globalSearch)
  .directive("sideNavigationItem", sideNavigationItem)
  .directive("pageTitle", pageTitle)
  .directive("caregiverPatientSearch", caregiverPatientSearch)
  .directive("sideNavigation", sideNavigation)
  .directive("iboxTools", iboxTools)
  .directive("minimalizaSidebar", minimalizaSidebar)
  .directive("iboxToolsFullScreen", iboxToolsFullScreen)
  .directive("fileModel", fileModel)
  .directive("glue", glue)
  .directive("percentage", percentage)
  .directive("onErrorSrc", onErrorSrc)
  .directive("focusMe", focusMe)
  .directive("ssn", ssn)
  .directive("uppercase", uppercase)
  .directive("includeReplace", includeReplace)
  .directive("checkedButton", checkedButton)
  .directive("styledLabel", styledLabel)
  .directive("styledBadge", styledBadge)
  .directive("fileUrlField", fileUrlField)
  .directive("introVideo", introVideo)
  .directive("callsMissedTable", callsMissedTable)
  .directive("clockLocationBound", clockLocationBound)
  .directive("viewPermission", viewPermission)
  .directive("viewPermissionSome", viewPermissionSome)
  .directive("roundedCheckbox", roundedCheckbox)
  .directive("mfActiveOnScroll", mfActiveOnScroll)
  .directive("mfPreventNewLine", mfPreventNewLine)
  .directive("mfTextareaAutoHeight", mfTextareaAutoHeight)
  .directive("autocompleteMultiple", autocompleteMultiple)
  .directive("caregiverPayRates", caregiverPayRates)
  .directive("caregiverPayrollTax", caregiverPayrollTax)
  .directive("clickAnywhereButHere", clickAnywhereButHere)
  .directive("customProHeader", customProHeader)
  .directive("dateFromToPickers", dateFromToPickers)
  .directive("dateRangePicker", dateRangePicker)
  .directive("visitTimeSpan", visitTimeSpan)
  .directive("filterFieldValue", filterFieldValue)
  .directive("labelWithOptions", labelWithOptions)
  .directive("ngEnter", ngEnter)
  .directive("paginationPanel", paginationPanel)
  .directive("permissionsManagement", permissionsManagement)
  .directive("profileCollapsibleSection", profileCollapsibleSection)
  .directive("spinner", spinner)
  .directive("historyTimeline", historyTimeline)
  .directive("newVisitTimeSpan", newVisitTimeSpan)
  .directive("pageTabs", pageTabs)
  .directive("patientPayRates", patientPayRates)
  .directive("pngTimeInput", pngTimeInput)
  .directive("selectAutocomplete", selectAutocomplete)
  .directive("billingVisitsTable", billingVisitsTable)
  .directive("billingAdministrativePaymentsTable", billingAdministrativePaymentsTable)
  .directive("importedVisitsTable", importedVisitsTable)
  .directive("billingVisitsToExportTable", billingVisitsToExportTable)
  .directive("billingInvoicesToExportTable", billingInvoicesToExportTable)
  .directive("validateDateInput", validateDateInput)
  .directive("visitAdjustmentApprovalTable", visitAdjustmentApprovalTable)

  // Components
  .component("mfTimeInput", mfTimeInput)
  .component("newVisitChooseDates", newVisitChooseDates)
  .component("flexibleDaysChooseDates", flexibleDaysChooseDates)
  .component("flexibleHoursChooseDates", flexibleHoursChooseDates)
  .component("flexibleDaysAndHoursChooseDates", flexibleDaysAndHoursChooseDates)
  .component("newVisitFooter", newVisitFooter)
  .component("visitSectionToggleEdit", visitSectionToggleEdit)
  .component("missedVisitSideBar", MissedVisitSideBar)
  .component("patientDocumentDropdownButton", PatientDocumentDropdownButton)
  .component("mfNumberRange", mfNumberRange)
  .component("caregiverAssignedTasks", CaregiverAssignedTasks)
  .component("caregiverVisits", caregiverVisits)
  .component("entityChatsListComponent", entityChatsListComponent)
  .component("entityCustomFieldComponent", entityCustomFieldComponent)
  .component("entityCustomFieldValueComponent", entityCustomFieldValueComponent)
  .component("entityDeleteVisitComponent", entityDeleteVisitComponent)
  .component("entityRemoveVisitComponent", entityRemoveVisitComponent)
  .component("entityNewVisitComponent", entityNewVisitComponent)
  .component("entitySideBarComponent", entitySideBarComponent)
  .component("entityNotesComponent", entityNotesComponent)
  .component("patientDutySheet", patientDutySheetComponent)
  .component("patientMedicalSummary", patientMedicalSummary)
  .component("patientTasks", patientTasks)
  .component("patientVisits", patientVisits)
  .component("ptoApprovalNotesComponent", ptoApprovalNotesComponent)
  .component("reportToolContent", reportToolContent)
  .component("reportToolTablesAndColumns", reportToolTablesAndColumns)
  .component("settingsAgencyLogoUpload", settingsAgencyLogoUpload)
  .component("teamMemberTable", teamMemberTable)
  .component("visitsListView", visitsListView)
  .component("agencyTeamsTable", agencyTeamsTable)
  .component("agencyUserPermissionsRoles", agencyUserPermissionsRoles)
  .component("calendarWeekAggregationColumn", calendarWeekAggregationColumn)
  .component("caregiverCalendar", caregiverCalendar)
  .component("masterWeek", masterWeek)
  .component("caregiverAttendance", caregiverAttendance)
  .component("caregiverPayroll", caregiverPayroll)
  .component("caregiverTrainingCenter", caregiverTrainingCenter)
  .component("caregiverTrainingCenterBundle", caregiverTrainingCenterBundle)
  .component("caregiverTrainingCenterDueDate", caregiverTrainingCenterDueDate)
  .component(
    "caregiverTrainingCenterInfoBundleDueDateHeader",
    caregiverTrainingCenterInfoBundleDueDateHeader
  )
  .component(
    "caregiversTrainingCenterMaterialStatsTable",
    caregiversTrainingCenterMaterialStatsTable
  )
  .component("caregiversTrainingCenterOverviewBundles", caregiversTrainingCenterOverviewBundles)
  .component("caregiversTrainingCenterOverviewTable", caregiversTrainingCenterOverviewTable)
  .component(
    "caregiversTrainingCenterOverviewTotalStatistics",
    caregiversTrainingCenterOverviewTotalStatistics
  )
  .component("newVisitCaregiverSearch", newVisitCaregiverSearch)
  .component("noteCommentsComponent", noteCommentsComponent)
  .component("nursingQuestion", nursingQuestion)
  .component("datePickerWithSideButtonsComponent", datePickerWithSideButtonsComponent)
  .component("entityName", entityName)
  .component("entityNote", entityNote)
  .component("entityNotes", entityNotes)
  .component("entityNoteFiles", entityNoteFiles)
  .component("entityNoteFilesTable", entityNoteFilesTable)
  .component("entityNoteSettingPredefinedAnswers", entityNoteSettingPredefinedAnswers)
  .component("historyTimelineChange", historyTimelineChange)
  .component("mfCalendar", mfCalendar)
  .component("newVersion", newVersion)
  .component("patientCalendar", patientCalendar)
  .component("patientCaregiverRelationships", patientCaregiverRelationships)
  .component("patientDiagnosisCodes", patientDiagnosisCodes)
  .component("patientMedicationProfile", patientMedicationProfile)
  .component("patientPhysicians", patientPhysicians)
  .component("patientWeeklyTemplate", patientWeeklyTemplate)
  .component("patientWeeklyTemplateTable", patientWeeklyTemplateTable)
  .component("personList", personList)
  .component("patientSurplusList",patientSurplusList)
  .component("patientSurplusChecksList",patientSurplusChecksList)
  .component("patientSurplusInvoiceList",patientSurplusInvoiceList)
  .component("physicians", physicians)
  .component("plyrVideo", plyrVideo)
  .component("segmentsProgress", segmentsProgress)
  .component("stateOfHomeCareSurvey", stateOfHomeCareSurvey)
  .component("contractAndPayment", contractAndPayment)
  .component("dateTimeDuration", DateTimeDuration)
  .component("eligibilityChecksPage", EligibilityChecksPage)
  .component("patientPlansOfCare", patientPlansOfCareComponent)
  .component("patientPlanOfCare", patientPlanOfCareComponent)
  .component("patientTaskScheduleDateTime", patientTaskScheduleDateTime)
  .component("certificationPeriodSelector", CertificationPeriodSelector)
  .component("patientTaskModalAssignedType", PatientTaskModalAssignedType)
  .component("releaseNotesAddOn", ReleaseNotesAddOn)
  .component("loginComponent", loginComponent)
  .component("onboardingComponent", onboardingComponent)
  .component("patientBilling", patientBilling)
  .component("profileFaxesTable", profileFaxesTable)
  .run(initialRun)
  .run(angularToReact)
  .name;

/**
 * The reason why I'm not inlining this function in this file is due to typescript not being
 * able to process such a huge file (getting RangeError: Maximum call stack size exceeded).
 * I hope this will be fixed in the future.
 */
registerAppModuleControllers();
registerComplianceModule();
registerCaregiverModule();
registerSharedModule();
registerPlanOfCareModule();
registerVisitBroadcastModule();
registerFaxesModule();
registerReactAdapterModule();
registerPatientBpModule(); 
registerHoursOverrideModule();