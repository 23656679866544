import { createIcon } from "@chakra-ui/react";

const VoicemailIcon = createIcon({
  displayName: "VoicemailIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M6.5 16q-1.875 0-3.187-1.312T2 11.5t1.313-3.187T6.5 7t3.188 1.313T11 11.5q0 .675-.2 1.3t-.55 1.2h3.5q-.35-.575-.55-1.2t-.2-1.3q0-1.875 1.313-3.187T17.5 7t3.188 1.313T22 11.5t-1.312 3.188T17.5 16zm0-2q1.05 0 1.775-.725T9 11.5t-.725-1.775T6.5 9t-1.775.725T4 11.5t.725 1.775T6.5 14m11 0q1.05 0 1.775-.725T20 11.5t-.725-1.775T17.5 9t-1.775.725T15 11.5t.725 1.775T17.5 14"
      fill="currentColor"
    />
  ),
});

export default VoicemailIcon;
