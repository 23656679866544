import { Messages } from "../../../core/api";
import useInboundCallEntitySelectionModal from "../../communication/hooks/useInboundCallEntitySelectionModal";
import CallEntitySelectionModal from "./CallEntitySelectionModal";

export default function ActiveInboundCallWrapper({
  call,
}: {
  call: Messages["TelephonyInboundCallInfo"];
}) {
  const callEntitySelectionModal = useInboundCallEntitySelectionModal({ callId: call.id });

  return (
    <CallEntitySelectionModal
      callId={call.id}
      currentCallerEntity={call.caller.entity}
      entities={callEntitySelectionModal.callEntityOptions}
      isOpen={callEntitySelectionModal.isOpen}
      onClose={callEntitySelectionModal.onClose}
    />
  );
}
