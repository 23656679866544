import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../../../../shared/hooks/useApi";
import { PatientId, TelephonyCallId } from "../../../../shared/schema/schema";
import useCallCenter from "../../../call-center/hooks/useCallCenter";
import {
  ListedParallelCallItem,
  OngoingParallelCallItem,
  PendingParallelCallItem,
} from "./parallel-calls.types";
import {
  hasParallelCallStatus,
  isParallelCallInGroup,
  MAX_CONCURRENT_PARALLEL_CALLS,
} from "./parallel-calls.utils";
import useParallelCallsDial from "./useParallelCallsDial";
import { useRouter } from "@uirouter/react";

export default function useParallelCalls() {
  const { api, queries } = useApi();
  const queryClient = useQueryClient();
  const query = useQuery(queries.telephony.parallel.queue);
  const callCenter = useCallCenter();
  const { stateService } = useRouter();

  const setChecked = (patientId: PatientId, isChecked: boolean) => {
    queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
      return prev?.map((item) => {
        return item.contact.id === patientId
          ? {
              status: isChecked ? ("Checked" as const) : ("Unchecked" as const),
              contact: item.contact,
            }
          : item;
      });
    });
  };

  const checkAll = () => {
    queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
      return prev.map((item) => {
        return item.status === "Unchecked"
          ? { status: "Checked" as const, contact: item.contact }
          : item;
      });
    });
  };

  const uncheckAll = () => {
    queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
      return prev.map((item) => {
        return item.status === "Checked"
          ? { status: "Unchecked" as const, contact: item.contact }
          : item;
      });
    });
  };

  const getHasUnchecked = () => {
    return (query.data ?? []).some(({ status }) => status === "Unchecked");
  };

  const getCheckedCallsCount = () => {
    return (query.data ?? []).filter((x) => hasParallelCallStatus(x, "Checked")).length;
  };

  const getListedCalls = (): ListedParallelCallItem[] | undefined => {
    return (query.data ?? [])
      .filter((item) => isParallelCallInGroup(item, "Listed"))
      .sort((a, b) => {
        if (a.status === "Checked" && b.status === "Unchecked") {
          return -1;
        }

        if (a.status === "Unchecked" && b.status === "Checked") {
          return 1;
        }

        return 0;
      });
  };

  const getPendingCalls = () => {
    return (query.data ?? []).filter(
      (call): call is PendingParallelCallItem => call.status === "Pending"
    );
  };

  const getDispatchedCalls = () => {
    return (query.data ?? [])
      .filter((x) => isParallelCallInGroup(x, "Enqueued") || isParallelCallInGroup(x, "Ongoing"))
      .sort((a, b) => b.queuedAt.compareTo(a.queuedAt));
  };

  const getEndedCalls = () => {
    return (query.data ?? [])
      .filter((call) => isParallelCallInGroup(call, "Ended"))
      .sort((a, b) => b.endedAt.compareTo(a.endedAt))
      .slice(0, 5);
  };

  const dial = useParallelCallsDial();

  const playQueue = () => {
    const now = Instant.now();

    queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev = []) => {
      const pending = prev.filter((x) => isParallelCallInGroup(x, "Pending"));
      const enqueued = prev.filter((x) => isParallelCallInGroup(x, "Enqueued"));
      const ongoing = prev.filter((x) => isParallelCallInGroup(x, "Ongoing"));

      for (const item of prev.filter((x) => hasParallelCallStatus(x, "Checked"))) {
        switch (true) {
          case ongoing.find((x) => !x.contact.hasVoiceMailTemplate) !== undefined:
          case enqueued.find((x) => !x.contact.hasVoiceMailTemplate) !== undefined:
          case enqueued.length + ongoing.length > 0 && !item.contact.hasVoiceMailTemplate:
          case enqueued.length + ongoing.length >= MAX_CONCURRENT_PARALLEL_CALLS:
            pending.push({
              status: "Pending",
              contact: item.contact,
              requestCallId: crypto.randomUUID(),
            });
            break;
          default:
            enqueued.push({
              status: "InQueue",
              contact: item.contact,
              requestCallId: crypto.randomUUID(),
              queuedAt: now,
            });
        }
      }

      if (enqueued.length > 0) {
        dial.mutate({
          calls: enqueued
            .filter((x) => x.queuedAt === now)
            .map((item) => ({ source: item.contact.source, requestCallId: item.requestCallId })),
        });
      }

      return prev.map(
        (item) =>
          enqueued.find((x) => x.contact === item.contact) ??
          pending.find((x) => x.contact === item.contact) ??
          item
      );
    });
  };

  const { mutate: markAsVoiceMailMutation } = useMutation({
    mutationFn: async (params: { callId: TelephonyCallId }) => {
      return api.post("./telephony/calls/:callId/mark_as_voicemail", {
        path: { callId: params.callId },
      });
    },
  });

  const classifyCall = (item: OngoingParallelCallItem, as: "VoiceMail" | "Human") => {
    switch (as) {
      case "VoiceMail": {
        callCenter.hangup();

        markAsVoiceMailMutation({ callId: item.call.id });

        queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
          return prev?.map((prevItem) => {
            return item === prevItem
              ? { ...item, status: "VoiceMail" as const, endedAt: Instant.now() }
              : prevItem;
          });
        });

        break;
      }

      case "Human": {
        callCenter.openCallTicketPopup(item.call);
        queryClient.setQueryData(queries.telephony.parallel.queue.queryKey, (prev) => {
          return prev?.map((prevItem) => {
            return item === prevItem ? { ...item, status: "Human" as const } : prevItem;
          });
        });

        if (item.call.callee.entity.type === "Patient" && item.call.status === "InProgress") {
          stateService.go("app.patients.intake-flow", {
            patientId: item.call.callee.entity.id,
          });
        }
      }
    }
  };

  return {
    query: query,
    getHasUnchecked: getHasUnchecked,
    setChecked: setChecked,
    checkAll: checkAll,
    uncheckAll: uncheckAll,
    getCheckedCallsCount: getCheckedCallsCount,
    getListedCalls: getListedCalls,
    getPendingCalls: getPendingCalls,
    getDispatchedCalls: getDispatchedCalls,
    getEndedCalls: getEndedCalls,
    classifyCall: classifyCall,
    playQueue: playQueue,
  };
}
