import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useRouter } from "@uirouter/react";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import React from "react";
import { z } from "zod";
import useApi from "../../../../shared/hooks/useApi";
import usePathParams from "../../../../shared/hooks/usePathParams";
import { PatientId } from "../../../../shared/schema/schema";
import { zPathParamId } from "../../../../shared/utils/zod";
import usePhoneProvider from "../../../call-center/hooks/usePhoneProvider";
import IntakePatientProfileRoute from "../../components/IntakePatientProfileRoute";
import ListedParallelCalls from "./components/ListedParallelCalls";
import ParallelCallBox from "./components/ParallelCallBox";
import PendingParallelCallsListActions from "./components/PendingParallelCallsListActions";
import useParallelCalls from "./useParallelCalls";
import useParallelCallsListeners from "./useParallelCallsListeners";
import useSocketSubscription from "./useSocketSubscription";

const zPathParams = z.object({
  intakePatientId: zPathParamId(PatientId).optional(),
});

export default function ParallelCallsV2Route() {
  const phoneProvider = usePhoneProvider();
  const pathParams = usePathParams(zPathParams);
  const { stateService } = useRouter();

  const login = useMutation({
    mutationFn: phoneProvider.login,
  });

  if (login.isPending) {
    return (
      <Center h="65vh">
        <Spinner />
      </Center>
    );
  }

  if (phoneProvider.isActiveOnAnotherTab) {
    return (
      <Center h="65vh">
        <Flex direction="column" gap={6} textAlign="center" w="lg">
          <Heading size="md">Active in another tab</Heading>
          <VStack>
            <Text>
              You&apos;re already logged into the phone system in another tab. Would you like to log
              out from the other tab and log in here?
            </Text>
            <Text fontWeight="semibold">
              If you&apos;re in an ongoing call in another tab, it will get disconnected
            </Text>
          </VStack>
          <Flex justify="center">
            <Button colorScheme="red" onClick={() => login.mutate()}>
              Switch to this tab
            </Button>
          </Flex>
        </Flex>
      </Center>
    );
  }

  if (!phoneProvider.isActiveOnCurrentTab) {
    return (
      <Center h="65vh">
        <Button colorScheme="blue" onClick={() => login.mutate()}>
          Login to dialer
        </Button>
      </Center>
    );
  }

  return (
    <>
      <ParallelCallsSection />
      <IntakePatientProfileRoute
        patientId={pathParams.intakePatientId}
        onClose={() => stateService.go(".", { intakePatientId: null })}
      />
    </>
  );
}

function ParallelCallsSection() {
  const calls = useParallelCalls();
  const queryClient = useQueryClient();
  const { queries } = useApi();

  React.useEffect(() => {
    queryClient.resetQueries(queries.telephony.parallel.queue);
  }, [queries.telephony.parallel.queue, queryClient]);

  useSocketSubscription({ channel: "telephony-parallel-calls" });

  useParallelCallsListeners();

  return (
    <Flex h="full">
      <Flex
        borderRightColor="gray.200"
        borderRightWidth={1}
        direction="column"
        flexShrink={0}
        w="sm"
      >
        <PendingParallelCallsListActions />
        <Box h="65vh" overflow="auto">
          <ListedParallelCalls />
        </Box>
      </Flex>
      <Flex direction="column" w="full">
        <LayoutGroup>
          {[
            { kind: "Active" as const, calls: calls.getDispatchedCalls() },
            { kind: "Pending" as const, calls: calls.getPendingCalls() },
            { kind: "Previous" as const, calls: calls.getEndedCalls() },
          ]
            .filter((x) => (x.calls?.length ?? 0) > 0)
            .map((callsGroup, i) => (
              <React.Fragment key={callsGroup.kind}>
                {i > 0 && <Divider />}

                <Text color="gray.800" fontSize="sm" fontWeight="semibold" pt={4} px={4}>
                  {callsGroup.kind} calls
                </Text>

                <Flex direction="column" gap={4} p={4}>
                  <AnimatePresence>
                    {callsGroup.calls?.map((item) => (
                      <React.Suspense key={item.requestCallId} fallback="...">
                        <ParallelCallBox item={item} onClassify={calls.classifyCall} />
                      </React.Suspense>
                    ))}
                  </AnimatePresence>
                </Flex>
              </React.Fragment>
            ))}
        </LayoutGroup>
      </Flex>
    </Flex>
  );
}
