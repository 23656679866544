import { Instant } from "@js-joda/core";
import { useAtom, useAtomValue } from "jotai";
import { atomWithReducer } from "jotai/utils";
import { Messages } from "../../../core/api";
import { CommCenterTicketId, PatientId, TelephonyCallId } from "../../../shared/schema/schema";
import { isIntakeTeam, isParallelCall, isPortalCall } from "../call-center.utils";

export type CallState = {
  callSummaryTicketId: CommCenterTicketId | null;
  afterCallCallTypeData: { callId: TelephonyCallId; patientId: PatientId } | null;
  isMuted: boolean;
  parallelCallSource: boolean;
} & (
  | {
      status: "Loading";
      currentCall: null;
      lastCall: Messages["TelephonyCallInfo"] | null;
    }
  | {
      status: "Unauthorized";
      currentCall: null;
      lastCall: null;
    }
  | {
      status: "Idle";
      currentCall: null;
      lastCall: Messages["TelephonyCallInfo"] | null;
    }
  | {
      status: "Ringing";
      currentCall:
        | Messages["TelephonyInboundCallInfo"]
        | Messages["TelephonyPortalRequestCallInfo"];
      lastCall: Messages["TelephonyCallInfo"] | null;
    }
  | {
      status: "Dialing";
      currentCall: null;
      lastCall: Messages["TelephonyCallInfo"] | null;
    }
  | {
      status: "Calling";
      currentCall: Messages["TelephonyOutboundCallInfo"];
      lastCall: Messages["TelephonyCallInfo"] | null;
    }
  | {
      status: "Active";
      currentCall: Messages["TelephonyCallInfo"];
      lastCall: Messages["TelephonyCallInfo"] | null;
      startedAt: Instant;
    }
);

type CallAction =
  | { type: "Ready" }
  | { type: "Unauthorize" }
  | {
      type: "Ring";
      payload: Messages["TelephonyInboundCallInfo"] | Messages["TelephonyPortalRequestCallInfo"];
    }
  | {
      type: "Dial";
      destination: string;
    }
  | {
      type: "Answer";
      payload: Messages["TelephonyCallInfo"];
    }
  | {
      type: "BargeIn";
      payload: Messages["TelephonyInboundCallInfo"];
    }
  | {
      type: "ConnectOutbound";
      payload: Messages["TelephonyOutboundCallInfo"];
    }
  | {
      type: "ConnectParallel";
      payload: Messages["TelephonyParallelCallInfo"];
    }
  | {
      type: "ConnectTransferedCall";
      payload: Messages["TelephonyOutboundCallInfo"];
    }
  | {
      type: "Hangup";
    }
  | {
      type: "RemovedFromCall";
    }
  | {
      type: "Decline";
    }
  | {
      type: "Cancel";
    }
  | {
      type: "OpenSummary";
      ticketId: CommCenterTicketId;
    }
  | {
      type: "SetAfterCallCallTypeData";
      payload: { callId: TelephonyCallId; patientId: PatientId } | null;
    }
  | {
      type: "CloseSummary";
    }
  | {
      type: "ToggleMute";
    }
  | {
      type: "Mute";
    }
  | {
      type: "Unmute";
    }
  | {
      type: "Transferred";
    }
  | {
      type: "UpdateActiveCall";
      payload: Messages["TelephonyCallInfo"];
    };

const callReducer = (prev: CallState, action: CallAction): CallState => {
  console.log("[call-state]", `action: ${action.type}`, { action, prev });

  if (action.type === "Ready") {
    return {
      status: "Idle",
      currentCall: null,
      lastCall: null,
      afterCallCallTypeData: null,
      callSummaryTicketId: null,
      isMuted: false,
      parallelCallSource: false,
    };
  }

  if (action.type === "Unauthorize") {
    return {
      status: "Unauthorized",
      currentCall: null,
      lastCall: null,
      afterCallCallTypeData: null,
      callSummaryTicketId: null,
      isMuted: false,
      parallelCallSource: false,
    };
  }

  switch (action.type) {
    case "Ring":
      return {
        ...prev,
        status: "Ringing",
        currentCall: action.payload,
      };
    case "Dial":
      return {
        ...prev,
        status: "Dialing",
        currentCall: null,
      };
    case "ConnectOutbound":
      return {
        ...prev,
        status: "Active",
        currentCall: action.payload,
        startedAt: Instant.now(),
      };
    case "ConnectParallel":
      return {
        ...prev,
        status: "Active",
        currentCall: action.payload,
        startedAt: Instant.now(),
      };
    case "ConnectTransferedCall": {
      return {
        ...prev,
        status: "Active",
        parallelCallSource: false,
        currentCall: action.payload,
        startedAt: Instant.now(),
      };
    }
    case "Answer":
      return {
        ...prev,
        status: "Active",
        currentCall: action.payload,
        startedAt: Instant.now(),
      };
    case "BargeIn":
      return {
        ...prev,
        status: "Active",
        currentCall: action.payload,
        startedAt: Instant.now(),
      };
    case "Transferred":
      return {
        ...prev,
      };
    case "Hangup":
      return {
        ...prev,
        status: "Idle",
        currentCall: null,
        isMuted: false,
        lastCall:
          prev.currentCall === null || isPortalCall(prev.currentCall) ? null : prev.currentCall,
        callSummaryTicketId: getCallSummaryTicketId(prev.currentCall),
      };
    case "RemovedFromCall":
      return {
        ...prev,
        status: "Idle",
        currentCall: null,
        isMuted: false,
        lastCall: prev.currentCall?.direction === "Inbound" ? prev.currentCall : null,
        callSummaryTicketId: null,
      };
    case "Cancel":
      return {
        ...prev,
        status: "Idle",
        currentCall: null,
      };
    case "Decline":
      return {
        ...prev,
        status: "Idle",
        currentCall: null,
      };
    case "OpenSummary":
      return {
        ...prev,
        callSummaryTicketId: action.ticketId,
      };
    case "SetAfterCallCallTypeData":
      return {
        ...prev,
        afterCallCallTypeData: action.payload,
      };
    case "CloseSummary":
      return {
        ...prev,
        callSummaryTicketId: null,
      };
    case "ToggleMute":
      return {
        ...prev,
        isMuted: !prev.isMuted,
      };
    case "Mute":
      return {
        ...prev,
        isMuted: true,
      };
    case "Unmute":
      return {
        ...prev,
        isMuted: false,
      };
    case "UpdateActiveCall": {
      if (prev.currentCall === null || prev.status !== "Active") {
        return prev;
      }

      return {
        ...prev,
        currentCall: action.payload,
      };
    }
  }
};

const callAtom = atomWithReducer(
  {
    status: "Loading",
    currentCall: null,
    lastCall: null,
    callSummaryTicketId: null,
    isMuted: false,
    afterCallCallTypeData: null,
    parallelCallSource: false,
  },
  callReducer
);

export default function useCallState() {
  return useAtom(callAtom);
}

export function useCallStateValue() {
  return useAtomValue(callAtom);
}

function getCallSummaryTicketId(
  call: Messages["TelephonyCallInfo"] | Messages["TelephonyPortalRequestCallInfo"] | null
) {
  if (
    call === null ||
    isPortalCall(call) ||
    isParallelCall(call) ||
    !isIntakeTeam(call.ticket.teamName)
  ) {
    return null;
  }

  return call.ticket.id;
}
