import { Button, useToast } from "@chakra-ui/react";
import { PatientId } from "../../../../shared/schema/schema";
import useApi from "../../../../shared/hooks/useApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../../../../shared/query-keys";
import { formatErrorResponse } from "../../../../shared/utils/format-response-error";

const CheckEligibilityButton = (props: { patientId: PatientId }) => {
  const { api, queries } = useApi();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: runEligibilityCheck } = useMutation({
    mutationKey: ["run-eligibility-check"],
    mutationFn: () =>
      api.post("./patients/:patientId/check_eligibility", {
        path: {
          patientId: props.patientId,
        },
      }),
    onSuccess: (_response) => {
      toast({
        title: "Successfuly run eligibility check",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.patientIntake.eligibilityCheck(props.patientId),
      });
      queryClient.invalidateQueries(queries.intake.history(props.patientId));
    },
    onError: (error) => {
      toast({
        title: `Error running eligibility check`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  return (
    <Button colorScheme="blue" marginRight="15px" onClick={() => runEligibilityCheck()}>
      {" "}
      Check Eligibility{" "}
    </Button>
  );
};

export default CheckEligibilityButton;
