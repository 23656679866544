import { Box } from "@chakra-ui/react";
import { Messages } from "../../../../../core/api";

type Props = {
  contact: Messages["TelephonyParallelCallContactInfo"];
};

export default function ParallelCallMachineDetectionStatus(props: Props) {
  return (
    <Box
      bg={props.contact.hasVoiceMailTemplate ? "green.50" : undefined}
      borderColor={props.contact.hasVoiceMailTemplate ? "green.300" : "gray.300"}
      borderWidth={1}
      color={props.contact.hasVoiceMailTemplate ? "green.600" : "gray.600"}
      fontSize="xs"
      fontWeight="semibold"
      px={3}
      py={1.5}
      rounded="full"
    >
      {props.contact.hasVoiceMailTemplate ? "Machine Detection ON" : "Machine Detection OFF"}
    </Box>
  );
}
