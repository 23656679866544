import {
  Flex,
  Heading,
  Text,
  Accordion,
  Spinner,
  AccordionItem,
  AccordionButton,
} from "@chakra-ui/react";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { PatientId } from "../../../../shared/schema/schema";
import useApi from "../../../../shared/hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { Instant, LocalDate } from "@js-joda/core";
import { mapRawHistoryRowToHistoryChangeLogRow } from "../../../../shared/utils/intake-change-history";
import { ChangeLogRow } from "./IntakeHistoryChangeLogRow";

const HISTORY_FEATURE_RELEASED_AT = LocalDate.of(2024, 9, 1);

interface Props {
  patientId: PatientId;
  patientCreatedAt: Instant;
}

export default function PatientIntakeHistory(props: Props) {
  const { queries } = useApi();

  const history = useQuery({
    ...queries.intake.history(props.patientId),
    select: (response) => {
      return response.history
        .filter((historyRow) => historyRow.changedField.title !== "addressComponents")
        .map((historyRow) => mapRawHistoryRowToHistoryChangeLogRow(historyRow));
    },
  });

  return (
    <Flex direction="column" gap={6}>
      <Flex justifyContent="space-between">
        <Heading as="h2" size="md">
          Intake Changes Log
        </Heading>
      </Flex>
      {(() => {
        switch (true) {
          case history.status === "pending":
            return <ChangeLogLoading />;
          case history.status === "error":
            return <ChangeLogError />;
          case history.status === "success" && history.data.length === 0:
            return <NoChangedFound />;
          case history.status === "success":
            return (
              <Accordion
                allowToggle
                maxH="600px"
                overflow="auto"
                sx={{
                  "::-webkit-scrollbar": { display: "none" },
                }}
              >
                {history.data.map((historyRow, index) => {
                  return <ChangeLogRow key={`change-log-row-${index}`} historyRow={historyRow} />;
                })}
                <EndOfScrollElement />
              </Accordion>
            );
          default:
            return <ChangeLogError />;
        }
      })()}
    </Flex>
  );
}

const NoChangedFound = () => {
  return <Text color="gray.500">No intake changes found for this patient.</Text>;
};

const ChangeLogLoading = () => {
  return (
    <Flex color="gray.500" flex={1} flexDirection="column" gap={8}>
      <Spinner size="sm" />
    </Flex>
  );
};

const ChangeLogError = () => {
  return (
    <div> Sorry.. Something went wrong while trying to fetch intke changes for this patient.</div>
  );
};

const EndOfScrollElement = () => {
  return (
    <AccordionItem>
      <AccordionButton
        alignItems="center"
        flexDirection="row"
        justifyContent="center"
        textAlign="center"
        textColor="gray.500"
      >
        {`No further changes to show. Started to log on ${dateFormatter.toDate(
          HISTORY_FEATURE_RELEASED_AT
        )}`}
      </AccordionButton>
    </AccordionItem>
  );
};
