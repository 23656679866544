import { Messages } from "../../core/api";
import { createMutationGroup } from "../../core/api/mutations";
import { TelephonyCallId } from "../../shared/schema/schema";

export default createMutationGroup(({ api, createMutation }) => ({
  lockFreeMsisdForParallelCall: () =>
    createMutation({
      mutationFn: (msisd: string | null) =>
        api.post("./parallel_calls/free_to_call_msisd", {
          body: {
            msisd,
          },
        }),
    }),
  updateInboundCallCallerParticipantEntity: (callId: TelephonyCallId) =>
    createMutation({
      mutationFn: (entity: Messages["TelephonyCallParticipantEntity"]) =>
        api.patch("./telephony/calls/:callId/update_caller_entity", {
          path: { callId },
          body: { entity: entity },
        }),
    }),
}));
