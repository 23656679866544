import { Box, Skeleton } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Messages } from "../../../../core/api";
import ErrorPage from "../../../../shared/components/ErrorPage";
import useApi from "../../../../shared/hooks/useApi";
import CompletedCallTicket from "../CallTicket/CompletedCallTicket";
import LiveCallTicket from "../CallTicket/LiveCallTicket";
import { CommCenterCallTicket } from "../CallTicket/helpers/call-ticket-utils";

interface Props {
  ticket: CommCenterCallTicket;
}

export default function TelephonyTicket(props: Props) {
  const { queries } = useApi();

  const query = useQuery(queries.telephony.call(props.ticket.callInfo.id));

  if (query.isPending) {
    return (
      <Box m={2}>
        <Skeleton height={72} width="50%" />
      </Box>
    );
  }

  if (query.isError) {
    return <ErrorPage error={query.error} resetErrorBoundary={query.refetch} />;
  }

  const { call } = query.data;

  if (call.isLive) {
    return <LiveCallTicket activeTicket={props.ticket} callInfo={call} />;
  }

  return (
    <CompletedCallTicket
      activeTicket={props.ticket}
      callId={call.id}
      createdAt={call.createdAt}
      direction={call.direction}
      durationSeconds={call.durationSeconds ?? 0}
      externalPhoneNumber={getTelephonyTicketExternalPhoneNumber(call)}
      failDescription={call.failDescription}
      hasRecording={call.hasRecording ?? false}
      notes={call.notes}
      participantEvents={call.participantEvents}
      participants={call.participants}
      status={call.status}
    />
  );
}

function getTelephonyTicketExternalPhoneNumber(callInfo: Messages["TelephonyCallInfo"]) {
  switch (callInfo.direction) {
    case "Inbound":
      return callInfo.caller.source;
    case "Outbound":
    case "Parallel":
      return callInfo.callee.source;
  }
}
