import { createIcon } from "@chakra-ui/react";

const PlaylistRemoveIcon = createIcon({
  displayName: "PlaylistRemoveIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M14.4 22L13 20.6l2.6-2.6l-2.6-2.6l1.4-1.4l2.6 2.6l2.6-2.6l1.4 1.4l-2.6 2.6l2.6 2.6l-1.4 1.4l-2.6-2.6zM3 16v-2h7v2zm0-4v-2h11v2zm0-4V6h11v2z"
      fill="currentColor"
    />
  ),
});

export default PlaylistRemoveIcon;
