import * as React from "react";

interface LocalStorageEmitter {
  subscribe(key: string, callback: () => void): () => void;
  emit(key: string): void;
}

const localStorageEmitter: LocalStorageEmitter = (() => {
  const listeners: Record<string, (() => void)[]> = {};

  return {
    subscribe(key: string, callback: () => void): () => void {
      if (!listeners[key]) {
        listeners[key] = [];
      }
      listeners[key].push(callback);
      return () => {
        listeners[key] = listeners[key].filter((cb) => cb !== callback);
      };
    },
    emit(key: string): void {
      const callbacks = listeners[key] || [];
      callbacks.forEach((callback) => callback());
    },
  };
})();

function createStorageSubscription(key: string, onChange: () => void) {
  const handleStorageChange = (event: StorageEvent) => {
    if (event.key === key) {
      onChange();
    }
  };

  window.addEventListener("storage", handleStorageChange);
  const unsubscribeCustom = localStorageEmitter.subscribe(key, onChange);

  return () => {
    window.removeEventListener("storage", handleStorageChange);
    unsubscribeCustom();
  };
}

function readFromStorage<T>(key: string, defaultValue: T): T {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : defaultValue;
}

function writeToStorage<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
  localStorageEmitter.emit(key); // Emit custom event for same-tab updates
  console.debug("[useLocalStorage] writeToStorage - after", {
    key,
    value: JSON.stringify(value),
    snapshot: localStorage.getItem(key),
  });
}

function useLocalStorage<T>(key: string, defaultValue: T): [T, (value: T) => void] {
  const getSnapshot = React.useCallback(
    () => readFromStorage(key, defaultValue),
    [key, defaultValue]
  );

  const subscribe = React.useCallback(
    (notify: () => void) => {
      return createStorageSubscription(key, notify);
    },
    [key]
  );

  const state = React.useSyncExternalStore(subscribe, getSnapshot);

  const setState = React.useCallback(
    (value: T) => {
      writeToStorage(key, value);
    },
    [key]
  );

  return [state, setState];
}

export default useLocalStorage;
