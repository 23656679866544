import { Flex, Tooltip } from "@chakra-ui/react";
import RocketLaunchOutlineIcon from "../../../../../shared/icons/RocketLaunchOutlineIcon";
import { Messages } from "../../../../../core/api";

type Props = { contact: Messages["TelephonyParallelCallContactInfo"] };

export default function ParallelCallOrderBadge(props: Props) {
  return (
    <Flex
      {...(() => {
        if (props.contact.callOrder === 1) {
          return { bg: "yellow.200", color: "yellow.800" };
        }

        if (props.contact.callOrder === 2) {
          return { bg: "gray.300", color: "gray.800" };
        }

        if (props.contact.callOrder === 3) {
          return { bg: "orange.200", color: "orange.800" };
        }

        return { bg: "gray.200" };
      })()}
      alignItems="center"
      fontSize="xs"
      fontWeight="semibold"
      gap={1}
      h={5}
      lineHeight={5}
      px={1.5}
      rounded="md"
      textAlign="center"
    >
      <span>#{props.contact.callOrder}</span>
      {props.contact.boostType && (
        <Tooltip label={props.contact.boostType}>
          <RocketLaunchOutlineIcon />
        </Tooltip>
      )}
    </Flex>
  );
}
