function createCurrencyFormatter() {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatter = {
    formatCents: (cents: number) => currencyFormatter.format(cents / 100),
  };

  return formatter;
}

export const currencyFormatter = createCurrencyFormatter();
