import { Instant } from "@js-joda/core";
import { NEW_YORK_TZ } from "../../../../shared/utils/date-utils";
import {
  IntakeHistoryChangeLogRow,
  IntakeHistoryChangeLogRowType,
} from "../../../../shared/utils/intake-change-history";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ChangeLogDetails } from "./IntakeHistoryChangeLogDetails";

export const ChangeLogRow = (props: { historyRow: IntakeHistoryChangeLogRow }) => {
  return (
    <AccordionItem>
      <AccordionButton
        alignItems="center"
        flexDirection="row"
        justifyContent="space-between"
        textAlign="left"
      >
        <Flex alignItems="center" direction="row" gap={10}>
          <ChangeLogTime changeTime={props.historyRow.time} />
          <Box fontWeight={500}>{props.historyRow.changeTitle}</Box>
        </Flex>
        <Box marginRight={6}>
          <ChangeLogBadge changeType={props.historyRow.type} />
        </Box>
      </AccordionButton>
      <AccordionPanel>
        <ChangeLogDetails change={props.historyRow} />
      </AccordionPanel>
    </AccordionItem>
  );
};

const ChangeLogTime = (props: { changeTime: Instant }) => {
  const formattedDateAndTime = dateFormatter.toDateTimeObject(props.changeTime, {
    timezone: NEW_YORK_TZ,
  });
  return (
    <Box>
      <Text color="gray.500" fontWeight="550" textAlign="center">
        {formattedDateAndTime.time}
        <br />
        {formattedDateAndTime.date}
      </Text>
    </Box>
  );
};

const ChangeLogBadge = (props: { changeType: IntakeHistoryChangeLogRowType }) => {
  const getStyleProps = () => {
    switch (props.changeType) {
      case "Added":
        return {
          backgroundColor: "green.200",
          textColor: "green.500",
        };
      case "Removed":
        return {
          backgroundColor: "red.200",
          textColor: "red.500",
        };
      case "Updated":
        return {
          backgroundColor: "blue.200",
          textColor: "blue.500",
        };
      default:
        return {
          backgroundColor: "gray.200",
          textColor: "gray.500",
        };
    }
  };

  const styleProps = getStyleProps();

  return (
    <Button
      _hover={{ ...styleProps }}
      background={styleProps.backgroundColor}
      color={styleProps.textColor}
      fontSize={12}
      height="25px"
      width="65px"
    >
      {props.changeType}
    </Button>
  );
};
