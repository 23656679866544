import { queryOptions } from "@tanstack/react-query";
import { createQueryGroup } from "../../core/api/queries";
import { TelephonyCallId } from "../../shared/schema/schema";

export default createQueryGroup(({ api }) => ({
  getAgencyMemberSubscriber: () =>
    queryOptions({
      queryKey: ["call-center", "subscriber"],
      queryFn: () => api.get("./call_center_subscriber", {}),
      staleTime: Infinity,
    }),
  getPatientsForParallelCalls: () =>
    queryOptions({
      queryKey: ["call-center", "patients-for-parallel-calls"],
      queryFn: () => api.get("./patient_intake/parallel_calls_patients", {}),
    }),
  generateAwsSessionTokenForTranscription: () =>
    queryOptions({
      queryKey: ["call-center", "aws-session-token"],
      queryFn: () => api.post("./parallel_calls/aws_token", {}),
    }),
  inboundCallEntityOptions: (callId: TelephonyCallId) =>
    queryOptions({
      queryKey: ["call-center", "calls", callId, "inbound_call_entity_options"],
      queryFn: async () => {
        const { entityOptions } = await api.get(
          "./telephony/calls/:callId/inbound_call_entity_options",
          { path: { callId } }
        );
        return entityOptions;
      },
    }),
}));
