import { ChatIcon, PhoneIcon } from "@chakra-ui/icons";
import { Flex, Text } from "@chakra-ui/react";
import { Messages } from "../../../core/api";
import PhoneCallStatus from "./PhoneCallStatus";
import SmsTicketStatus from "./SmsTicketStatus";
import EmailTicketStatus from "./Emails/EmailTicketStatus";

interface Props {
  source: Messages["CommCenterTicket"]["source"];
  callInfo: Messages["CommCenterTicket"]["callInfo"];
  emailThread: Messages["CommCenterTicket"]["emailThread"];
  messages: Messages["CommCenterTicket"]["messages"];
  status: Messages["CommCenterTicket"]["status"];
}

const TicketSourceCell = (props: Props) => {
  switch (props.source) {
    case "MANUAL":
    case "MOBILE_CHAT":
    case "SYSTEM_TRIGGER":
      return <ChatIcon />;
    case "PHONE_CALL":
      return (
        <Flex align="center" gap={1}>
          <PhoneIcon color="green.400" />
          {props.callInfo && (
            <Flex alignItems="center" gap={4}>
              <Text fontSize="x-small">
                {formatTelephonyCallDirection(props.callInfo.direction)}
              </Text>
              <PhoneCallStatus
                callStatus={props.callInfo.status}
                failDescription={props.callInfo.failDescription}
                recordingState={{ type: "NoRecording" }}
              />
            </Flex>
          )}
        </Flex>
      );
    case "EMAIL":
      if (props.emailThread === null) {
        return <Text>Bad email ticket</Text>;
      }

      return (
        <EmailTicketStatus
          direction={props.emailThread.direction}
          messages={props.emailThread.messages}
          status={props.status}
        />
      );
    case "SMS_CONVERSATION":
      return <SmsTicketStatus messages={props.messages} status={props.status} />;
  }
};

function formatTelephonyCallDirection(direction: Messages["TelephonyCallDirection"]) {
  switch (direction) {
    case "Inbound":
      return "In";
    case "Outbound":
      return "Out";
    case "Parallel":
      return "Out (Parallel)";
  }
}

export default TicketSourceCell;
