import { Text, useToast } from "@chakra-ui/react";
import useSocketEvent from "../../hooks/useSocketEvent";

export default function SendOutboundSMSResponseListener() {
  const toast = useToast();

  useSocketEvent({
    key: "OutboundSMSFailed",
    validate: true,
    onEvent: ({ messageText, ticketId, error }) => {
      toast({
        position: "top-right",
        status: "error",
        title: `Your SMS from ticket ${ticketId} could not be sent`,
        description: <OutboundSMSFailedDescription error={error} messageText={messageText} />,
        duration: 15000,
        isClosable: true,
      });
    },
  });

  return <></>;
}

const OutboundSMSFailedDescription = ({
  messageText,
  error,
}: {
  messageText: string;
  error: string;
}) => {
  return (
    <>
      <Text>{`Error: ${error}`}</Text>
      <Text>SMS Message:</Text>
      <Text>{messageText}</Text>
    </>
  );
};
