import { createIcon } from "@chakra-ui/react";

const AnimatedLiveAudioIcon = createIcon({
  displayName: "AnimatedLiveAudioIcon",
  viewBox: "0 0 200 200",
  path: (
    <>
      <rect fill="currentColor" height="100" rx="10" ry="10" width="20" x="30" y="50">
        <animate
          attributeName="height"
          begin="0s"
          dur="1.2s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="100;150;50;100"
        />
        <animate
          attributeName="y"
          begin="0s"
          dur="1.2s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="50;25;75;50"
        />
      </rect>
      <rect fill="currentColor" height="80" rx="10" ry="10" width="20" x="70" y="60">
        <animate
          attributeName="height"
          begin="0.2s"
          dur="1.5s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="80;130;40;80"
        />
        <animate
          attributeName="y"
          begin="0.2s"
          dur="1.5s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="60;35;90;60"
        />
      </rect>
      <rect fill="currentColor" height="100" rx="10" ry="10" width="20" x="110" y="50">
        <animate
          attributeName="height"
          begin="0.4s"
          dur="1.8s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="100;150;50;100"
        />
        <animate
          attributeName="y"
          begin="0.4s"
          dur="1.8s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="50;25;75;50"
        />
      </rect>
      <rect fill="currentColor" height="60" rx="10" ry="10" width="20" x="150" y="70">
        <animate
          attributeName="height"
          begin="0.6s"
          dur="1.1s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="60;110;30;60"
        />
        <animate
          attributeName="y"
          begin="0.6s"
          dur="1.1s"
          keyTimes="0;0.3;0.6;1"
          repeatCount="indefinite"
          values="70;45;85;70"
        />
      </rect>
    </>
  ),
});

export default AnimatedLiveAudioIcon;
