import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import EntityCard, { Entity } from "../../../shared/components/EntityCard";
import useApi from "../../../shared/hooks/useApi";
import { TelephonyCallId } from "../../../shared/schema/schema";
import useCallCenter from "../hooks/useCallCenter";

export default function CallEntitySelectionModal({
  callId,
  entities,
  currentCallerEntity,
  isOpen,
  onClose,
}: {
  callId: TelephonyCallId;
  entities: Messages["InboundCallIdentifiedCallerEntity"][];
  currentCallerEntity: Messages["TelephonyCallParticipantEntityInfo"];
  isOpen: boolean;
  onClose: () => void;
}) {
  const [selectedEntity, setSelectedEntity] = React.useState<
    Messages["InboundCallIdentifiedCallerEntity"] | null
  >(null);
  const { mutations } = useApi();
  const toast = useToast();
  const { mutate: updateCallerEntity } = useMutation(
    mutations.callCenter.updateInboundCallCallerParticipantEntity(callId)
  );
  const callCenter = useCallCenter();

  const handleSubmit = () => {
    if (selectedEntity === null) {
      return;
    }

    // close the modal right away, even if the mutation fails
    onClose();

    if (
      currentCallerEntity.type === selectedEntity.type &&
      currentCallerEntity.id === selectedEntity.id
    ) {
      // no need to update the entity if it's the same as the current one
      toast({
        position: "top-right",
        title: "Entity selected",
        status: "success",
      });
      return;
    }

    updateCallerEntity(mapEntity(selectedEntity), {
      onSuccess: ({ call }) => {
        toast({
          position: "top-right",
          title: "Entity selected",
          status: "success",
        });

        callCenter.updateActiveCall(call);
      },
      onError: () => {
        toast({
          position: "top-right",
          title: "Failed to update entity",
          status: "error",
        });
      },
    });
  };

  return (
    <Modal closeOnEsc={false} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Who are you talking to?</ModalHeader>
        <ModalBody>
          <Text>
            This phone number is associated with multiple entities. Please select the entity you are
            talking to.
          </Text>
          <Stack gap="0" mt="8">
            {entities.map((entity) => (
              <EntityOption
                key={`${entity.type}-${entity.id}`}
                entity={entity}
                isSelected={
                  selectedEntity?.type === entity.type && selectedEntity?.id === entity.id
                }
                onClick={() => setSelectedEntity(entity)}
              />
            ))}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" isDisabled={selectedEntity === null} onClick={handleSubmit}>
            Select
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

function EntityOption({
  entity,
  isSelected,
  onClick,
}: {
  entity: Messages["InboundCallIdentifiedCallerEntity"];
  isSelected: boolean;
  onClick: () => void;
}) {
  const cardEntity = React.useMemo((): Entity => {
    switch (entity.type) {
      case "Caregiver":
      case "PhonebookContact":
        return entity;

      case "Patient":
        return {
          ...entity,
          contactDetails: null,
        };

      case "PatientContact":
        return {
          ...entity.patient,
          type: "Patient",
          contactDetails: {
            firstName: entity.firstName,
            lastName: entity.lastName,
            relationship: entity.relationship,
          },
        };
    }
  }, [entity]);

  return (
    <Button
      display="block"
      fontSize="inherit"
      fontWeight={isSelected ? "600" : "normal"}
      height="auto"
      p="4"
      variant={isSelected ? "solid" : "ghost"}
      onClick={onClick}
    >
      <EntityCard entity={cardEntity} />
    </Button>
  );
}

function mapEntity(
  entity: Messages["InboundCallIdentifiedCallerEntity"]
): Messages["TelephonyCallParticipantEntity"] {
  switch (entity.type) {
    case "Caregiver":
      return {
        type: "Caregiver",
        id: entity.id,
      };

    case "Patient":
      return {
        type: "Patient",
        id: entity.id,
      };

    case "PatientContact":
      return {
        type: "PatientContact",
        id: entity.id,
        patientId: entity.patient.id,
      };

    case "PhonebookContact":
      return {
        type: "PhonebookContact",
        id: entity.id,
        contactType: entity.contactType,
      };
  }
}
