import React from "react";
import { Socket } from "socket.io-client";

export default function useSocketSubscription(params: { channel: string; enabled?: boolean }) {
  const socket = (window as any).socket as Socket | undefined;
  const enabled = params.enabled ?? true;

  const subscribe = React.useCallback(() => {
    if (!socket) {
      return;
    }

    socket.emit("subscribe", [params.channel]);

    return () => {
      socket.emit("unsubscribe", [params.channel]);
    };
  }, [params.channel, socket]);

  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    if (socket) {
      socket.on("auth:done", subscribe);
      socket.on("auth:error", subscribe);
    }

    return () => {
      if (socket) {
        socket.off("auth:done", subscribe);
        socket.off("auth:error", subscribe);
      }
    };
  }, [socket, subscribe, enabled]);

  React.useEffect(() => (enabled ? subscribe() : undefined), [enabled, subscribe]);
}
