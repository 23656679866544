import { Flex, Text } from "@chakra-ui/react";
import { LocalDateTime } from "@js-joda/core";
import { UseQueryResult } from "@tanstack/react-query";
import React from "react";
import { Messages } from "../../../core/api";
import {
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  NotIdentifiedEmailEntity,
  NotIdentifiedPhoneNumberEntity,
  PatientEntity,
  PhonebookContactEntity,
} from "../../../shared/components/EntityCard";
import { GlobalWorkflowHint } from "../../../shared/hooks/useGlobalWorkflowRunner";
import BaselinePlayCircleIcon from "../../../shared/icons/BaselinePlayCircleIcon";
import DocumentIcon from "../../../shared/icons/DocumentIcon";
import PhotoFillIcon from "../../../shared/icons/PhotoFillIcon";
import SmileyFillIcon from "../../../shared/icons/SmileyFillIcon";
import SmileyMehFillIcon from "../../../shared/icons/SmileyMehFillIcon";
import SmileySadFillIcon from "../../../shared/icons/SmileySadFillIcon";
import {
  AgencyMemberId,
  CaregiverId,
  CommCenterLabelId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  PatientId,
  VisitBroadcastId,
  VisitInstanceId,
} from "../../../shared/schema/schema";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import { assertNever, isDefined } from "../../../utils";
import { CommCenterTicket } from "../communication.types";
import CallTicketText from "../components/CallTicket/helpers/CallTicketText";
import { isEmailTicket, isInboundEmailMessage } from "../utils/comm-center-email-utils";

export type NewTicketRequestBody = {
  labelId: CommCenterLabelId;
  caregiverId: CaregiverId[] | null;
  patientId: PatientId[] | null;
  message: string;
  topic: "Patient" | "Caregiver" | "NotIdentifiedPhoneNumber";
  phoneNumber?: string;
};

function getPatientContactDetailsFromTicket(ticket: Messages["CommCenterTicket"]) {
  for (const participant of ticket.callInfo?.participants ?? []) {
    if (participant.entity.type === "PatientContact") {
      return participant.entity;
    }
  }

  return null;
}

export const getTopicEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<Entity> | null => {
  switch (true) {
    case ticket.topic === "Caregiver" && ticket.relatedCaregiver !== null:
      return EntityFormatter.formatCaregiver(ticket.relatedCaregiver);

    case ticket.topic === "Patient" && ticket.relatedPatient !== null:
      return EntityFormatter.formatPatient(ticket.relatedPatient, ticket);

    case ticket.topic === "NotIdentifiedPhoneNumber" &&
      ticket.relatedNotIdentifiedPhoneNumber !== null:
      return EntityFormatter.formatNotIdentifiedPhoneNumber(ticket.relatedNotIdentifiedPhoneNumber);

    case ticket.topic === "PhonebookContact" && ticket.relatedPhonebookContact !== null:
      return EntityFormatter.formatPhonebookContact(ticket.relatedPhonebookContact);

    case ticket.topic === "NotIdentifiedEmailEntity" &&
      ticket.relatedNotIdentifiedEmailEntity !== null:
      return EntityFormatter.formatNotIdentifiedEmailEntity(ticket.relatedNotIdentifiedEmailEntity);
    default:
      return null;
  }
};

// There are a few options for a secondary entity on a ticket:
// 1. Main entity is a Patient, secondary entity is a Caregiver
// 2. Main entity is a Caregiver, secondary entity is a Patient
// 3. Main entity is a Patient, secondary entity is a PhonebookContact
// 4. Main entity is a Caregiver, secondary entity is a PhonebookContact
// 5. Main entity is a PhonebookContact, secondary entity is a Patient
// 6. Main entity is a PhonebookContact, secondary entity is a Caregiver
// 7. Main entity is a NotIdentifiedEmailEntity, secondary entity is a Patient
// 8. Main entity is a NotIdentifiedEmailEntity, secondary entity is a Caregiver
export const getSecondaryEntityFromTicket = (
  ticket: Messages["CommCenterTicket"]
): EntityWithStatus<CaregiverEntity | PatientEntity | PhonebookContactEntity> | undefined => {
  switch (true) {
    case ticket.topic === "Patient" && ticket.relatedCaregiver !== null:
      return EntityFormatter.formatCaregiver(ticket.relatedCaregiver);
    case ticket.topic === "Patient" && ticket.relatedPhonebookContact !== null:
      return EntityFormatter.formatPhonebookContact(ticket.relatedPhonebookContact);
    case ticket.topic === "Caregiver" && ticket.relatedPatient !== null:
      return EntityFormatter.formatPatient(ticket.relatedPatient, ticket);
    case ticket.topic === "Caregiver" && ticket.relatedPhonebookContact !== null:
      return EntityFormatter.formatPhonebookContact(ticket.relatedPhonebookContact);
    case ticket.topic === "PhonebookContact" && ticket.relatedPatient !== null:
      return EntityFormatter.formatPatient(ticket.relatedPatient, ticket);
    case ticket.topic === "PhonebookContact" && ticket.relatedCaregiver !== null:
      return EntityFormatter.formatCaregiver(ticket.relatedCaregiver);
    case ticket.topic === "NotIdentifiedEmailEntity" && ticket.relatedPatient !== null:
      return EntityFormatter.formatPatient(ticket.relatedPatient, ticket);
    case ticket.topic === "NotIdentifiedEmailEntity" && ticket.relatedCaregiver !== null:
      return EntityFormatter.formatCaregiver(ticket.relatedCaregiver);
    default:
      return undefined;
  }
};

export type CommCenterRelatedVisit =
  | {
      type: "VisitBroadcast";
      id: VisitBroadcastId;
      startTime: LocalDateTime;
      endTime: LocalDateTime;
      relatedPatient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>;
    }
  | {
      type: "VisitInstance";
      id: VisitInstanceId;
      startTime: LocalDateTime;
      endTime: LocalDateTime;
      relatedPatient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>;
    };

export function getRelatedVisitFromTicket(
  activeTicket: Messages["CommCenterTicket"] | null
): CommCenterRelatedVisit | null {
  if (activeTicket === null || activeTicket.relatedPatient === null) {
    return null;
  }

  switch (true) {
    case activeTicket.relatedVisitBroadcast !== null:
      return {
        type: "VisitBroadcast",
        id: activeTicket.relatedVisitBroadcast.id,
        startTime: activeTicket.relatedVisitBroadcast.startTime,
        endTime: activeTicket.relatedVisitBroadcast.endTime,
        relatedPatient: activeTicket.relatedPatient,
      };
    case activeTicket.relatedVisitInstance !== null:
      return {
        type: "VisitInstance",
        id: activeTicket.relatedVisitInstance.id,
        startTime: activeTicket.relatedVisitInstance.startTime,
        endTime: activeTicket.relatedVisitInstance.endTime,
        relatedPatient: activeTicket.relatedPatient,
      };
    default:
      return null;
  }
}

export function getTextFormatForRelatedVisit(props: CommCenterRelatedVisit): React.ReactNode {
  switch (props.type) {
    case "VisitBroadcast": {
      const range = dateFormatter.toDateTimeRange(props.startTime, props.endTime);
      const name = props.relatedPatient.name;
      return `This ticket is related to a Visit Broadcast (${props.id}) From ${range}, patient: ${name}`;
    }

    case "VisitInstance": {
      const time = dateFormatter.toDateTime(props.startTime);
      const name = props.relatedPatient.name;
      return `This ticket is related to a Visit Instance (${props.id}) on ${time}, patient: ${name}`;
    }
  }
}

export const getCommCenterTeamMemberIdByAgencyMemberId = (
  teams: Messages["CommCenterTeamWithMembers"][],
  agencyMemberId: AgencyMemberId
): CommCenterTeamMemberId | null => {
  const teamMember = teams
    .flatMap((team) => team.members)
    .find((member) => member.agencyMemberId === agencyMemberId);

  return teamMember?.id ?? null;
};

export function getCommCenterMessageCreatorName(
  message: Pick<Messages["CommCenterMessage"], "createdBy">
) {
  switch (message.createdBy.type) {
    case "Agency Member":
    case "Caregiver":
    case "Patient":
      return message.createdBy.name;
    case "System":
      return "Medflyt Support";
  }
}

export function isCommCenterMessageAuthorTheSame(params: {
  previous: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
  next: Pick<Messages["CommCenterMessage"], "createdBy"> | undefined;
}) {
  if (params.previous === undefined || params.next === undefined) {
    return false;
  }

  const pCreatedBy = params.previous.createdBy;
  const nCreatedBy = params.next.createdBy;

  switch (true) {
    case pCreatedBy.type === "System" && nCreatedBy.type === "System":
      return true;
    case pCreatedBy.type === "Agency Member" && nCreatedBy.type === "Agency Member":
    case pCreatedBy.type === "Caregiver" && nCreatedBy.type === "Caregiver":
    case pCreatedBy.type === "Patient" && nCreatedBy.type === "Patient":
      return pCreatedBy.id === nCreatedBy.id;
    default:
      return false;
  }
}

export function getMessagePreview(message: Messages["CommCenterMessage"]) {
  const latestMessage = message.payload.at(-1);

  if (latestMessage === undefined) {
    return null;
  }

  switch (latestMessage.type) {
    case "TEXT":
      return <Text isTruncated={true}>{latestMessage.message}</Text>;
    case "IMAGE":
      return (
        <Flex align="center" gap={2}>
          <PhotoFillIcon />
          <Text>Photo</Text>
        </Flex>
      );
    case "DOCUMENT":
      return (
        <Flex align="center" gap={2}>
          <DocumentIcon />
          <Text>Document</Text>
        </Flex>
      );
    case "VIDEO":
      return (
        <Flex align="center" gap={2}>
          <BaselinePlayCircleIcon />
          <Text>Video</Text>
        </Flex>
      );
    case "ACTION":
      return <></>;
    case "ONBOARDING_MESSAGE":
      return <></>;
  }
}

const CommCenterTicketStatus = ["NEW", "IN PROGRESS", "RESOLVED"] as const;
export type CommCenterTicketStatus = typeof CommCenterTicketStatus[number];

export function getLatestCommCenterMessageByRecipient(messages: Messages["CommCenterMessage"][]) {
  return messages.filter(isIncomingMessage).at(-1);
}

export function transformCommCenterTickets(tickets: Messages["CommCenterTicket"][]) {
  return sortTicketsByLastActivity(tickets.map((ticket) => toCommCenterTicket(ticket)));
}

export function toCommCenterTicket(ticket: Messages["CommCenterTicket"]): CommCenterTicket {
  return { ...ticket, lastActivity: ticket.messages.at(-1)?.createdAt ?? ticket.createdAt };
}

export function sortTicketsByLastActivity(tickets: CommCenterTicket[]) {
  return [...tickets].sort((a, b) => b.lastActivity.compareTo(a.lastActivity));
}

export function isMobileChatTicket(ticket: Messages["CommCenterTicket"]): ticket is ChatTicket {
  const chatMessagesTicketSources: Messages["CommCenterTicketSource"][] = [
    "MANUAL",
    "MOBILE_CHAT",
    "SYSTEM_TRIGGER",
  ];
  return chatMessagesTicketSources.includes(ticket.source);
}
export type ChatTicket = Messages["CommCenterTicket"] & {
  source: "MANUAL" | "MOBILE_CHAT" | "SYSTEM_TRIGGER";
};

export function buildAttachmentsPayload(
  preSignedFiles: UseQueryResult<
    {
      file: File;
      url: string;
      key: string;
      type: string;
    },
    unknown
  >[]
) {
  const attachments = preSignedFiles.flatMap((a) => (a.data ? a.data : []));
  const payload: MessagePayload[] = [];
  for (const attachment of attachments) {
    const messageType = getPayloadTypeByMimeType(attachment.type as AttachmentMimeType);

    payload.push({ type: messageType, url: attachment.key });
  }

  return payload;
}

export function getPayloadTypeByMimeType(mime: AttachmentMimeType): MessagePayloadType {
  switch (mime) {
    case "image/jpeg":
      return "IMAGE";
    case "image/png":
      return "IMAGE";
    case "image/webp":
      return "IMAGE";
    case "application/pdf":
      return "DOCUMENT";
    case "video/mp4":
      return "VIDEO";
    case "video/webm":
      return "VIDEO";
    case "video/ogg":
      return "VIDEO";
    case "video/quicktime":
      return "VIDEO";
    default:
      assertNever(mime);
  }
}

export const formatDirection = (
  direction: "Inbound" | "Outbound" | "Parallel" | "INBOUND" | "OUTBOUND"
): string => {
  switch (direction) {
    case "Inbound":
    case "INBOUND":
      return "Inbound";
    case "Outbound":
    case "OUTBOUND":
      return "Outgoing";
    case "Parallel":
      return "Outgoing (Parallel)";
  }
};

export function getIconBySatisfactionRank(rank: number) {
  const boxSize = 6;
  const { icon: Icon, color } = (() => {
    if (rank <= 50) {
      return {
        icon: SmileySadFillIcon,
        color: "red.300",
      };
    }

    if (rank <= 74) {
      return {
        icon: SmileyMehFillIcon,
        color: "yellow.300",
      };
    }

    return {
      icon: SmileyFillIcon,
      color: "green.300",
    };
  })();

  return (
    <Flex alignItems="center" color={color} gap={1}>
      <Icon boxSize={boxSize} />
      <CallTicketText fontWeight="bold">{rank}%</CallTicketText>
    </Flex>
  );
}

export function getWorkflowHintsFromTicket(ticket: Messages["CommCenterTicket"]) {
  const hints: GlobalWorkflowHint[] = [
    {
      type: "prefill-field",
      fieldType: { type: "entity", entity: "Comm Center Ticket" },
      display: `Ticket #${ticket.id}`,
      value: ticket.id,
    },
  ];

  if (ticket.relatedCaregiver !== null) {
    hints.push({
      type: "prefill-field",
      fieldType: { type: "entity", entity: "Caregiver" },
      value: ticket.relatedCaregiver.id,
      display: ticket.relatedCaregiver.name,
    });
  }

  if (ticket.relatedPatient !== null) {
    hints.push({
      type: "prefill-field",
      fieldType: { type: "entity", entity: "Patient" },
      value: ticket.relatedPatient.id,
      display: ticket.relatedPatient.name,
    });
  }

  if (ticket.relatedVisitInstance !== null) {
    hints.push({
      type: "prefill-field",
      fieldType: { type: "entity", entity: "Visit Instance" },
      value: ticket.relatedVisitInstance.id,
      display: `Visit Instance #${ticket.relatedVisitInstance.id}`,
    });
  }

  if (ticket.relatedVisitBroadcast !== null) {
    hints.push({
      type: "prefill-field",
      fieldType: { type: "entity", entity: "Visit Broadcast" },
      value: ticket.relatedVisitBroadcast.id,
      display: `Visit Broadcast #${ticket.relatedVisitBroadcast.id}`,
    });
  }

  return hints;
}

export type MessagePayloadType = "IMAGE" | "VIDEO" | "DOCUMENT";

export type AttachmentMimeType =
  | "image/jpeg"
  | "image/png"
  | "image/webp"
  | "application/pdf"
  | "video/mp4"
  | "video/webm"
  | "video/ogg"
  | "video/quicktime";

export type MessagePreSignedAttachments = {
  url: string;
  file: File;
  key: string;
  type: string;
};

export type MessagePayload =
  | Messages["AgencyMemberImageMessage"]
  | Messages["AgencyMemberDocumentMessage"]
  | Messages["AgencyMemberVideoMessage"];

export namespace EntityFormatter {
  export function formatPatient(
    patient: NonNullable<Messages["CommCenterTicket"]["relatedPatient"]>,
    ticket: Messages["CommCenterTicket"]
  ): PatientEntity | EntityWithStatus<PatientEntity> {
    return {
      type: "Patient",
      displayId: patient.displayId,
      fullName: patient.name,
      id: patient.id,
      status: patient.status,
      gender: patient.gender,
      contactDetails: getPatientContactDetailsFromTicket(ticket),
    };
  }

  export function formatCaregiver(
    caregiver: NonNullable<Messages["CommCenterTicket"]["relatedCaregiver"]>
  ): CaregiverEntity | EntityWithStatus<CaregiverEntity> {
    return {
      type: "Caregiver",
      displayId: caregiver.displayId,
      fullName: caregiver.name,
      id: caregiver.id,
      status: caregiver.status,
      photoUrl: caregiver.photoUrl,
    };
  }

  export function formatNotIdentifiedPhoneNumber(
    notIdentifiedPhoneNumber: NonNullable<
      Messages["CommCenterTicket"]["relatedNotIdentifiedPhoneNumber"]
    >
  ): EntityWithStatus<NotIdentifiedPhoneNumberEntity> {
    return {
      phoneNumber: notIdentifiedPhoneNumber,
      type: "NotIdentifiedPhoneNumber",
      status: null,
    };
  }

  export function formatPhonebookContact(
    phonebookContact: NonNullable<Messages["CommCenterTicket"]["relatedPhonebookContact"]>
  ): EntityWithStatus<PhonebookContactEntity> {
    return {
      type: "PhonebookContact",
      id: phonebookContact.id,
      name: phonebookContact.name,
      status: "Phonebook Contact",
    };
  }

  export function formatNotIdentifiedEmailEntity(
    notIdentifiedEmailEntity: NonNullable<
      Messages["CommCenterTicket"]["relatedNotIdentifiedEmailEntity"]
    >
  ): EntityWithStatus<NotIdentifiedEmailEntity> {
    return {
      type: "NotIdentifiedEmailEntity",
      email: notIdentifiedEmailEntity,
      status: null,
    };
  }
}

export type NewEmailTicketRequestBody = {
  subject: string;
  caregiverId: CaregiverId | null;
  patientId: PatientId | null;
  assignedTeamId: CommCenterTeamId;
  message: {
    sender: Messages["CommCenterEmailAddressObject"];
    recipients: Messages["CommCenterEmailAddressObject"][];
    content: string;
    contentPlainText: string;
    attachments: Messages["CreateEmailMessageAttachmentParams"][];
    cc: Messages["CommCenterEmailAddressObject"][];
    bcc: Messages["CommCenterEmailAddressObject"][];
  };
};

export function getTicketUnreadCount(ticket: Messages["CommCenterTicket"]): number {
  if (isEmailTicket(ticket)) {
    return ticket.emailThread !== null
      ? ticket.emailThread.messages.filter((x) => isInboundEmailMessage(x) && x.readAt === null)
          .length
      : 0;
  }

  return ticket.messages.filter((x) => x.readAt === null && isIncomingMessage(x)).length;
}

export type CallTicket = Messages["CommCenterTicket"] & {
  callInfo: NonNullable<Messages["CommCenterTicket"]["callInfo"]>;
};
export function isCallTicket(ticket: Messages["CommCenterTicket"]): ticket is CallTicket {
  return ticket.source === "PHONE_CALL" && ticket.callInfo !== null;
}

export type SmsTicket = Messages["CommCenterTicket"] & {
  phoneNumber: NonNullable<Messages["CommCenterTicket"]["phoneNumber"]>;
};
export function isSmsTicket(ticket: Messages["CommCenterTicket"]): ticket is SmsTicket {
  return ticket.source === "SMS_CONVERSATION" && ticket.phoneNumber !== null;
}

export function isIncomingMessage(message: Messages["CommCenterTicket"]["messages"][0]) {
  return message.createdBy.type === "Caregiver" || message.createdBy.type === "Patient";
}

export function isOutgoingMessage(message: Messages["CommCenterTicket"]["messages"][0]) {
  return message.createdBy.type === "Agency Member" || message.createdBy.type === "System";
}

export function hasActiveAgencyMemberInCall(call: Messages["TelephonyCallInfo"]) {
  return call.participants.some((x) => x.entity.type === "AgencyMember" && x.status === "Joined");
}

export function getTicketLastUpdatedAt(ticket: Messages["CommCenterTicket"]) {
  const lastEmailMessage = isEmailTicket(ticket) ? ticket.emailThread.messages.at(-1) : null;
  const lastChatMessage = !isEmailTicket(ticket) ? ticket.messages.at(-1) : null;
  const updatedAt = isDefined(lastEmailMessage)
    ? lastEmailMessage.createdAt
    : isDefined(lastChatMessage)
    ? lastChatMessage.createdAt
    : ticket.createdAt;

  return updatedAt;
}
