import { createIcon } from "@chakra-ui/react";

const WarningOutlineIcon = createIcon({
  displayName: "WarningOutlineIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M1 21L12 2l11 19zm3.45-2h15.1L12 6zM12 18q.425 0 .713-.288T13 17t-.288-.712T12 16t-.712.288T11 17t.288.713T12 18m-1-3h2v-5h-2zm1-2.5"
      fill="currentColor"
    />
  ),
});

export default WarningOutlineIcon;
