import { useMutation } from "@tanstack/react-query";
import { BodyOf } from "../../../../core/api";
import useApi from "../../../../shared/hooks/useApi";

export default function useParallelCallsDial() {
  const { api } = useApi();

  return useMutation({
    mutationKey: ["telephony", "parallel_calls", "make"],
    mutationFn: async (body: BodyOf<"post", "./telephony/parallel_calls/make">) => {
      await api.post("./telephony/parallel_calls/make", { body });
    },
  });
}
