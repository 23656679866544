import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Icon, Tooltip } from "@chakra-ui/react";
import { FaPhoneSlash, FaVoicemail } from "react-icons/fa";
import { Messages } from "../../../core/api";
import CompletedSolidIcon from "../../../shared/icons/CompletedSolidIcon";
import RoundPermPhoneMsgIcon from "../../../shared/icons/RoundPermPhoneMsgIcon";
import useCommCenterCallRecording from "../hooks/useCommCenterCallRecording";
import CallTicketRecording from "./CallTicket/helpers/CallTicketEventCard/CallTicketRecording";
import CallTicketText from "./CallTicket/helpers/CallTicketText";

type Props = {
  callStatus: Messages["TelephonyCallStatus"];
  failDescription: string | null;
  recordingState: ReturnType<typeof useCommCenterCallRecording>;
};

const PhoneCallStatus = ({ callStatus, recordingState, failDescription }: Props) => {
  switch (callStatus) {
    case "Completed":
      return (
        <Flex alignItems="center" gap={4}>
          <Icon as={CompletedSolidIcon} color="green" />
          <CallTicketText>Completed Call</CallTicketText>
          <Flex alignItems="inherit" gap={1} onClick={(e) => e.stopPropagation()}>
            <CallTicketRecording state={recordingState} />
          </Flex>
        </Flex>
      );
    case "Failed":
      return (
        <Tooltip label={failDescription}>
          <Flex alignItems="center" color="red" gap={4}>
            <WarningTwoIcon />
            <CallTicketText>Failed Call</CallTicketText>
          </Flex>
        </Tooltip>
      );
    case "Missed":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <CallTicketText>Missed Call</CallTicketText>
        </Flex>
      );
    case "MovedToSMS":
      return (
        <Flex alignItems="center" color="orange.500" gap={4}>
          <RoundPermPhoneMsgIcon />
          <CallTicketText>Moved to SMS</CallTicketText>
        </Flex>
      );
    case "NoAnswer":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <CallTicketText>No Answer</CallTicketText>
        </Flex>
      );
    case "VoiceMail":
      return (
        <Flex alignItems="center" color="yellow.400" gap={4}>
          <FaVoicemail size="16px" />
          <CallTicketText>Voice Mail</CallTicketText>
          <Flex alignItems="inherit" gap={1} onClick={(e) => e.stopPropagation()}>
            <CallTicketRecording state={recordingState} />
          </Flex>
        </Flex>
      );
    case "Canceled":
      return (
        <Flex alignItems="center" color="red" gap={4}>
          <FaPhoneSlash />
          <CallTicketText>Canceled</CallTicketText>
        </Flex>
      );
    case "Connecting":
    case "InProgress":
    case "Initiated":
    case "Ringing":
    case "Classifying":
      return <></>;
  }
};

export default PhoneCallStatus;
