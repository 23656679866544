import { createIcon } from "@chakra-ui/react";

const PlaylistAddIcon = createIcon({
  displayName: "PlaylistAddIcon",
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M3 16v-2h7v2zm0-4v-2h11v2zm0-4V6h11v2zm13 12v-4h-4v-2h4v-4h2v4h4v2h-4v4z"
      fill="currentColor"
    />
  ),
});

export default PlaylistAddIcon;
