import { Flex, Text } from "@chakra-ui/react";
import { AppointmentHistoryChangeDetails } from "./AppointmentHistoryChangeDetails";
import {
  IntakeHistoryChangeLogRow,
  IntakeHistoryChangeLogRowType,
} from "../../../../shared/utils/intake-change-history";

export const ChangeLogDetails = (props: { change: IntakeHistoryChangeLogRow }) => {
  const detailsByEntityType = () => {
    switch (props.change.entity.type) {
      case "Appointment":
        return (
          <AppointmentHistoryChangeDetails
            appointment={{
              clinician: props.change.entity.clinician,
              date: props.change.entity.appointmentDateTime,
              location: props.change.entity.location,
            }}
            changeType={props.change.type}
            field={props.change.field}
            from={props.change.from}
            to={props.change.to}
          />
        );
      case "Eligibility Check":
        return <></>;
      default:
        return (
          <BaseChangeLogDetails
            changeType={props.change.type}
            from={props.change.from}
            to={props.change.to}
          />
        );
    }
  };

  return (
    <Flex direction="column">
      {detailsByEntityType()}
      <br />
      <Text>{`${props.change.type} ${props.change.changeSource}`}</Text>
    </Flex>
  );
};

const BaseChangeLogDetails = (props: {
  from: string | null;
  to: string | null;
  changeType: IntakeHistoryChangeLogRowType;
}) => {
  const getDetailsComponentByChangeType = () => {
    switch (props.changeType) {
      case "Updated": {
        return (
          <>
            <Text>
              <u>From:</u> {props.from}
            </Text>
            <Text>
              <u>To:</u> {props.to}
            </Text>
          </>
        );
      }
      case "Added": {
        return <Text>{`New value: ${props.to}`}</Text>;
      }
      case "Removed": {
        return <Text>{`Previous value: ${props.from}`}</Text>;
      }
    }
  };

  return getDetailsComponentByChangeType();
};
