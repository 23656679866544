import { Instant, nativeJs } from "@js-joda/core";
import { Messages } from "../../core/api";
import { dateFormatter } from "./date-formatter";

export type IntakeHistoryChangeLogRowType = "Added" | "Removed" | "Updated";

export type IntakeHistoryChangeLogRow = {
  type: IntakeHistoryChangeLogRowType;
  time: Instant;
  changedBy: string;
  from: string | null;
  to: string | null;
  field: string;
  changeSource: string;
  isEntityOperation?: boolean;
  changeTitle: string;
  entity: Messages["PatientIntakeHistoryRow"]["changedEntity"];
};

export const mapRawHistoryRowToHistoryChangeLogRow = (row: Messages["PatientIntakeHistoryRow"]) => {
  const from = formatValueByType(row.oldValue, row.changedField.type);
  const to = formatValueByType(row.newValue, row.changedField.type);
  const didEntireEntityChange = isEntityOperation(row.changeType);
  const changeTypeDisplay = formatChangeTypeForDisplay(row.changeType);

  const on = formatOnClause(row.changeType);
  const changeTypeText = didEntireEntityChange
    ? ""
    : row.changeType === "Checked" || row.changeType === "Unchecked"
    ? row.changeType
    : changeTypeDisplay;
  const entityType = didEntireEntityChange ? "" : row.changedEntity.type;
  const fieldText = ["Checked", "Unchecked"].includes(row.changeType)
    ? ""
    : row.changedField.title.toLowerCase();

  // Example: "Arlene Christiansen (changedBy) updated (changeTypeText) next call date (fieldText) on patient John Doe"
  // Example: "System User (changedBy) added (changeTypeText) birth date (fieldText) to patient John Doe"
  // Example: "System User (changedBy) deleted appointment (fieldText) NYIA 1"
  // Example: "Arlene Christiansen (changedBy) checked checklist item (entityType) nyia assesments were scheduled (entity.name)"
  const changeString = `${
    row.changedBy
  } ${changeTypeText.toLowerCase()} ${fieldText} ${on} ${entityType.toLowerCase()} ${row.changedEntity.name.toLowerCase()}`;

  return {
    type: changeTypeDisplay,
    time: row.changeTime,
    changedBy: row.changedBy,
    entity: row.changedEntity,
    changeSource: row.changeSource,
    field: row.changedField.title,
    from,
    to,
    changeTitle: changeString,
  };
};

const formatOnClause = (changeType: Messages["PatientIntakeHistoryChangeType"]) => {
  const didEntireEntityChange = isEntityOperation(changeType);

  if (didEntireEntityChange) {
    return "";
  }

  switch (changeType) {
    case "Added":
      return "to";
    case "Removed":
      return "from";
    case "Checked":
    case "Unchecked":
      return "";
    default:
      return "on";
  }
};

const formatChangeTypeForDisplay = (
  type: Messages["PatientIntakeHistoryChangeType"]
): IntakeHistoryChangeLogRowType => {
  switch (type) {
    case "Added":
    case "Added Entity":
      return "Added";
    case "Removed":
    case "Removed Entity":
      return "Removed";
    case "Updated":
    case "Checked":
    case "Unchecked":
      return "Updated";
    default:
      return type;
  }
};

const formatValueByType = (
  value: string | null,
  type: Messages["PatientIntakeHistoryRow"]["changedField"]["type"]
) => {
  switch (type) {
    case "string":
    case "number":
      return value;
    case "phoneNumber":
      return value;
    case "dateTime":
      return value === null
        ? null
        : dateFormatter.toDateTime(Instant.from(nativeJs(new Date(value))));
    case "date":
      return value === null ? null : dateFormatter.toDate(Instant.from(nativeJs(new Date(value))));
    case "boolean":
      return value === "true" ? "Yes" : "No";
    case "checkbox":
      return value === "true" ? "Checked" : "Unchecked";
    default:
      return value;
  }
};

const isEntityOperation = (changeType: Messages["PatientIntakeHistoryRow"]["changeType"]) => {
  return ["Added Entity", "Removed Entity"].includes(changeType);
};
