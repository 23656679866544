import ng from "angular";
import { DayOfWeek, LocalTime } from "@js-joda/core";
import { CaregiverId, PatientId, VisitBroadcastId } from "@medflyt/messages/ids";
import { VisitBroadcastService } from "../../visitBroadcast.service";
import { AssignCaregiverToFlexibleVisitParams, CaregiverRequest } from "../../visitBroadcast.types";
import { CaregiverVisitRequestsContainerBindings, CaregiverWorkHoursPerWeekData, FlexibleCaregiverEngagement, MappedCaregiverEngagement } from "./flexible-visit-requests-container.types";
import {
    areCaregiverRequestsEqual,
    convertWeeklyTemplateModalActionToEnumValue,
    hoursBetweenTime,
    WeeklyTemplateActionEnum
} from "./flexible-visit-requests-container.utils";
import { getErrorFromAssignResponse } from "../../visitBroadcast.utils";

//! @ngInject
class caregiverVisitRequestsContainerCtrl implements ng.IComponentController, CaregiverVisitRequestsContainerBindings
{
    caregiverEngagements!: FlexibleCaregiverEngagement[];
    visitBroadcastId!: VisitBroadcastId;
    visitType!: "PATTERN" | "SINGLES";
    closeModal!: () => void;
    allowMultipleCaregivers!: boolean;
    patientId!: PatientId;
    totalVisitHours!: number;
    isPendingAssignment = false;
    onClickChat!: (caregiverId: CaregiverId, context: "REQUESTED") => void;
    isVisitAssignmentBlocked!: boolean;
    
    selectedShiftsByCaregiver!: Map<CaregiverId, Map<DayOfWeek, CaregiverRequest>>;
    doesPatientHaveActiveWeeklyTemplate!: boolean;
    totalSelectedHoursNum!: number;
    caregiversWorkHoursPerWeek!: Map<CaregiverId, CaregiverWorkHoursPerWeekData[]>
    mappedCaregiverEngagements!: MappedCaregiverEngagement[]; 
    visitHoursRestrictionError: {
      isOpen: boolean;
      conflicts: {
        exceededHours: number;
        caregiverId: CaregiverId;
      }[];
    };

    constructor(
      private $rootScope: ng.IRootScopeService,
      private $scope: ng.IScope,
      private visitBroadcastService: VisitBroadcastService,
      private toaster: toaster.IToasterService,
      private mfModal: any){
        this.selectedShiftsByCaregiver = new Map<CaregiverId, Map<DayOfWeek, CaregiverRequest>>();
        this.caregiversWorkHoursPerWeek = new Map<CaregiverId, CaregiverWorkHoursPerWeekData[]>();
        this.visitHoursRestrictionError = {
          isOpen: false,
          conflicts: [],
        };
    }

    // ------- Initiation

    $onInit(){
      this.initCaregiversWorkingHoursMap();

      this.visitBroadcastService.getPatientWeeklyTemplate(this.patientId).then((response) => {
        if(response.data.shifts.length > 0){
          this.doesPatientHaveActiveWeeklyTemplate = true;
        }
        else{
          this.doesPatientHaveActiveWeeklyTemplate = false;
        }
      }).catch((error) => {
        console.log(`Error in fetching patient ${this.patientId} weekly template. ${error.data}`);
      })

      this.totalSelectedHoursNum = 0;
    }

    $onChanges(changesObj){
      if("caregiverEngagements" in changesObj){
        const isFirstChange = changesObj.caregiverEngagements.isFirstChange();
        const prevValue = changesObj.caregiverEngagements.previousValue;
        const currValue = changesObj.caregiverEngagements.currentValue;

        if(isFirstChange || prevValue.length !== currValue.length){
          const removedEngagements = isFirstChange ? [] : 
                                     prevValue.filter((prevEngagement) => 
                                          !currValue.some((currEngagement) => 
                                              currEngagement.caregiver.id === prevEngagement.caregiver.id
                                          )
                                     );

          this.mapRequestsOnChange(removedEngagements);
        }
      }
    }

    mapRequestsOnChange(removedEngagements){
      // TODO: This onlt apply to Permanent Flexible Visits! When singles arrive, create a different function,
      // use this.visitType to determine which function to use.

      this.totalSelectedHoursNum = 0;
      this.mappedCaregiverEngagements = this.caregiverEngagements.map((engagement) => {
        const mappedRequests: CaregiverRequest[] = DayOfWeek.values().map((day) => {
            const requestInDay = engagement.flexibleVisitRequest.find((request) => request.day.toString() === day.name());
        
            if(requestInDay === undefined){
              return {
                type: "DISABLED",
                day,
                isDisabled: true,
                isSelected: false,
                caregiverId: engagement.caregiver.id
              }
            }
          
            return {
              type: "REAL_REQUEST",
              day,
              startTime: requestInDay.startTime,
              endTime: requestInDay.endTime,
              isDisabled: requestInDay === undefined,
              isSelected: false,
              caregiverId: engagement.caregiver.id
            }           
          }) 

          return {
            caregiver: engagement.caregiver,
            requests: mappedRequests
          };
      })

      const removedCaregiverIds = removedEngagements.map((engagement) => engagement.caregiver.id);
      const selectedCaregiverIdsToRemove: CaregiverId[] = [];
      const selectedRequests: CaregiverRequest[] = [];

      // Map the removed and preserved selected visits.
      for(const [selectedCaregiverId, selectedRequestsMap] of this.selectedShiftsByCaregiver.entries()){
        if(removedCaregiverIds.some((removedId) => selectedCaregiverId === removedId)){
          selectedCaregiverIdsToRemove.push(selectedCaregiverId);
        }
        else{
          selectedRequests.push(...selectedRequestsMap.values());
        }
      }

      // Remove the selected caregiver ids that removed the request.
      for(const removedCaregiverId of selectedCaregiverIdsToRemove){
        this.selectedShiftsByCaregiver.delete(removedCaregiverId);
      }

      // Preserve the state of the requests before the change.
      this.mappedCaregiverEngagements = this.mappedCaregiverEngagements.map((engagement) => {
          return {
            caregiver: engagement.caregiver,
            requests: engagement.requests.map((request) => {
              if(request.type === "REAL_REQUEST" && 
                 selectedRequests.some((selectedRequest) => areCaregiverRequestsEqual(selectedRequest, request))){
                request.isSelected = true;
                this.totalSelectedHoursNum = 
                  this.totalSelectedHoursNum + hoursBetweenTime(request.startTime, request.endTime);
              }

              return request;
            })
         }
      })
    }

    initCaregiversWorkingHoursMap(){
      const requestedCaregiversIds = this.caregiverEngagements.map((engagement) => engagement.caregiver.id);

        this.visitBroadcastService.getCaregiversWorkingHoursPerWeek(
          requestedCaregiversIds,
          this.visitBroadcastId
        ).then((response) => {
          response.data.caregivers.forEach((caregiver) => {
            const caregiverMapValue =  this.caregiversWorkHoursPerWeek.get(caregiver.caregiverId);
            const mappedWorkHours: CaregiverWorkHoursPerWeekData[] = caregiver.workHours.map((data) => {
              return {
                ...data,
                isInOvertime: data.weeklyHours > data.weeklyOvertimeHoursMark
              }
            })

            if(caregiverMapValue === undefined){
              this.caregiversWorkHoursPerWeek.set(caregiver.caregiverId, mappedWorkHours)
            }
            else{
              caregiverMapValue.push(...mappedWorkHours);
              this.caregiversWorkHoursPerWeek.set(caregiver.caregiverId, caregiverMapValue);
            }
          })
        }).catch((error) => {
          console.log(`Error in fetching caregivers working hours per week.${error.data}`);
        })
    }

    // ------ Reject Caregiver Request

    rejectCaregiverVisitRequest(caregiverId: CaregiverId){
      const modal = this.mfModal.create({
        subject: "Are You Sure?",
        variant: "warning",
        message:
          "Clicking 'Reject' will result in rejecting the entire request (all of the caregiver's requested shifts).",
        cancelLabel: "I changed my mind",
        confirmLabel: "Reject Request",
        showInput: false,
        layoutOrder: ["message"],
        hideCancelButton: false,
        preventBackdropClose: true,
        onConfirm: () => {
          this.visitBroadcastService.rejectCaregiverVisitRequest(caregiverId, this.visitBroadcastId, {visitInstances: []})
            .then((_res) => {
              modal.close();
              this.toaster.pop("success", "Successfully rejected caregiver's request.");
              this.$rootScope.$broadcast("visit_changed", { visitId: this.visitBroadcastId });
            })
            .catch((_err) => {
              this.toaster.pop("error", "Oops...", "Failed to reject the caregiver's request.");
            });
        },
      }); 
    }

    // ------ Shift Selection

    toggleShiftSelection(requestDetails: CaregiverRequest[]){
      const changedRequests: CaregiverRequest[] = [];
      this.mappedCaregiverEngagements.forEach((engagement) => {
        engagement.requests.forEach((request) => {
          const requestInDetails = requestDetails.find((details) => details === request);

          if(requestInDetails !== undefined){
            request.isSelected = !request.isSelected
            changedRequests.push(request);
          }
        })
      })

      this.addShiftsToMap(changedRequests);
      this.calculateAssumedOvertimeHours(changedRequests);
    }

    addShiftsToMap(requestDetails: CaregiverRequest[]){
      for(const shift of requestDetails){
        if(shift.type !== "REAL_REQUEST"){
          console.error("Selected a disabled shifts. Impossible!");
        }
        else{
          const convertedShift = {
            ...shift,
            startTime: LocalTime.parse(shift.startTime.toString()),
            endTime: LocalTime.parse(shift.endTime.toString())
          }
   
          const amountOfShiftHours = hoursBetweenTime(convertedShift.startTime, convertedShift.endTime);
    
          if(!convertedShift.isSelected){
            this.selectedShiftsByCaregiver.get(convertedShift.caregiverId)?.delete(convertedShift.day);
  
            if(this.selectedShiftsByCaregiver.get(convertedShift.caregiverId)?.size === 0){
              this.selectedShiftsByCaregiver.delete(convertedShift.caregiverId);
            }
  
            this.totalSelectedHoursNum = this.totalSelectedHoursNum - amountOfShiftHours;
          }
          else{
            const shiftByCaregiver = this.selectedShiftsByCaregiver.get(convertedShift.caregiverId);
      
            if(shiftByCaregiver === undefined){
              const newDayOfWeekMap = new Map<DayOfWeek, CaregiverRequest>().set(convertedShift.day, convertedShift);
              this.selectedShiftsByCaregiver.set(convertedShift.caregiverId, newDayOfWeekMap);
            }
            else {
              shiftByCaregiver.set(convertedShift.day, convertedShift);
              this.selectedShiftsByCaregiver.set(convertedShift.caregiverId, shiftByCaregiver);
            }
      
            this.totalSelectedHoursNum = this.totalSelectedHoursNum + amountOfShiftHours;
          }
        }
      }
    }

    clearSelectedShifts(){
      const affectedRequests: CaregiverRequest[] = [];
      for(const [_caregiverId, selectedShifts] of this.selectedShiftsByCaregiver.entries()){
        selectedShifts.forEach((request) => request.isSelected = false);
        affectedRequests.push(...selectedShifts.values());
      }

      this.selectedShiftsByCaregiver = new Map<CaregiverId, Map<DayOfWeek, CaregiverRequest>>();
      this.mappedCaregiverEngagements = this.mappedCaregiverEngagements.map((engagement) => {
        return {
          ...engagement,
          requests: engagement.requests.map((request) =>{
            return {
              ...request,
              isSelected: false
            }
          })
        }
      });

      this.totalSelectedHoursNum = 0;
      this.calculateAssumedOvertimeHours(affectedRequests);
    }

    calculateAssumedOvertimeHours(requests: CaregiverRequest[]){
      for(const request of requests){
        if(request.type === "REAL_REQUEST"){
          const parsedStartTime = LocalTime.parse(request.startTime.toString());
          const parsedEndTime = LocalTime.parse(request.endTime.toString());
          const hoursForRequestedVisit = hoursBetweenTime(parsedStartTime, parsedEndTime);

          const caregiverWorkingHours = this.caregiversWorkHoursPerWeek.get(request.caregiverId);

          if(caregiverWorkingHours !== undefined){
            this.caregiversWorkHoursPerWeek.set(request.caregiverId, caregiverWorkingHours.map((weekData) => {
              const newWeeklyHoursSum = request.isSelected ? 
                                weekData.weeklyHours + hoursForRequestedVisit:
                                weekData.weeklyHours - hoursForRequestedVisit;
    
              return {
                ...weekData,
                weeklyHours: newWeeklyHoursSum,
                isInOvertime: weekData.weeklyOvertimeHoursMark < newWeeklyHoursSum
              }
            }))
          }
        }
      }
    }
    
    // ------- Assign visit

    mapSelectedShiftsToRequestBodyParams(weeklyTemplateAction){
      const assignParams: AssignCaregiverToFlexibleVisitParams[] = [];

      for(const [caregiverId, shiftsByDay] of this.selectedShiftsByCaregiver.entries()){
        const caregiverShifts: {
          day: DayOfWeek,
          startTime: LocalTime,
          endTime: LocalTime
        }[] = [];

        for(const [_day, shift] of shiftsByDay.entries()){
          if(shift.type !== "REAL_REQUEST"){
            console.error("Can't add a disabled shift to request param.");
          }
          else{
            const currShiftDetails = {
              day: shift.day,
              startTime: LocalTime.parse(shift.startTime.toString()),
              endTime: LocalTime.parse(shift.endTime.toString())
            }
  
            caregiverShifts.push(currShiftDetails);
          }
        }

        assignParams.push({
          caregiverId: caregiverId,
          shifts: caregiverShifts
        })
      }

      return {
        assignParams: assignParams,
        weeklyTemplateAction: convertWeeklyTemplateModalActionToEnumValue(weeklyTemplateAction) 
      };
    }


    openVisitHoursRestrictionsErrorModal(
      conflicts: { exceededHours: number; caregiverId: CaregiverId }[]
    ) {
      this.visitHoursRestrictionError = {
        isOpen: true,
        conflicts,
      };
    }

    onCloseHoursRestrictionErrorModal() {
      this.visitHoursRestrictionError = {
        isOpen: false,
        conflicts: [],
      };

      this.$scope.$apply();
    }

    assignShifts(weeklyTemplateAction){
        const requestBody = this.mapSelectedShiftsToRequestBodyParams(weeklyTemplateAction);

        const assignCaregiverToVisits = async () => this.$rootScope.isOnboardingAgency
            ? this.visitBroadcastService.requestAssignmentCaregiversToFlexibleVisit(this.visitBroadcastId, requestBody)
            : this.visitBroadcastService.assignCaregiversToFlexibleVisit(this.visitBroadcastId, requestBody)
        ;

        assignCaregiverToVisits().then(
            (res) => {
                if (res.data.assignedWithOvertime) {
                    this.toaster.pop({
                        type: "warning",
                        title: "Warning",
                        body: `Successfully assigned caregiver with increased caregiver overtime`
                    });
                } else {
                    this.toaster.pop("success", "Successfully assigned caregiver");
                }
                this.$rootScope.$emit("refresh_visits");
                this.closeModal();
            },
            (err) => {
                if (err.data && err.data.type === "OfficeHoursRestrictionsConflict") {
                    this.openVisitHoursRestrictionsErrorModal(err.data.officeHoursRestrictionsConflicts);
                }
                else{
                  const error = getErrorFromAssignResponse(err.data);
                  this.toaster.pop({
                    type: "error",
                    title: error.errorMessage,
                    body: error.secondaryMessage,
                    timeout: 3500
                  });
                }
            }
        );
    }

    promptWeeklyTemplateQuestions(){
        const message = "What would you like to do?"
        const options: {id: number, action: WeeklyTemplateActionEnum, label: string, tooltipText?: string}[] = [];
        let subject = '';

        if(!this.doesPatientHaveActiveWeeklyTemplate){
          options.push(
            { id: 2, action: "NO_EFFECT", label: "Add visits without creating a new Weekly Template" },
            { id: 3, action: "ADD", label: "Add visits and create a new Weekly Template"});
          subject = `This patient doesn't have an active Weekly Template`;
        }
        else{
          options.push(
            { id: 2, action: "NO_EFFECT", label: "Add visits without any change to the existing Weekly Template" });
          options.push(
            { id: 3, action: "ADD", label: "Add to the existing Weekly Template"}
          );
          options.push({
            id: 1,
            action: "REPLACE",
            label: "Replace the existing Weekly Template",
            tooltipText: "Days with visits will be replaced\r\nDays without visits will be added"
          })
          subject = `This patient already has an active Weekly Template`;
        }

        const modal = this.mfModal.create({
            subject,
            options: options,
            message: message,
            layoutOrder: ["message", "options"],
            confirmLabel: "Submit",
            hideCancelButton: false,
            preventClose: true,
            onConfirm: ({ selectedOption }) => {
              modal.setLoading(true);
              if (selectedOption) {
                this.assignShifts(selectedOption);
              }
    
              // No selected option
              modal.update({
                isLoading: false,
                message: "Please select option"
              });
            },
            onCancel: () => modal.close(),
            onComplete: () => modal.close()
          });
    }
}

export const flexibleVisitRequestsContainer = {
    templateUrl:
      "admin/modules/visit-broadcast/components/flexible-visit-requests-container/flexible-visit-requests-container.component.html",
    controller: caregiverVisitRequestsContainerCtrl,
    controllerAs: "ctrl",
    bindings: {
        caregiverEngagements: "<",
        visitAssignmentRequests: "<",
        visitBroadcastId: "<",
        visitType: "<",
        closeModal: "&",
        allowMultipleCaregivers: "<",
        patientId: "<",
        totalVisitHours: "<",
        isPendingAssignment: "<",
        onClickChat: "=",
        isVisitAssignmentBlocked: "<",
    },
  };