import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Divider, Flex, Link, List, ListItem, Tag, Text } from "@chakra-ui/react";
import { Instant } from "@js-joda/core";
import React from "react";
import { Messages } from "../../../../core/api";
import { CommCenterTicketId, RobocallInstanceId } from "../../../../shared/schema/schema";
import { dateFormatter } from "../../../../shared/utils/date-formatter";
import { sortByInstant, toLocalDateTime } from "../../../../shared/utils/date-utils";
import { isEmailTicket } from "../../utils/comm-center-email-utils";
import { isCallTicket, isMobileChatTicket, isSmsTicket } from "../../utils/communication-utils";
import CommunicationLogCallDetails from "./CommunicationLogCallDetails";
import CommunicationLogEmailDetails from "./CommunicationLogEmailDetails";
import CommunicationLogSmsDetails from "./CommunicationLogSmsDetails";
import CommunicationRobocallRow from "./CommunicationRobocallRow";
import CommunicationLogMobileChatDetails from "./CommunicationLogMobileChatDetails";

type CommunicationLogResponse = Messages["CommCenterTicket"][];
type CommunicationLogRow = CommunicationLogResponse[number];
type RoboCallsLogResponse = Messages["RobocallLogData"][];

interface CommunicationLogProps {
  communicationLog: CommunicationLogResponse;
  roboCallsLog: RoboCallsLogResponse;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
  emptyCommunicationsComponent?: React.ReactNode;
}

function getGroupedMessagesDateString(from: Instant, to: Instant) {
  const fromLocalDateTime = toLocalDateTime(from, { timezone: "America/New_York" });
  const tillLocalDateTime = toLocalDateTime(to, { timezone: "America/New_York" });
  const date1Str = dateFormatter.toDateTime(from, { timezone: "America/New_York" });
  const date2Str = dateFormatter.toDateTime(to, { timezone: "America/New_York" });
  const isExactlyTheSame = fromLocalDateTime.isEqual(tillLocalDateTime);
  const isSameDay = fromLocalDateTime.toLocalDate().isEqual(tillLocalDateTime.toLocalDate());
  const date2StrWithoutDate = date2Str.split(", ")[1];

  return isExactlyTheSame
    ? date1Str
    : isSameDay
    ? `${date1Str} - ${date2StrWithoutDate}`
    : `${date1Str} - ${date2Str}`;
}

type MessagesGroup =
  | {
      ticketId: CommCenterTicketId;
      from: Instant;
      to: Instant;
      part: number;
      totalParts: number;
      createdAt: Instant;
      type: "COMMUNICATION_LOG";
    }
  | {
      id: RobocallInstanceId;
      createdAt: Instant;
      data: Messages["RobocallData"][];
      status: string;
      callType: string;
      customCallName: string | null;
      callSignedUrl: string | null;
      type: "ROBOCALL";
    };
function groupAllLogData(
  communicationLog: CommunicationLogResponse,
  roboCallsLog: RoboCallsLogResponse
) {
  const groups: MessagesGroup[] = [];
  const allMessages: { ticketId: CommCenterTicketId; createdAt: Instant }[] = communicationLog
    .map((log: CommunicationLogRow) => {
      const ticketId = log.id;
      if (log.callInfo !== null) {
        return [
          {
            ticketId: ticketId,
            createdAt: log.createdAt,
          },
        ];
      }

      const messages = log.emailThread !== null ? log.emailThread.messages : log.messages;
      return messages.map((message) => ({
        ticketId: ticketId,
        createdAt: message.createdAt,
      }));
    })
    .flat();

  groups.push(...groupConsecutiveMessagesByTicketId(allMessages));
  groups.push(
    ...roboCallsLog.map((log) => ({ ...log, callType: log.type, type: "ROBOCALL" as const }))
  );

  return sortByInstant(groups, "createdAt", "desc");
}

function groupConsecutiveMessagesByTicketId(
  items: { ticketId: CommCenterTicketId; createdAt: Instant }[]
): MessagesGroup[] {
  if (items.length === 0) {
    return [];
  }
  items = sortByInstant(items, "createdAt", "desc");
  const groups: MessagesGroup[] = [];
  const ticketParts = new Map<CommCenterTicketId, number>();
  let currentGroup = [items[0]];
  ticketParts.set(items[0].ticketId, 1);

  for (let i = 1; i < items.length; i++) {
    if (items[i].ticketId === currentGroup[currentGroup.length - 1].ticketId) {
      currentGroup.push(items[i]);
    } else {
      ticketParts.set(items[i].ticketId, (ticketParts.get(items[i].ticketId) ?? 0) + 1);

      groups.push({
        ticketId: currentGroup[0].ticketId,
        from: currentGroup[0].createdAt,
        to: currentGroup[currentGroup.length - 1].createdAt,
        part: ticketParts.get(currentGroup[0].ticketId) ?? 0,
        totalParts: 0,
        createdAt: currentGroup[0].createdAt,
        type: "COMMUNICATION_LOG",
      });
      currentGroup = [items[i]];
    }
  }

  groups.push({
    ticketId: currentGroup[0].ticketId,
    from: currentGroup[0].createdAt,
    to: currentGroup[currentGroup.length - 1].createdAt,
    part: ticketParts.get(currentGroup[0].ticketId) ?? 1,
    totalParts: 0,
    createdAt: currentGroup[0].createdAt,
    type: "COMMUNICATION_LOG",
  });

  for (const group of groups) {
    if (group.type === "COMMUNICATION_LOG") {
      group.totalParts = ticketParts.get(group.ticketId) ?? 1;
    }
  }

  return groups;
}

const CommunicationLog = (props: CommunicationLogProps) => {
  const groupedMessages = groupAllLogData(props.communicationLog, props.roboCallsLog);
  const ticketsMapped = props.communicationLog.reduce(
    (map, obj) => map.set(obj.id, obj),
    new Map<CommCenterTicketId, CommunicationLogRow>()
  );

  return (
    <Flex direction="column" gap={4}>
      {groupedMessages.length > 0 ? (
        <List display="flex" flexDirection="column" gap={4}>
          {groupedMessages.map((group, index) => {
            switch (group.type) {
              case "COMMUNICATION_LOG": {
                const currentLog = ticketsMapped.get(group.ticketId);

                const logDateRange = { from: group.from, to: group.to };
                const parts = { part: group.part, totalParts: group.totalParts };
                if (!currentLog) {
                  return <></>;
                }
                return (
                  <React.Fragment key={`${currentLog.id}-${group.part}`}>
                    <ListItem>
                      <CommunicationLogRow
                        key={currentLog.id}
                        log={currentLog}
                        logDateRange={logDateRange}
                        parts={parts}
                        onClickTicket={props.onClickTicket}
                      />
                    </ListItem>
                    {index !== groupedMessages.length - 1 && <CommunicationLogDivider />}
                  </React.Fragment>
                );
              }

              case "ROBOCALL": {
                return (
                  <React.Fragment key={group.id}>
                    <ListItem>
                      <CommunicationRobocallRow robocall={{ ...group, type: group.callType }} />
                    </ListItem>
                    {index !== groupedMessages.length - 1 && <CommunicationLogDivider />}
                  </React.Fragment>
                );
              }
            }
          })}
        </List>
      ) : (
        props.emptyCommunicationsComponent ?? (
          <Text>Looks like there are no communication logs yet.</Text>
        )
      )}
    </Flex>
  );
};

function CommunicationLogRow(props: {
  log: CommunicationLogRow;
  logDateRange: {
    from: Instant;
    to: Instant;
  };
  parts: { part: number; totalParts: number };
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}) {
  return (
    <Flex direction="column" gap={3}>
      <Flex justifyContent="space-between">
        <Flex gap={5}>
          <CommunicationLogInfoTag
            part={props.parts.part}
            status={props.log.status}
            ticketId={props.log.id}
            totalParts={props.parts.totalParts}
          />
          <CommunicationLogDateTag from={props.logDateRange.from} to={props.logDateRange.to} />
        </Flex>
        <CommunicationLogTicketLink ticketId={props.log.id} onClickTicket={props.onClickTicket} />
      </Flex>
      {(() => {
        switch (true) {
          case isSmsTicket(props.log):
            return <CommunicationLogSmsDetails ticket={props.log} />;
          case isCallTicket(props.log):
            return <CommunicationLogCallDetails ticket={props.log} />;
          case isEmailTicket(props.log):
            return <CommunicationLogEmailDetails ticket={props.log} />;
          case isMobileChatTicket(props.log):
            return <CommunicationLogMobileChatDetails ticket={props.log} />;
          default:
            return <></>;
        }
      })()}
    </Flex>
  );
}

function CommunicationLogInfoTag(props: {
  ticketId: CommCenterTicketId;
  status: Messages["CommCenterTicketStatus"];
  part: number;
  totalParts: number;
}) {
  return (
    <Tag colorScheme={props.status === "RESOLVED" ? "green" : "blue"}>
      {`${props.status === "RESOLVED" ? "Resolved" : "Ongoing"} ticket #${props.ticketId} ${
        props.totalParts !== 1 ? `(Part ${props.part}/${props.totalParts})` : ""
      }`}
    </Tag>
  );
}

function CommunicationLogDateTag(props: { from: Instant; to: Instant }) {
  const dateString = getGroupedMessagesDateString(props.from, props.to);
  return <Tag>{dateString}</Tag>;
}

function CommunicationLogTicketLink(props: {
  ticketId: CommCenterTicketId;
  onClickTicket: (ticketId: CommCenterTicketId) => void;
}) {
  return (
    <Flex>
      <Link color="blue.600" onClick={() => props.onClickTicket(props.ticketId)}>
        <Flex alignItems="center">
          See ticket
          <ExternalLinkIcon mx={2} />
        </Flex>
      </Link>
    </Flex>
  );
}

export const CommunicationLogDivider = () => <Divider borderColor="gray.300" variant="dashed" />;
export const CommunicationLogVerticalDivider = () => (
  <Divider borderColor="gray.400" h={6} orientation="vertical" />
);

export default CommunicationLog;
