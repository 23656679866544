import { createIcon } from "@chakra-ui/icons";

const LiveAudioIcon = createIcon({
  displayName: "AudioIcon",
  viewBox: "0 0 200 200",
  path: (
    <>
      <rect fill="currentColor" height="100" rx="10" ry="10" width="20" x="30" y="50" />
      <rect fill="currentColor" height="80" rx="10" ry="10" width="20" x="70" y="60" />
      <rect fill="currentColor" height="100" rx="10" ry="10" width="20" x="110" y="50" />
      <rect fill="currentColor" height="60" rx="10" ry="10" width="20" x="150" y="70" />
    </>
  ),
});

export default LiveAudioIcon;
