import { useDisclosure } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import useApi from "../../../shared/hooks/useApi";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import { TelephonyCallId } from "../../../shared/schema/schema";

export default function useInboundCallEntitySelectionModal({
  callId,
}: {
  callId: TelephonyCallId;
}) {
  const { queries } = useApi();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const callEntityOptionsQuery = useQuery({
    ...queries.callCenter.inboundCallEntityOptions(callId),
  });

  React.useEffect(() => {
    if (callEntityOptionsQuery.data === undefined) return;

    const entityOptions = callEntityOptionsQuery.data;

    if (entityOptions.length > 1) {
      onOpen();
    }
  }, [callEntityOptionsQuery.data, onOpen]);

  useSocketEvent({
    key: "TelephonyInboundCallAnswered",
    onEvent: (event) => {
      if (event.call.id === callId) {
        callEntityOptionsQuery.refetch();
      }
    },
  });

  return {
    isOpen: isOpen,
    onClose: onClose,
    callEntityOptions: callEntityOptionsQuery.data ?? [],
  };
}
