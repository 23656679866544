import { IntakeTrackStepId, PatientId } from "../../shared/schema/schema";
import { createQueryKeys } from "../../shared/utils/create-query-keys";

export const patientIntakeKeys = createQueryKeys("patient_intake", {
  search: (params: Record<string, unknown>) => params,
  get: (id: PatientId) => id,
  communicationLog: (id: PatientId) => id,
  portalDocuments: (id: PatientId) => id,
  medicaidStatus: null,
  intakeStatus: null,
  intakePlan: null,
  intakeCallsOrder: null,
  flowSettings: null,
  intakeNotes: null,
  intakeTracks: null,
  flowPatient: (id: PatientId) => id,
  flowSteps: (params: { id: PatientId }) => params,
  flowStep: (params: { patientId: PatientId; stepId: IntakeTrackStepId }) => params,
  checklist: (id: PatientId) => id,
  externalFlows: null,
  eligibilityCheck: (id: PatientId) => id,
  history: (id: PatientId) => id,
});
