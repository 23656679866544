import {
  EndedParallelCallItem,
  EnqueuedParallelCallItem,
  ListedParallelCallItem,
  OngoingParallelCallItem,
  ParallelCallItem,
  PendingParallelCallItem,
} from "./parallel-calls.types";

export const MAX_CONCURRENT_PARALLEL_CALLS = 3;

export function hasParallelCallStatus<TStatus extends ParallelCallItem["status"]>(
  item: ParallelCallItem,
  status: TStatus
): item is ParallelCallItem & { status: TStatus } {
  return item.status === status;
}

export function isParallelCallInGroup<
  TGroup extends "Listed" | "Pending" | "Enqueued" | "Ongoing" | "Ended"
>(
  item: ParallelCallItem,
  group: TGroup
): item is {
  Listed: ListedParallelCallItem;
  Pending: PendingParallelCallItem;
  Enqueued: EnqueuedParallelCallItem;
  Ongoing: OngoingParallelCallItem;
  Ended: EndedParallelCallItem;
}[TGroup] {
  switch (item.status) {
    case "Unchecked":
    case "Checked":
      return group === "Listed";
    case "Pending":
      return group === "Pending";
    case "InQueue":
    case "Queued":
      return group === "Enqueued";
    case "Dialing":
    case "Classifying":
    case "ManualClassificationRequired":
    case "Human":
      return group === "Ongoing";
    case "VoiceMail":
    case "Canceled":
    case "Completed":
    case "Failed":
    case "NoAnswer":
      return group === "Ended";
  }
}
