import { Flex, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import AnimatedLiveAudioIcon from "../../../../../shared/icons/AnimatedAudioIcon";
import LiveAudioIcon from "../../../../../shared/icons/LiveAudioIcon";
import { EndedParallelCallItem, OngoingParallelCallItem } from "../parallel-calls.types";

type Props = {
  item: OngoingParallelCallItem | EndedParallelCallItem;
};

export default function ParallelCallTranscriptionText(props: Props) {
  switch (props.item.status) {
    case "Dialing":
    case "Classifying":
    case "ManualClassificationRequired":
      return (
        <motion.div
          animate={{ opacity: 1, height: "auto" }}
          exit={{ opacity: 0, height: 0 }}
          initial={{ opacity: 0, height: 0 }}
        >
          <Flex alignItems="center" gap={2}>
            <AnimatedLiveAudioIcon color="blue.500" fontSize={20} />
            <Text color="gray.600">{props.item.transcription ?? "Transcribing..."}</Text>
          </Flex>
        </motion.div>
      );
    case "Canceled":
    case "Failed":
    case "Completed":
    case "NoAnswer":
    case "VoiceMail":
    case "Human":
      return (
        <>
          {Boolean(props.item.transcription) && (
            <motion.div
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              initial={{ opacity: 0, height: 0 }}
            >
              <Flex alignItems="center" gap={2}>
                <LiveAudioIcon color="gray.400" fontSize={20} />
                <Text color="gray.600">{props.item.transcription}</Text>
              </Flex>
            </motion.div>
          )}
        </>
      );
  }
}
